import { Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BasicSelectInput,
  BasicTextInput,
  Button,
  DurationTypeInput,
  EachInput,
  ExerciseInfo,
  ISelectItem,
  Loading,
  Modal,
} from 'telemed-core';

import { useAddExerciseFormManagement } from '../hooks';

type Props = {
  onAdd: (newExercise: ExerciseInfo) => void;
};

const keyPrefix = 'forms.AddExerciseForm';

export const AddExerciseForm: React.FC<Props> = ({ onAdd }) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { contentOptions, error, loading, methods, submit } =
    useAddExerciseFormManagement();

  if (loading) {
    return <Loading />;
  }

  const onSubmit = async (fields: any) => {
    const newExercise = await submit(fields);
    onAdd(newExercise);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <ExerciseInput
              contentOptions={contentOptions}
              label={t('exercise.label')}
              placeholder={t('exercise.placeholder')}
            />
          </Grid>
          <Grid item md={6}>
            <EachInput
              name='each'
              label={t('each.label')}
              placeholder={t('each.placeholder')}
            />
          </Grid>
          <Grid item md={2}>
            <BasicTextInput
              name='duration'
              maxLength={2}
              onlyNumbers={true}
              label={t('duration.label')}
              placeholder={t('duration.placeholder')}
            />
          </Grid>
          <Grid item md={4} display='flex' alignItems='flex-end'>
            <DurationTypeInput
              label={t('durationType.label')}
              placeholder={t('durationType.placeholder')}
            />
          </Grid>
          <Grid item md={12}>
            <BasicTextInput
              name='indications'
              maxLength={300}
              minRows={4}
              label={t('indications.label')}
              placeholder={t('indications.placeholder')}
            />
          </Grid>

          <Grid item md={12} mt={3} display='flex' justifyContent='center'>
            <Button buttonProps={{ type: 'submit' }}>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};

type ExerciseInputInputProps = {
  // maxMenuHeight?: number;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  contentOptions: ISelectItem[];
};

const ExerciseInput: React.FC<ExerciseInputInputProps> = ({
  // maxMenuHeight = 200,
  name = 'content',
  label = '',
  placeholder = 'Seleccione...',
  required = true,
  contentOptions,
}): JSX.Element => {
  return (
    <BasicSelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      options={contentOptions}
      // maxMenuHeight={maxMenuHeight}
    />
  );
};
