import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useVerifyEmail } from 'telemed-core';

const EMAIL_TEXT = 'Intenta con tu correo electrónico';
const REQUIRED_TEXT = 'Este campo es obligatorio';

const schema = yup.object().shape({
  email: yup.string().email(EMAIL_TEXT).required(REQUIRED_TEXT),
});

type FormProps = {
  email: string;
};

export const useVerifyEmailFormManagement = () => {
  const { username, error, loading, verify } = useVerifyEmail();

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({ email }) => {
    await verify(email);
  };

  return {
    username,
    error,
    loading,
    methods,
    submit,
  };
};
