import React from 'react';
import Box from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { MedicineInfo, LayoutDialog } from 'telemed-core';

import { AddMedicineForm } from '../forms';

type Props = {
  open: boolean;
  onClose: () => void;
  onAdd: (newMedicine: MedicineInfo) => void;
};

const keyPrefix = 'dialogs.AddMedicineDialog';

export const AddMedicineDialog: React.FC<Props> = ({
  open,
  onAdd,
  onClose,
}) => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <LayoutDialog title={t('title')} open={open} handleClose={onClose}>
      <Box container width='800px' minHeight='346px'>
        <AddMedicineForm onAdd={onAdd} />
      </Box>
    </LayoutDialog>
  );
};
