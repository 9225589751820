import React from 'react';
import Div100vh from 'react-div-100vh';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';

import { LogoTypeSvg } from '../resources/svg';
import { LoginForm } from '../forms';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  form: {
    display: 'grid',
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyItems: 'center',
    rowGap: theme.spacing(2),
    '& button': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const LoginContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const classes = useStyles();

    return (
      <Div100vh className={classes.root}>
        <Container maxWidth='xs'>
          <Box>
            <LogoTypeSvg />
          </Box>
          <LoginForm className={classes.form} />
        </Container>
      </Div100vh>
    );
  };
