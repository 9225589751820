import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';

import { LogoTypeSvg } from '../resources/svg';
import { VerifyCodeForm, VerifyEmailForm, RestorePasswordForm } from '../forms';
import { Modal } from 'telemed-core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  formContainer: {
    height: 350,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    color: theme.palette.grey[300],
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyItems: 'center',
    rowGap: theme.spacing(2.5),
  },
}));

export const RestorePassword: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const [username, setUsername] = useState<string>('');
    const [codeValidated, setCodeValidated] = useState<boolean>(false);
    const [passwordRestored, setPasswordRestored] = useState(false);
    const navigate = useNavigate();

    const handleEmailSent = (username: string) => {
      setUsername(username);
    };

    const handleCodeValidated = () => {
      setCodeValidated(true);
    };

    const handleRestorePassword = () => {
      setPasswordRestored(true);
    };

    const handleClickOk = () => {
      navigate('/');
    };

    const classes = useStyles();

    return (
      <Div100vh className={classes.root}>
        <Container maxWidth='xs'>
          <Box
            sx={{
              color: (theme) => theme.palette.grey[100],
            }}
          >
            <LogoTypeSvg />
          </Box>
          <Box className={classes.formContainer}>
            {codeValidated ? (
              <RestorePasswordForm
                className={classes.form}
                username={username}
                onValidate={handleRestorePassword}
              />
            ) : !!username ? (
              <VerifyCodeForm
                className={classes.form}
                username={username}
                onValidate={handleCodeValidated}
              />
            ) : (
              <VerifyEmailForm
                className={classes.form}
                onMailSent={handleEmailSent}
              />
            )}
          </Box>
        </Container>
        {passwordRestored && (
          <Modal
            title='Éxito'
            message='La contraseña se ha actualizado con éxito'
            buttonText='Aceptar'
            onClickOk={handleClickOk}
          />
        )}
      </Div100vh>
    );
  };
