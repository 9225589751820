import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useState } from 'react';
import { Modal } from 'telemed-core';

import {
  CurrentConditionRecordForm,
  HistoricalRecordForm,
  NonPathologicalPersonalRecordForm,
  OtherFamilyMemberRecordForm,
  PathologicalRecordForm,
  // PersonalRecordForm,
  PhysicalExplorationRecordForm,
  RespiratoryApparatusRecordForm,
} from '../forms';

import { MedicalRecordStepper } from './MedicalRecordStepper';
import { PersonalRecordViewer } from './PersonalRecordViewer';

const PERSONAL_RECORD = 0,
  FATHER_HISTORICAL_RECORD = 1,
  MOTHER_HISTORICAL_RECORD = 2,
  PATERNAL_GRANDFATHER_HISTORICAL_RECORD = 3,
  PATERNAL_GRANDMOTHER_HISTORICAL_RECORD = 4,
  MATERNAL_GRANDFATHER_HISTORICAL_RECORD = 5,
  MATERNAL_GRANDMOTHER_HISTORICAL_RECORD = 6,
  OTHER_FAMILY_MEMBER = 7,
  PATHOLOGICAL_PERSONAL_RECORD = 8,
  NON_PATHOLOGICAL_PERSONAL_RECORD = 9,
  PHYSICAL_EXPLORATION_RECORD = 10,
  RESPIRATORY_APPARATUS_RECORD = 11,
  CURRENT_CONDITION_RECORD = 12;

type Props = {
  patientId?: number;
  reduceStepperSize?: boolean;
  returnToPatientDetail?: boolean;
};

const TOTAL_STEPS = 13;

export const MedicalRecord: React.FC<Props> = ({
  reduceStepperSize = false,
  returnToPatientDetail = true,
}): JSX.Element => {
  const [activeStep, setActiveStep] = useState<number>(PERSONAL_RECORD);

  const handleNext = (): void => {
    setActiveStep((activeStep) => activeStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  const handleFinish = (): void => {
    if (!!returnToPatientDetail) {
      window.location.reload();
    } else {
      setActiveStep(PERSONAL_RECORD);
    }
  };

  return (
    <Box>
      <MedicalRecordStepper
        activeStep={activeStep}
        sx={{
          margin: (theme) => `${theme.spacing(5)} 0`,
          transform: reduceStepperSize ? 'scale(0.63)' : 'scale(0.9)',
          transformOrigin: 'top left',
        }}
        total_steps_to_show={TOTAL_STEPS}
      />
      <Box>
        <Container maxWidth='md'>
          {activeStep === PERSONAL_RECORD && (
            <PersonalRecordViewer onNext={handleNext} />
          )}
          {activeStep === FATHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              memberName='father'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === MOTHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              gender='F'
              memberName='mother'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === PATERNAL_GRANDFATHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              memberName='paternalGrandfather'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === PATERNAL_GRANDMOTHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              gender='F'
              memberName='paternalGrandmother'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === MATERNAL_GRANDFATHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              memberName='maternalGrandfather'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === MATERNAL_GRANDMOTHER_HISTORICAL_RECORD && (
            <HistoricalRecordForm
              gender='F'
              memberName='maternalGrandmother'
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === OTHER_FAMILY_MEMBER && (
            <OtherFamilyMemberRecordForm
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === PATHOLOGICAL_PERSONAL_RECORD && (
            <PathologicalRecordForm onNext={handleNext} onBack={handleBack} />
          )}
          {activeStep === NON_PATHOLOGICAL_PERSONAL_RECORD && (
            <NonPathologicalPersonalRecordForm
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === PHYSICAL_EXPLORATION_RECORD && (
            <PhysicalExplorationRecordForm
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === RESPIRATORY_APPARATUS_RECORD && (
            <RespiratoryApparatusRecordForm
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {activeStep === CURRENT_CONDITION_RECORD && (
            <CurrentConditionRecordForm
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
        </Container>
      </Box>
      {activeStep === TOTAL_STEPS && (
        <Modal
          title='Guardado Exitoso'
          message='El expediente médico del paciente se ha guardado correctamente.'
          onClickOk={handleFinish}
        />
      )}
    </Box>
  );
};
