import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import {
  User,
  Layout,
  usePatients,
  UserListItem,
  Filter,
  getCompleteName,
} from 'telemed-core';

const keyPrefix = 'containers.PatientListContainer';

export const PatientListContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [{ data, error: requestError, loading }] = usePatients();
  const [error, setError] = useState<string | null>(null);
  const [list, setList] = useState<User[]>([]);
  const [filteredList, setFilteredList] = useState<User[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.userList) {
      setList(data.userList);
      setFilteredList(data.userList);
    }
    data?.result.error && setError(data.result.responseMessage);
    requestError && setError(requestError.message);
  }, [requestError, data]);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.trim() === '') {
      setFilteredList(list);
      return;
    }

    const filteredList = list.filter((user) => {
      const name = getCompleteName(user, true)
        .replaceAll(' ', '')
        .toLowerCase();
      const clearValue = value.replaceAll(' ', '').toLowerCase();
      return name.includes(clearValue);
    });

    setFilteredList(filteredList);
  };

  return (
    <Layout title={t('title')} error={error} loading={loading}>
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: (theme) => theme.spacing(5),
          marginBottom: (theme) => theme.spacing(3),
        }}
      >
        <div></div>
        <Box width='60%'>
          <Filter
            filterText={t('filter.text')}
            placeholder={t('filter.placeholder')}
            onChange={handleChange}
          />
        </Box>
      </Container>

      <Container maxWidth='md' sx={{ width: 500 }}>
        {!loading && list.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: (theme) => theme.spacing(5),
            }}
          >
            <Typography variant='body1' color='GrayText'>
              {t('empty')}
            </Typography>
          </Box>
        )}
        <List>
          {filteredList.map((user: User, key: number) => {
            const { name, firstLastName, secondLastName, birthday } =
              user.userInfo;
            return (
              <UserListItem
                key={key}
                user={user}
                title={`${name} ${firstLastName} ${secondLastName}`}
                subTitle={calculateAge(birthday)}
                onClick={() => {
                  navigate(`/patient`, {
                    state: {
                      user,
                    },
                  });
                }}
              />
            );
          })}
        </List>
      </Container>
    </Layout>
  );
};

const calculateAge = (birthday: string) => {
  let age = moment().diff(birthday, 'years');
  if (age <= 1) {
    age = moment().diff(birthday, 'months');
    if (age <= 1) {
      age = moment().diff(birthday, 'days');
      return `${age} días`;
    } else {
      return `${age} meses`;
    }
  } else {
    return `${age} años`;
  }
};
