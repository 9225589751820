import { useCallback, useEffect, useState } from 'react';
import { useTreatmentsLazy } from 'telemed-core';

export const useTreatments = (patientId: number) => {
  // const [error, setError] = useState<string | undefined>(undefined);
  // const [loading, setLoading] = useState<boolean>(false);

  const { error, loading, treatments, get } = useTreatmentsLazy();

  // useEffect(() => {
  //   !!gerErr && setError(gerErr);
  //   !!hErr && setError(hErr);
  // }, [gerErr, hErr]);

  // useEffect(() => {
  //   setLoading(getLoading || hLoading);
  // }, [getLoading, hLoading]);

  useEffect(() => {
    console.log('useTreatments', patientId);
    get(patientId);
  }, [get, patientId]);

  const refresh = () => {
    get(patientId);
  };

  return {
    error,
    loading,
    treatments,
    refresh,
  };
};
