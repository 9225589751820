import React from 'react';

export const NotSignSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53 53' {...props}>
      <path
        fill='none'
        stroke='#334c5e'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={22.9256}
        strokeWidth={1.4775}
        d='M44.8 7.8L8.2 45.2'
      />
    </svg>
  );
};
