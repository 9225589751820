import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Consultation, LayoutDialog, Note } from 'telemed-core';

import { AddNoteForm } from '../forms';

type Props = {
  consultation: Consultation;
  onClose: () => void;
  onAdd?: (newNote: Note) => void;
  onEditedConsultation?: (editedConsultation: Consultation) => void;
};

const keyPrefix = 'dialogs.AddNoteDialog';

export const AddNoteDialog: React.FC<Props> = ({
  consultation,
  onClose,
  onEditedConsultation,
}) => {
  const { t } = useTranslation('translation', { keyPrefix });
  console.log('consultation', consultation);

  return (
    <LayoutDialog title={t('title')} open={true} handleClose={onClose}>
      <Box width='700px' minHeight='400px'>
        <AddNoteForm
          originalConsultation={consultation}
          onEditedConsultation={onEditedConsultation}
        />
      </Box>
    </LayoutDialog>
  );
};
