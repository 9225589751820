import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { TelemedProvider, theme } from 'telemed-core';
import './i18n';

import Routes, { menuFooterOptions, menuOptions } from './routes';
import { VideoCallProvider } from './contexts';

ReactDOM.render(
  <React.StrictMode>
    <TelemedProvider
      versionApp='1.0'
      skuApp='doctor'
      menuOptions={menuOptions}
      menuFooterOptions={menuFooterOptions}
    >
      <ThemeProvider theme={theme}>
        <VideoCallProvider>
          <CssBaseline />
          <Routes />
        </VideoCallProvider>
      </ThemeProvider>
    </TelemedProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
