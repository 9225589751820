import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AlcoholInput,
  BasicTextInput,
  BasicYesNoInput,
  Button,
  InputLabel,
  Loading,
  Modal,
  SmokeInput,
  WeeklyFeedingInput,
  YesNoInput,
} from 'telemed-core';

import {
  useNonPathologicalRecordFormManagement,
  useMedicalRecord,
} from '../hooks';
// import { useNonPathologicalPersonalRecordForm, useMedicalRecord } from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.NonPathologicalPersonalRecordForm';

export const NonPathologicalPersonalRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { defaultValues, methods, preSave, submit } =
    useNonPathologicalRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput
              name='bathFrecuency'
              maxLength={30}
              label={t('bath.label')}
              placeholder={t('bath.placeholder')}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput
              name='toothBrushingFrecuency'
              maxLength={30}
              label={t('toothBrushing.label')}
              placeholder={t('toothBrushing.placeholder')}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <WeeklyFeedingInput
              name='weeklyFeeding'
              label={t('weeklyFeeding.label')}
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <SmokeInput
              labels={{
                main: t('smoke.labels.main'),
                start: t('smoke.labels.start'),
                tobaccoPerPay: t('smoke.labels.tobaccoPerPay'),
                years: t('smoke.labels.years'),
              }}
              names={{
                status: 'smokeStatus',
                start: 'smokeStart',
                years: 'smokeYears',
                tobaccoPerPay: 'tobaccoPerPay',
              }}
              showTextBox={defaultValues.smokeStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <AlcoholInput
              labels={{
                main: t('alcohol.labels.main'),
                startingAge: t('alcohol.labels.startingAge'),
              }}
              names={{
                answer: 'alcoholAnswer',
                status: 'alcoholStatus',
                startingAge: 'alcoholStartingAge',
              }}
              showTextBox={defaultValues.alcoholStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <YesNoInput
              labels={{
                main: t('drugAddiction.labels.main'),
                extra: t('drugAddiction.labels.extra'),
              }}
              names={{
                extra: 'drugAddiction',
                status: 'drugAddictionStatus',
              }}
              showTextBox={defaultValues.drugAddictionStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <YesNoInput
              labels={{
                main: t('pet.labels.main'),
                extra: t('pet.labels.extra'),
              }}
              names={{
                extra: 'petType',
                status: 'petStatus',
              }}
              showTextBox={defaultValues.petStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('childhoodImmunizations.label')} />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.influence') }}
              name='childhoodInfluence'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.chickenpox') }}
              name='childhoodChickenpox'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.measles') }}
              name='childhoodMeasles'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.hepatitis') }}
              name='childhoodHepatitis'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.pentavalent') }}
              name='childhoodPentavalent'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.tetanus') }}
              name='childhoodTetanus'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.HPV') }}
              name='childhoodHPV'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.polio') }}
              name='childhoodPolio'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.BCG') }}
              name='childhoodBCG'
            />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            sx={{ paddingTop: '0px !important', minHeight: '75.1px' }}
          >
            <BasicYesNoInput
              name='childhoodPneumococcus'
              extra={{
                name: 'childhoodPneumococcusExcersice',
                label: t('childhoodImmunizations.pneumococcusExcersice'),
              }}
              labels={{
                main: t('childhoodImmunizations.pneumococcus'),
              }}
              showTextBox={defaultValues.childhoodPneumococcus === 'true'}
            />
          </Grid>

          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{
              paddingTop: '0px !important',
              alignItems: 'center',
              minHeight: '70.1px',
            }}
          >
            <Grid item md={3}>
              <Typography variant='body2'>
                {t('childhoodImmunizations.other')}
              </Typography>
            </Grid>
            <Grid item md={9}>
              <BasicTextInput name='childhoodOther' maxLength={30} />
            </Grid>
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('adultImmunizations.label')} />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.hepatitis') }}
              name='adultHepatitis'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('childhoodImmunizations.tetanus') }}
              name='adultTetanus'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{ main: t('adultImmunizations.influence') }}
              name='adultInfluence'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              name='adultPneumococcus'
              // extra={{
              //   name: 'adultPneumococcusExcersice',
              //   label: t('adultImmunizations.pneumococcusExcersice'),
              // }}
              labels={{
                main: t('adultImmunizations.pneumococcus'),
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{
              paddingTop: '0px !important',
              alignItems: 'center',
              minHeight: '70.1px',
            }}
          >
            <Grid item md={3}>
              <Typography variant='body2'>
                {t('adultImmunizations.other')}
              </Typography>
            </Grid>
            <Grid item md={9}>
              <BasicTextInput name='adultOther' maxLength={30} />
            </Grid>
          </Grid>

          <Grid item md={12} display='grid' paddingX={0}>
            <YesNoInput
              labels={{
                main: t('trips.labels.main'),
                extra: t('trips.labels.extra'),
              }}
              names={{
                extra: 'tripsAnswer',
                status: 'tripsStatus',
              }}
              large
              // showTextBox={defaultValues.allergiesStatus === 'true'}
            />
          </Grid>

          {/* <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput name='trips' maxLength={100} label={t('trips')} />
          </Grid> */}
          <Grid item md={12}>
            <BasicTextInput
              name='exercise'
              maxLength={100}
              label={t('exercise')}
              // placeholder={t('bath.placeholder')}
            />
          </Grid>

          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
      {/* {added && (
        <Modal
          title='Consulta Agendada'
          message='La consulta fue agendada satisfactoriamente, se envió un correo al paciente con la información de la consulta.'
          onClickOk={onAdded}
        />
      )} */}
    </FormProvider>
  );
};
