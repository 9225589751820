import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Notification from 'react-web-notification';
import { useTimeout } from 'react-use-timeout';
import { Alarm } from 'telemed-core';

import { useAlarms } from '../hooks';

const keyPrefix = 'components.Alarms';

export const Alarms = (): JSX.Element => {
  const { alarms, fetchAlarms } = useAlarms();

  useEffect(() => {
    fetchAlarms();
  }, [fetchAlarms]);

  console.log('alarms', alarms);

  return (
    <>
      {alarms.map((alarm, idx) => (
        <ListItemContent key={idx} {...alarm} />
      ))}
    </>
  );
};

const ListItemContent: React.FC<Alarm> = ({
  date,
  isReminder,
  timeToShowAlarm,
  patientName,
}): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [off, setOff] = useState<boolean>(true);
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItemContent`,
  });

  const { onDeniedNotification } = useAlarms();

  const body = isReminder
    ? t('reminder', { patientName, date })
    : t('body', { patientName });

  const timeout = useTimeout(() => {
    console.log(`NOW Alarm --> ${body} | in ${timeToShowAlarm / 1000} secs`);
    setOff(false);
  }, timeToShowAlarm);

  const handleNotificationOnShow = () => {
    audioRef.current?.play();
  };

  useEffect(() => {
    !off && setOff(true);
  }, [off]);

  useEffect(() => {
    timeout.start(timeToShowAlarm);
  }, [timeToShowAlarm]);

  console.log(
    `Alarm --> off[${off}] | ${body} | in ${timeToShowAlarm / 1000} secs`
  );

  const handlePermissionGranted = () => {
    console.log('Permission Granted');
  };
  const handlePermissionDenied = () => {
    console.log('Permission Denied');
    onDeniedNotification();
  };
  const handleNotSupported = () => {
    console.log('Web Notification not Supported');
  };

  const handleNotificationOnError = (e: any, tag: any) => {
    console.log(e, 'Notification error tag:' + tag);
  };

  return (
    <>
      <Notification
        ignore={off}
        title={t('title')}
        timeout={60 * 1000}
        onShow={handleNotificationOnShow}
        options={{ body, icon: './logo.png' }}
        notSupported={handleNotSupported}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        onError={handleNotificationOnError}
      />
      <audio ref={audioRef} id='sound' preload='auto'>
        <source src='./sound.mp3' type='audio/mpeg' />
        <source src='./sound.ogg' type='audio/ogg' />
        <embed hidden={true} src='./sound.mp3' />
      </audio>
    </>
  );
};
