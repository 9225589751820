import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ISelectItem,
  PhysicalExploration,
  parseEyesInputValue,
  parseMouthInputValue,
  parseBreathingInputValue,
  parseCyanosisInputValue,
  parseHandleInputValue,
  parseBorgInputValue,
  parseQuantityInputValue,
  parseExpectorationColorInputValue,
  parseTimeInputValue,
} from 'telemed-core';

import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';

export interface PhysicalExplorationRecordFormFields {
  eyes: ISelectItem | null;
  mouth: ISelectItem | null;
  breathingType: ISelectItem | null;
  weight: string | null;
  size: string | null;
  height: string | null;
  hydration: string | null;
  awake: string | null;
  cooperativeConscious: string | null;
  clouded: string | null;
  unconscious: string | null;
  quiet: string | null;
  depressed: string | null;
  cyanosisStatus: string | null;
  cyanosis: ISelectItem | null;
  otherRelevantData: string | null;
  lastSixMonthsWeightChangeStatus: string | null;
  intentional: string | null;
  gain: string | null;
  lost: string | null;

  useAccessoryMusclesStatus: string | null;
  nasalElevator: string | null;
  sternocleidomastoid: string | null;
  previousScalene: string | null;
  middleAndLater: string | null;
  externalIntercostals: string | null;
  pectoralisMajorMinor: string | null;

  respiratoryMusclesAssessmentStatus: string | null;

  trapezoids: string | null;
  scalenes: string | null;
  pectorals: string | null;
  rmaSternocleidomastoid: string | null;
  rmaExternalIntercostals: string | null;
  diaphragm: string | null;

  serratos: string | null;
  oblique: string | null;
  rectusAbdominis: string | null;
  transversusAbdominis: string | null;
  internalIntercostals: string | null;

  alveolars: string | null;
  bronchialAlveolar: string | null;
  bronchial: string | null;
  wheezing: string | null;
  condensationSyndrome: string | null;

  coughStatus: string | null;
  uponAwakening: string | null;
  duringTheDay: string | null;
  atNight: string | null;
  continuous: string | null;

  expectorationStatus: string | null;
  quantity: ISelectItem | null;
  time: ISelectItem | null;
  color: ISelectItem | null;
  consistencies: string | null;
  handling: ISelectItem | null;

  feverStatus: string | null;
  feverAnswer: string | null;

  borgScaleRepose: ISelectItem | null;
  borgScaleTalk: ISelectItem | null;
  borgScaleEat: ISelectItem | null;
  borgScaleBath: ISelectItem | null;
  borgScaleWalk: ISelectItem | null;

  hike: string | null;
  exerciseCapacityOther: string | null;

  vitalCapacity: string | null;
  breathingFrequency: string | null;
  heartRate: string | null;
  bloodPressure: string | null;
  atRestInAmbientAir: string | null;
  atRestWithOxygen: string | null;
  activeInAmbientAir: string | null;
  activeWithOxygen: string | null;
}

const getDefaultValues = (physicalExploration: PhysicalExploration | {}) => {
  console.log('physicalExploration', physicalExploration);
  return {
    eyes:
      'eyes' in physicalExploration
        ? parseEyesInputValue(physicalExploration.eyes)
        : null,
    mouth:
      'mouth' in physicalExploration
        ? parseMouthInputValue(physicalExploration.mouth)
        : null,
    breathingType:
      'breathingType' in physicalExploration
        ? parseBreathingInputValue(physicalExploration.breathingType)
        : null,
    weight:
      'weight' in physicalExploration && !!physicalExploration.weight
        ? physicalExploration.weight
        : undefined,
    size:
      'size' in physicalExploration && !!physicalExploration.size
        ? physicalExploration.size
        : undefined,
    height:
      'height' in physicalExploration && !!physicalExploration.height
        ? physicalExploration.height
        : undefined,

    ...('patientAspects' in physicalExploration &&
    !!physicalExploration.patientAspects
      ? {
          hydration:
            'hydration' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.hydration !== undefined
              ? physicalExploration.patientAspects.hydration
                ? 'true'
                : 'false'
              : undefined,
          awake:
            'awake' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.awake !== undefined
              ? physicalExploration.patientAspects.awake
                ? 'true'
                : 'false'
              : undefined,
          cooperativeConscious:
            'cooperativeConscious' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.cooperativeConscious !==
              undefined
              ? physicalExploration.patientAspects.cooperativeConscious
                ? 'true'
                : 'false'
              : undefined,
          clouded:
            'clouded' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.clouded !== undefined
              ? physicalExploration.patientAspects.clouded
                ? 'true'
                : 'false'
              : undefined,
          unconscious:
            'unconscious' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.unconscious !== undefined
              ? physicalExploration.patientAspects.unconscious
                ? 'true'
                : 'false'
              : undefined,
          quiet:
            'quiet' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.quiet !== undefined
              ? physicalExploration.patientAspects.quiet
                ? 'true'
                : 'false'
              : undefined,
          depressed:
            'depressed' in physicalExploration.patientAspects &&
            physicalExploration.patientAspects.depressed !== undefined
              ? physicalExploration.patientAspects.depressed
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('cyanosis' in physicalExploration && !!physicalExploration.cyanosis
      ? {
          cyanosisStatus:
            'status' in physicalExploration.cyanosis &&
            physicalExploration.cyanosis.status !== undefined
              ? physicalExploration.cyanosis.status
                ? 'true'
                : 'false'
              : undefined,
          cyanosis:
            'answer' in physicalExploration.cyanosis &&
            !!physicalExploration.cyanosis.answer
              ? parseCyanosisInputValue(physicalExploration.cyanosis.answer)
              : undefined,
        }
      : {}),

    otherRelevantData:
      'otherRelevantData' in physicalExploration &&
      !!physicalExploration.otherRelevantData
        ? physicalExploration.otherRelevantData
        : undefined,

    ...('lastSixMonthsWeightChange' in physicalExploration &&
    !!physicalExploration.lastSixMonthsWeightChange
      ? {
          lastSixMonthsWeightChangeStatus:
            'status' in physicalExploration.lastSixMonthsWeightChange &&
            physicalExploration.lastSixMonthsWeightChange.status !== undefined
              ? physicalExploration.lastSixMonthsWeightChange.status
                ? 'true'
                : 'false'
              : undefined,
          intentional:
            'intentional' in physicalExploration.lastSixMonthsWeightChange &&
            physicalExploration.lastSixMonthsWeightChange.intentional !==
              undefined
              ? physicalExploration.lastSixMonthsWeightChange.intentional
                ? 'false'
                : 'true'
              : undefined,
          gain:
            'gain' in physicalExploration.lastSixMonthsWeightChange &&
            physicalExploration.lastSixMonthsWeightChange.gain !== undefined
              ? physicalExploration.lastSixMonthsWeightChange.gain.toString()
              : undefined,
          lost:
            'lost' in physicalExploration.lastSixMonthsWeightChange &&
            physicalExploration.lastSixMonthsWeightChange.lost !== undefined
              ? physicalExploration.lastSixMonthsWeightChange.lost.toString()
              : undefined,
        }
      : {}),

    ...('useAccessoryMuscles' in physicalExploration &&
    !!physicalExploration.useAccessoryMuscles
      ? {
          useAccessoryMusclesStatus:
            'status' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.status !== undefined
              ? physicalExploration.useAccessoryMuscles.status
                ? 'true'
                : 'false'
              : undefined,
          nasalElevator:
            'nasalElevator' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.nasalElevator !== undefined
              ? physicalExploration.useAccessoryMuscles.nasalElevator
                ? 'true'
                : 'false'
              : undefined,
          sternocleidomastoid:
            'sternocleidomastoid' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.sternocleidomastoid !==
              undefined
              ? physicalExploration.useAccessoryMuscles.sternocleidomastoid
                ? 'true'
                : 'false'
              : undefined,
          previousScalene:
            'previousScalene' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.previousScalene !==
              undefined
              ? physicalExploration.useAccessoryMuscles.previousScalene
                ? 'true'
                : 'false'
              : undefined,
          middleAndLater:
            'middleAndLater' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.middleAndLater !== undefined
              ? physicalExploration.useAccessoryMuscles.middleAndLater
                ? 'true'
                : 'false'
              : undefined,
          externalIntercostals:
            'externalIntercostals' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.externalIntercostals !==
              undefined
              ? physicalExploration.useAccessoryMuscles.externalIntercostals
                ? 'true'
                : 'false'
              : undefined,
          pectoralisMajorMinor:
            'pectoralisMajorMinor' in physicalExploration.useAccessoryMuscles &&
            physicalExploration.useAccessoryMuscles.pectoralisMajorMinor !==
              undefined
              ? physicalExploration.useAccessoryMuscles.pectoralisMajorMinor
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('respiratoryMusclesAssessment' in physicalExploration &&
    !!physicalExploration.respiratoryMusclesAssessment
      ? {
          respiratoryMusclesAssessmentStatus:
            'status' in physicalExploration.respiratoryMusclesAssessment &&
            physicalExploration.respiratoryMusclesAssessment.status !==
              undefined
              ? physicalExploration.respiratoryMusclesAssessment.status
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('respiratoryMusclesAssessment' in physicalExploration &&
    !!physicalExploration.respiratoryMusclesAssessment &&
    'inspiratory' in physicalExploration.respiratoryMusclesAssessment &&
    !!physicalExploration.respiratoryMusclesAssessment.inspiratory
      ? {
          trapezoids:
            'trapezoids' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .trapezoids !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .trapezoids
                ? 'true'
                : 'false'
              : undefined,
          scalenes:
            'scalenes' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .scalenes !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .scalenes
                ? 'true'
                : 'false'
              : undefined,
          pectorals:
            'pectorals' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .pectorals !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .pectorals
                ? 'true'
                : 'false'
              : undefined,
          rmaSternocleidomastoid:
            'sternocleidomastoid' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .sternocleidomastoid !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .sternocleidomastoid
                ? 'true'
                : 'false'
              : undefined,
          rmaExternalIntercostals:
            'externalIntercostals' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .externalIntercostals !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .externalIntercostals
                ? 'true'
                : 'false'
              : undefined,
          diaphragm:
            'diaphragm' in
              physicalExploration.respiratoryMusclesAssessment.inspiratory &&
            physicalExploration.respiratoryMusclesAssessment.inspiratory
              .diaphragm !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.inspiratory
                  .diaphragm
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('respiratoryMusclesAssessment' in physicalExploration &&
    !!physicalExploration.respiratoryMusclesAssessment &&
    'expiratory' in physicalExploration.respiratoryMusclesAssessment &&
    !!physicalExploration.respiratoryMusclesAssessment.expiratory
      ? {
          serratos:
            'serratos' in
              physicalExploration.respiratoryMusclesAssessment.expiratory &&
            physicalExploration.respiratoryMusclesAssessment.expiratory
              .serratos !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.expiratory
                  .serratos
                ? 'true'
                : 'false'
              : undefined,
          oblique:
            'oblique' in
              physicalExploration.respiratoryMusclesAssessment.expiratory &&
            physicalExploration.respiratoryMusclesAssessment.expiratory
              .oblique !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.expiratory
                  .oblique
                ? 'true'
                : 'false'
              : undefined,
          rectusAbdominis:
            'rectusAbdominis' in
              physicalExploration.respiratoryMusclesAssessment.expiratory &&
            physicalExploration.respiratoryMusclesAssessment.expiratory
              .rectusAbdominis !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.expiratory
                  .rectusAbdominis
                ? 'true'
                : 'false'
              : undefined,
          transversusAbdominis:
            'transversusAbdominis' in
              physicalExploration.respiratoryMusclesAssessment.expiratory &&
            physicalExploration.respiratoryMusclesAssessment.expiratory
              .transversusAbdominis !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.expiratory
                  .transversusAbdominis
                ? 'true'
                : 'false'
              : undefined,
          internalIntercostals:
            'internalIntercostals' in
              physicalExploration.respiratoryMusclesAssessment.expiratory &&
            physicalExploration.respiratoryMusclesAssessment.expiratory
              .internalIntercostals !== undefined
              ? physicalExploration.respiratoryMusclesAssessment.expiratory
                  .internalIntercostals
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('auscultation' in physicalExploration &&
    !!physicalExploration.auscultation &&
    'rales' in physicalExploration.auscultation &&
    !!physicalExploration.auscultation.rales
      ? {
          alveolars:
            'alveolars' in physicalExploration.auscultation.rales &&
            physicalExploration.auscultation.rales.alveolars !== undefined
              ? physicalExploration.auscultation.rales.alveolars
                ? 'true'
                : 'false'
              : undefined,
          bronchialAlveolar:
            'bronchialAlveolar' in physicalExploration.auscultation.rales &&
            physicalExploration.auscultation.rales.bronchialAlveolar !==
              undefined
              ? physicalExploration.auscultation.rales.bronchialAlveolar
                ? 'true'
                : 'false'
              : undefined,
          bronchial:
            'bronchial' in physicalExploration.auscultation.rales &&
            physicalExploration.auscultation.rales.bronchial !== undefined
              ? physicalExploration.auscultation.rales.bronchial
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('auscultation' in physicalExploration &&
    !!physicalExploration.auscultation
      ? {
          wheezing:
            'wheezing' in physicalExploration.auscultation &&
            physicalExploration.auscultation.wheezing !== undefined
              ? physicalExploration.auscultation.wheezing
                ? 'true'
                : 'false'
              : undefined,
          condensationSyndrome:
            'condensationSyndrome' in physicalExploration.auscultation &&
            physicalExploration.auscultation.condensationSyndrome !== undefined
              ? physicalExploration.auscultation.condensationSyndrome
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('currentSymptoms' in physicalExploration &&
    !!physicalExploration.currentSymptoms &&
    'cough' in physicalExploration.currentSymptoms &&
    !!physicalExploration.currentSymptoms.cough
      ? {
          coughStatus:
            'status' in physicalExploration.currentSymptoms.cough &&
            physicalExploration.currentSymptoms.cough.status !== undefined
              ? physicalExploration.currentSymptoms.cough.status
                ? 'true'
                : 'false'
              : undefined,
          uponAwakening:
            'uponAwakening' in physicalExploration.currentSymptoms.cough &&
            physicalExploration.currentSymptoms.cough.uponAwakening !==
              undefined
              ? physicalExploration.currentSymptoms.cough.uponAwakening
                ? 'true'
                : 'false'
              : undefined,
          duringTheDay:
            'duringTheDay' in physicalExploration.currentSymptoms.cough &&
            physicalExploration.currentSymptoms.cough.duringTheDay !== undefined
              ? physicalExploration.currentSymptoms.cough.duringTheDay
                ? 'true'
                : 'false'
              : undefined,
          atNight:
            'atNight' in physicalExploration.currentSymptoms.cough &&
            physicalExploration.currentSymptoms.cough.atNight !== undefined
              ? physicalExploration.currentSymptoms.cough.atNight
                ? 'true'
                : 'false'
              : undefined,
          continuous:
            'status' in physicalExploration.currentSymptoms.cough &&
            physicalExploration.currentSymptoms.cough.continuous !== undefined
              ? physicalExploration.currentSymptoms.cough.continuous
                ? 'true'
                : 'false'
              : undefined,
        }
      : {}),

    ...('currentSymptoms' in physicalExploration &&
    !!physicalExploration.currentSymptoms &&
    'expectoration' in physicalExploration.currentSymptoms &&
    !!physicalExploration.currentSymptoms.expectoration
      ? {
          expectorationStatus:
            'status' in physicalExploration.currentSymptoms.expectoration &&
            physicalExploration.currentSymptoms.expectoration.status !==
              undefined
              ? physicalExploration.currentSymptoms.expectoration.status
                ? 'true'
                : 'false'
              : undefined,
          quantity:
            'quantity' in physicalExploration.currentSymptoms.expectoration &&
            !!physicalExploration.currentSymptoms.expectoration.quantity
              ? parseQuantityInputValue(
                  physicalExploration.currentSymptoms.expectoration.quantity
                )
              : undefined,
          time:
            'time' in physicalExploration.currentSymptoms.expectoration &&
            !!physicalExploration.currentSymptoms.expectoration.time
              ? parseTimeInputValue(
                  physicalExploration.currentSymptoms.expectoration.time
                )
              : undefined,
          color:
            'color' in physicalExploration.currentSymptoms.expectoration &&
            !!physicalExploration.currentSymptoms.expectoration.color
              ? parseExpectorationColorInputValue(
                  physicalExploration.currentSymptoms.expectoration.color
                )
              : undefined,
          consistencies:
            'consistencies' in
              physicalExploration.currentSymptoms.expectoration &&
            !!physicalExploration.currentSymptoms.expectoration.consistencies
              ? physicalExploration.currentSymptoms.expectoration.consistencies
              : undefined,
          handling:
            'handling' in physicalExploration.currentSymptoms.expectoration &&
            !!physicalExploration.currentSymptoms.expectoration.handling
              ? parseHandleInputValue(
                  physicalExploration.currentSymptoms.expectoration.handling
                )
              : undefined,
        }
      : {}),

    ...('currentSymptoms' in physicalExploration &&
    !!physicalExploration.currentSymptoms &&
    'fever' in physicalExploration.currentSymptoms &&
    !!physicalExploration.currentSymptoms.fever
      ? {
          feverStatus:
            'status' in physicalExploration.currentSymptoms.fever &&
            physicalExploration.currentSymptoms.fever.status !== undefined
              ? physicalExploration.currentSymptoms.fever.status
                ? 'true'
                : 'false'
              : undefined,
          feverAnswer:
            'answer' in physicalExploration.currentSymptoms.fever &&
            !!physicalExploration.currentSymptoms.fever.answer
              ? physicalExploration.currentSymptoms.fever.answer.toString()
              : undefined,
        }
      : {}),

    ...('borgScale' in physicalExploration && !!physicalExploration.borgScale
      ? {
          borgScaleRepose:
            'repose' in physicalExploration.borgScale &&
            physicalExploration.borgScale.repose !== undefined
              ? parseBorgInputValue(physicalExploration.borgScale.repose)
              : undefined,
          borgScaleTalk:
            'talk' in physicalExploration.borgScale &&
            physicalExploration.borgScale.talk !== undefined
              ? parseBorgInputValue(physicalExploration.borgScale.talk)
              : undefined,
          borgScaleEat:
            'eat' in physicalExploration.borgScale &&
            physicalExploration.borgScale.eat !== undefined
              ? parseBorgInputValue(physicalExploration.borgScale.eat)
              : undefined,
          borgScaleBath:
            'bath' in physicalExploration.borgScale &&
            physicalExploration.borgScale.bath !== undefined
              ? parseBorgInputValue(physicalExploration.borgScale.bath)
              : undefined,
          borgScaleWalk:
            'walk' in physicalExploration.borgScale &&
            physicalExploration.borgScale.walk !== undefined
              ? parseBorgInputValue(physicalExploration.borgScale.walk)
              : undefined,
        }
      : {}),

    ...('exerciseCapacity' in physicalExploration &&
    !!physicalExploration.exerciseCapacity
      ? {
          hike:
            'hike' in physicalExploration.exerciseCapacity &&
            !!physicalExploration.exerciseCapacity.hike
              ? physicalExploration.exerciseCapacity.hike
              : undefined,
          exerciseCapacityOther:
            'other' in physicalExploration.exerciseCapacity &&
            !!physicalExploration.exerciseCapacity.other
              ? physicalExploration.exerciseCapacity.other
              : undefined,
        }
      : {}),

    ...('lungFunctionTests' in physicalExploration &&
    !!physicalExploration.lungFunctionTests
      ? {
          vitalCapacity:
            'vitalCapacity' in physicalExploration.lungFunctionTests &&
            !!physicalExploration.lungFunctionTests.vitalCapacity
              ? physicalExploration.lungFunctionTests.vitalCapacity
              : undefined,
          breathingFrequency:
            'breathingFrequency' in physicalExploration.lungFunctionTests &&
            !!physicalExploration.lungFunctionTests.breathingFrequency
              ? physicalExploration.lungFunctionTests.breathingFrequency
              : undefined,
          heartRate:
            'heartRate' in physicalExploration.lungFunctionTests &&
            !!physicalExploration.lungFunctionTests.heartRate
              ? physicalExploration.lungFunctionTests.heartRate
              : undefined,
          bloodPressure:
            'bloodPressure' in physicalExploration.lungFunctionTests &&
            !!physicalExploration.lungFunctionTests.bloodPressure
              ? physicalExploration.lungFunctionTests.bloodPressure
              : undefined,
        }
      : {}),

    ...('lungFunctionTests' in physicalExploration &&
    !!physicalExploration.lungFunctionTests &&
    'oxygenSaturation' in physicalExploration.lungFunctionTests &&
    !!physicalExploration.lungFunctionTests.oxygenSaturation
      ? {
          atRestInAmbientAir:
            'atRestInAmbientAir' in
              physicalExploration.lungFunctionTests.oxygenSaturation &&
            !!physicalExploration.lungFunctionTests.oxygenSaturation
              .atRestInAmbientAir
              ? physicalExploration.lungFunctionTests.oxygenSaturation
                  .atRestInAmbientAir
              : undefined,
          atRestWithOxygen:
            'atRestWithOxygen' in
              physicalExploration.lungFunctionTests.oxygenSaturation &&
            !!physicalExploration.lungFunctionTests.oxygenSaturation
              .atRestWithOxygen
              ? physicalExploration.lungFunctionTests.oxygenSaturation
                  .atRestWithOxygen
              : undefined,
          activeInAmbientAir:
            'activeInAmbientAir' in
              physicalExploration.lungFunctionTests.oxygenSaturation &&
            !!physicalExploration.lungFunctionTests.oxygenSaturation
              .activeInAmbientAir
              ? physicalExploration.lungFunctionTests.oxygenSaturation
                  .activeInAmbientAir
              : undefined,
          activeWithOxygen:
            'activeWithOxygen' in
              physicalExploration.lungFunctionTests.oxygenSaturation &&
            !!physicalExploration.lungFunctionTests.oxygenSaturation
              .activeWithOxygen
              ? physicalExploration.lungFunctionTests.oxygenSaturation
                  .activeWithOxygen
              : undefined,
        }
      : {}),
  };
};

export const usePhysicalExplorationRecordFormManagement = (
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  const { getPhysicalExploration, setPhysicalExploration } = useMedicalRecord();
  const physicalExploration = getPhysicalExploration();

  const schema = yup.object().shape({
    eyes: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    mouth: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    breathingType: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    weight: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^[0-9]+([.][0-9]+)?$/, t('measureFormat')),
    size: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^[0-9]+([.][0-9]+)?$/, t('measureFormat')),
    height: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^[0-9]+([.][0-9]+)?$/, t('measureFormat')),
    hydration: yup.string().required(t('required')),
    awake: yup.string().required(t('required')),
    cooperativeConscious: yup.string().required(t('required')),
    clouded: yup.string().required(t('required')),
    unconscious: yup.string().required(t('required')),
    quiet: yup.string().required(t('required')),
    depressed: yup.string().required(t('required')),
    cyanosisStatus: yup.string().required(t('required')),
    cyanosis: yup.object().when('cyanosisStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired(),
    }),
    otherRelevantData: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    lastSixMonthsWeightChangeStatus: yup.string().required(t('required')),
    intentional: yup.string().when('lastSixMonthsWeightChangeStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    gain: yup.string().when('intentional', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    lost: yup.string().when('intentional', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    useAccessoryMusclesStatus: yup.string().required(t('required')),
    nasalElevator: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    sternocleidomastoid: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    previousScalene: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    middleAndLater: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    externalIntercostals: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    pectoralisMajorMinor: yup.string().when('useAccessoryMusclesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    respiratoryMusclesAssessmentStatus: yup.string().required(t('required')),
    trapezoids: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    scalenes: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    pectorals: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    rmaSternocleidomastoid: yup
      .string()
      .when('respiratoryMusclesAssessmentStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    rmaExternalIntercostals: yup
      .string()
      .when('respiratoryMusclesAssessmentStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    diaphragm: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    serratos: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    oblique: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    rectusAbdominis: yup.string().when('respiratoryMusclesAssessmentStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    transversusAbdominis: yup
      .string()
      .when('respiratoryMusclesAssessmentStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    internalIntercostals: yup
      .string()
      .when('respiratoryMusclesAssessmentStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    alveolars: yup.string().required(t('required')),
    bronchialAlveolar: yup.string().required(t('required')),
    bronchial: yup.string().required(t('required')),
    wheezing: yup.string().required(t('required')),
    condensationSyndrome: yup.string().required(t('required')),

    coughStatus: yup.string().required(t('required')),
    uponAwakening: yup.string().when('coughStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    duringTheDay: yup.string().when('coughStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    atNight: yup.string().when('coughStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    continuous: yup.string().when('coughStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    expectorationStatus: yup.string().required(t('required')),
    quantity: yup.object().when('expectorationStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired(),
    }),
    time: yup.object().when('expectorationStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired(),
    }),
    color: yup.object().when('expectorationStatus', {
      is: (val: string | null) => {
        console.log('val', val);
        return val === 'true';
      },
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired(),
    }),
    consistencies: yup.string().when('expectorationStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    handling: yup.object().when('expectorationStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired(),
    }),

    feverStatus: yup.string().required(t('required')),
    feverAnswer: yup.string().when('feverStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .typeError(t('required'))
        .required(t('required'))
        .matches(/^[0-9]+([.][0-9]+)?$/, t('measureFormat')),
      otherwise: yup.string().notRequired(),
    }),
    borgScaleRepose: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    borgScaleTalk: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    borgScaleEat: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    borgScaleBath: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    borgScaleWalk: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),

    hike: yup.string().required(t('required')),
    exerciseCapacityOther: yup.string().required(t('required')),

    vitalCapacity: yup.string().required(t('required')),
    breathingFrequency: yup.string().required(t('required')),
    heartRate: yup.string().required(t('required')),
    bloodPressure: yup.string().required(t('required')),
    atRestInAmbientAir: yup.string().required(t('required')),
    atRestWithOxygen: yup.string().required(t('required')),
    activeInAmbientAir: yup.string().required(t('required')),
    activeWithOxygen: yup.string().required(t('required')),
  });

  const methods = useForm<PhysicalExplorationRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(physicalExploration!),
  });

  useEffect(() => {
    methods.reset(getDefaultValues(physicalExploration!));
  }, [physicalExploration]);

  const submit: SubmitHandler<PhysicalExplorationRecordFormFields> = async (
    data
  ) => {
    console.log('data', data);
    await setPhysicalExploration(data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setPhysicalExploration(data, true);
  };

  return {
    defaultValues: getDefaultValues(physicalExploration!),
    methods,
    preSave,
    submit,
  };
};
