import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@material-ui/core';
import { BasicTextInput, Modal, Loading, Button } from 'telemed-core';

import { useVerifyCodeFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  username: string;
  onValidate: () => void;
}

export const VerifyCodeForm: React.FC<Props> = ({
  className,
  onValidate,
  username,
}) => {
  const { codeValidated, error, loading, methods, submit } =
    useVerifyCodeFormManagement();

  useEffect(() => {
    if (codeValidated) {
      onValidate();
    }
  }, [codeValidated, onValidate]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(({ code }) =>
          submit({ username, code })
        )}
        className={className}
      >
        <Typography
          component='div'
          sx={{
            textTransform: 'uppercase',
          }}
        >
          Introducir Código
        </Typography>
        <BasicTextInput
          name='code'
          type='text'
          placeholder='código'
          rounded
          grey
        />
        {/* <Button color='primary' type='submit'> */}
        <Button buttonProps={{ color: 'primary', type: 'submit' }}>
          validar código
        </Button>
        <Link
          href='/'
          variant='caption'
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: '0.8rem',
            textDecoration: 'none',
            marginTop: (theme) => theme.spacing(1.5),
            textTransform: 'uppercase',
          }}
        >
          Regresar a Inicio
        </Link>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error.toString()} />}
    </FormProvider>
  );
};
