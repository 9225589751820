import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
  AddButton,
  Button,
  DateTimeInput,
  Loading,
  Modal,
  ReminderFrequencyInput,
  User,
} from 'telemed-core';

import { useAddConsultationFormManagement, useAlarms } from '../hooks';

type Props = {
  patient: User;
  onAdded: () => void;
};

const keyPrefix = 'forms.AddConsultationForm';

export const AddConsultationForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, patient, onAdded }) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { areNotificationDenied } = useAlarms();

  const [showSecondAlarm, setShowSecondAlarm] = useState(false);
  const [showNotificationDenied, setShowNotificationDenied] = useState(false);
  const { added, error, loading, methods, submit } =
    useAddConsultationFormManagement(patient);

  const handleClickAdd = () => {
    setShowSecondAlarm(true);
  };

  const handleClickOk = () => {
    areNotificationDenied ? setShowNotificationDenied(true) : onAdded();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <DateTimeInput />
        <ReminderFrequencyInput
          name='reminderOne'
          label='Primer Alarma'
          maxMenuHeight={280}
        />
        {showSecondAlarm ? (
          <ReminderFrequencyInput
            name='reminderTwo'
            label='Segunda Alarma'
            maxMenuHeight={200}
          />
        ) : (
          <span>
            <AddButton label='Agregar otra Alarma' onClick={handleClickAdd} />
          </span>
        )}
        {/* <Button type='submit' className='axnButton' gray> */}
        <Button buttonProps={{ type: 'submit', className: 'axnButton' }}>
          guardar
        </Button>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
      {added && (
        <Modal
          title={t('success.title')}
          message={t('success.message')}
          onClickOk={handleClickOk}
        />
      )}
      {showNotificationDenied && (
        <Modal
          title={t('denied.title')}
          message={t('denied.message')}
          onClickOk={onAdded}
        />
      )}
    </FormProvider>
  );
};
