import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import { calendarStrings, Note } from 'telemed-core';
import moment from 'moment';
import 'moment/locale/es-mx';

type Props = {
  notes: Note[];
};

const keyPrefix = 'components.NotesList';

export const NotesList: React.FC<Props> = ({ notes }): JSX.Element => {
  return (
    <Stack>
      {notes.length > 0 ? (
        notes.map((note: Note, key: number) => (
          <ListItemContent {...note} key={key} />
        ))
      ) : (
        <EmptyList />
      )}
    </Stack>
  );
};

const ListItemContent: React.FC<Note> = ({
  description,
  creationDate,
}): JSX.Element => {
  const convertedDate = moment
    .utc(creationDate)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  return (
    <WrapperBox>
      <Typography variant='body2' color='primary'>
        <Moment calendar={calendarStrings}>{convertedDate}</Moment>
      </Typography>
      <Typography
        variant='body1'
        color='grey[300]'
        sx={{ overflowWrap: 'anywhere' }}
      >
        {description}
      </Typography>
    </WrapperBox>
  );
};

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <WrapperBox>
      <Typography
        color='GrayText'
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='190px'
        variant='body1'
        sx={{ opacity: 0.5 }}
      >
        {t('message')}
      </Typography>
    </WrapperBox>
  );
};

const WrapperBox: React.FC = ({ children }): JSX.Element => (
  <Box
    p={2}
    sx={{
      backgroundColor: (theme) => `${theme.palette.grey[100]}33`,
      border: (theme) => `1px solid ${theme.palette.grey[100]}`,
    }}
  >
    {children}
  </Box>
);
