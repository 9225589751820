import React, { useEffect, useState } from 'react';
import Div100vh, { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import {
  AddButton,
  Avatar,
  Button as TelemedButton,
  Consultation,
  Layout,
  Loading,
  Modal,
  useConsultationLazy,
} from 'telemed-core';

import { useVideoCall } from '../hooks';
import { MedicalRecord, NotesList, VideoCallParticipant } from '../components';
import { CameraSvg, MicroSvg, NotSignSvg } from '../resources/svg';
import { MedicalRecordProvider } from '../contexts';
import { AddNoteForm } from '../forms';

const keyPrefix = 'containers.VideoCallContainer';

export const VideoCallContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const height100vh = use100vh();
  const [height, setHeight] = useState<number | string>('80vh');
  const { t } = useTranslation('translation', { keyPrefix });
  const { callEnded, error, loading, room, exitRoom } = useVideoCall();

  useEffect(() => {
    setHeight(height100vh ? height100vh - 64 : '80vh');
  }, [height100vh]);

  return (
    <Div100vh>
      <Layout
        title={t('title')}
        loading={loading}
        error={error}
        {...((callEnded === null || callEnded) && { onBack: exitRoom })}
      >
        <Grid container height={height} sx={{ overflow: 'hidden' }}>
          <Grid item xs={5} sx={{ height, position: 'relative' }}>
            {!!room ? (
              <>
                <PatientStreaming />
                <DoctorStreaming />
              </>
            ) : (
              <PreStreaming />
            )}
          </Grid>
          <Grid item xs={7} sx={{ overflowY: 'scroll', maxHeight: height }}>
            <DataContainer />
          </Grid>
        </Grid>
        {callEnded !== null && !!callEnded && <Modals />}
      </Layout>
    </Div100vh>
  );
};

const DataContainer = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.DataContainer`,
  });
  const [value, setValue] = useState('1');
  const { patient } = useVideoCall();

  const patientId = patient!.id;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <MedicalRecordProvider patientId={patientId}>
      <TabContext value={value}>
        <TabList onChange={handleChange} centered>
          <Tab label={t('tabs.record')} value='1' />
          <Tab label={t('tabs.notes')} value='2' />
        </TabList>
        <TabPanel value='1'>
          <MedicalRecord returnToPatientDetail={false} reduceStepperSize />
        </TabPanel>
        <TabPanel value='2'>
          <NotesContainer />
        </TabPanel>
      </TabContext>
    </MedicalRecordProvider>
  );
};

const NotesContainer = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.NotesContainer`,
  });

  const { error, loading, get: getAllConsultation } = useConsultationLazy();
  const { consultation: originalConsultation } = useVideoCall();
  const [consultation, setConsultation] = useState<Consultation | null>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  !!error && console.error(error);

  const pullDataConsultation = async () => {
    const consultation = await getAllConsultation();
    setConsultation(
      consultation.find(
        ({ id }: Consultation) => id === originalConsultation!.id
      )
    );
  };

  useEffect(() => {
    if (!!originalConsultation) {
      pullDataConsultation();
    }
  }, [originalConsultation]);

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleEditedConsultation = (editedConsultation: Consultation) => {
    setConsultation(editedConsultation);
    setShowAdd(false);
  };

  // if (loading) return <Loading />; // In case need to show a loading screen
  if (loading) return <></>;

  return (
    <Stack rowGap={5}>
      {showAdd ? (
        <AddNoteForm
          originalConsultation={consultation!}
          rows={5}
          onEditedConsultation={handleEditedConsultation}
        />
      ) : (
        <AddButton label={t('add')} onClick={handleAddClick} />
      )}
      <NotesList notes={consultation?.consultationInfo.notes || []} />
    </Stack>
  );
};

const Modals: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.Modals`,
  });
  const { recordVideo } = useVideoCall();
  const [wasRecord, setWasRecord] = useState<boolean | null>(null);

  const recording = async (flag: boolean) => {
    const res = await recordVideo(flag);
    res !== null && setWasRecord(res);
  };

  return (
    <>
      <Modal
        title={t('record.title')}
        message={t('record.message')}
        buttonText={t('record.ok')}
        cancelText={t('record.cancel')}
        onClickOk={() => {
          recording(true);
        }}
        onClickCancel={() => {
          recording(false);
        }}
      />
      {wasRecord !== null && wasRecord && (
        <Modal
          title={t('wasRecord.title')}
          message={t('wasRecord.message')}
          buttonText={t('wasRecord.ok')}
        />
      )}
      {wasRecord !== null && !wasRecord && (
        <Modal
          title={t('wasNotRecord.title')}
          message={t('wasNotRecord.message')}
          buttonText={t('wasNotRecord.ok')}
        />
      )}
    </>
  );
};

const PreStreaming: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.PreStreaming`,
  });
  const { callEnded, patient, startVideoCall } = useVideoCall();

  const {
    userInfo: { name, firstLastName, secondLastName, profileImage },
  } = patient!;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.palette.grey[200],
      }}
    >
      <Stack rowGap={2} minWidth='350px'>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            opacityHex='44'
            backgroundColor='#EEE'
            iconSize={140}
            size={22}
            borderSizePx={5}
            url={profileImage.url || ''}
          />
        </Box>
        <Typography
          variant='body1'
          sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
        >
          {`${name} ${firstLastName} ${secondLastName}`}
        </Typography>
        {callEnded !== null && !!callEnded ? (
          <Typography
            color='primary'
            fontWeight={700}
            display='flex'
            justifyContent='center'
            fontSize={21}
          >
            {t(`callEnded`)}
          </Typography>
        ) : (
          <TelemedButton buttonProps={{ onClick: startVideoCall }} gray>
            {t('button')}
          </TelemedButton>
        )}
      </Stack>
    </Box>
  );
};

const DoctorStreaming: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.DoctorStreaming`,
  });

  const { room, endVideoCall, toggleVideo, toggleAudio } = useVideoCall();

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: '25px',
          left: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000',
          borderRadius: '15px',
        }}
      >
        <VideoCallParticipant
          key={room!.localParticipant.sid}
          participant={room!.localParticipant}
          style={{
            width: '200px',
            height: '230px',
            borderRadius: '15px',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Stack
        direction='row'
        alignItems='center'
        position='absolute'
        columnGap={2}
        bottom={25}
        right={25}
      >
        <ControlButton onClick={toggleAudio}>
          <MicroSvg width={45} height={45} />
        </ControlButton>
        <ControlButton onClick={toggleVideo}>
          <CameraSvg width={45} height={45} />
        </ControlButton>
        <Box marginLeft={2}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#ED1C24',
              borderRadius: 50,
              height: 45,
              padding: '6px 24px',
            }}
            onClick={endVideoCall}
          >
            {t('endButton')}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const PatientStreaming: React.FC = () => {
  const { participants } = useVideoCall();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      overflow='hidden'
      position='relative'
      height='100%'
      sx={{ backgroundColor: '#EEE' }}
    >
      {!!participants && participants.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            minWidth: '100%',
            backgroundColor: '#000',
          }}
        >
          <VideoCallParticipant
            key={participants[0].sid}
            participant={participants[0]}
            style={{
              objectFit: 'fill',
              minWidth: '100%',
              opacity: 0,
              marginBottom: '-8px',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

type ControlButtonProps = {
  isOn?: boolean;
  onClick: () => void;
};

const ControlButton: React.FC<ControlButtonProps> = ({
  children,
  isOn = true,
  onClick,
}): JSX.Element => {
  const [on, setOn] = useState(isOn);

  const handleClick = (): void => {
    onClick();
    setOn(!on);
  };

  return (
    <IconButton
      size='large'
      onClick={handleClick}
      sx={{ position: 'relative', padding: 0 }}
    >
      {children}
      {!on && (
        <Box sx={{ position: 'absolute' }}>
          <NotSignSvg width={30} height={30} />
        </Box>
      )}
    </IconButton>
  );
};
