import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ExerciseInfo,
  ExerciseStatus,
  ISelectItem,
  MediaType,
  useContentsLazy,
} from 'telemed-core';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// const REQUIRED_TEXT = 'Este campo es obligatorio';

type FormProps = {
  content: ISelectItem;
  each: ISelectItem;
  duration: string;
  durationType: ISelectItem;
  indications: string;
};

export const useAddExerciseFormManagement = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  const { error, get } = useContentsLazy();
  const [contentOptions, setContentOptions] = useState<ISelectItem[]>([]);

  const schema = yup.object().shape({
    content: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    each: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    duration: yup.string().required(t('required')),
    durationType: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    indications: yup.string().required(t('required')),
  });

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const getOptions = async () => {
    const contentList = await get();

    const options = contentList.map(
      ({
        id,
        name,
        description,
        contentInfo: {
          media: { url, urlVideoThumbnail, mediaType },
        },
      }) => {
        return {
          value: id.toString(),
          label: name,
          desc: description,
          image: mediaType === MediaType.IMAGE ? url : urlVideoThumbnail,
        } as ISelectItem;
      }
    );
    setContentOptions(options);
    setLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, [get]);

  const submit: SubmitHandler<FormProps> = async ({
    content,
    each,
    duration,
    durationType,
    indications,
  }) => {
    console.log('submit AddExerciseForm');

    return {
      status: ExerciseStatus.PENDING,
      media: {
        id: +content.value,
      },
      each: +each.value,
      duration: +duration,
      durationType: +durationType.value,
      indications,
    } as ExerciseInfo;

    // setError(null);
    // if (moment(date).isBefore(moment(moment(new Date())), 'minutes')) {
    //   setError('Selecciona una fecha/hora posterior');
    //   return;
    // }
    // const consultationDate = moment(date).utc().format('YYYY-MM-DD HH:mm:00');
    // const dates: DateData[] = [];
    // if (!!reminderOne) {
    //   const date = parseReminder(consultationDate, reminderOne);
    //   !!date && dates.push({ id: 1, date });
    // }
    // if (!!reminderTwo) {
    //   const date = parseReminder(consultationDate, reminderTwo);
    //   !!date && dates.push({ id: 2, date });
    // }
    // const consultation = {
    //   consultationDate,
    //   id: 0,
    //   status: 0,
    //   consultationInfo: {},
    //   referenceDate: { dates },
    //   // user,
    // };
    // await add(consultation);
  };

  return {
    contentOptions,
    error,
    loading,
    methods,
    submit,
  };
};
