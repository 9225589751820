import React from 'react';
// import Div100vh from 'react-div-100vh';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';

import {
  Layout,
  // Button,
  // Loading,
  LogoTypeSvg,
  // Modal,
  // useAcceptTyC,
  // useTelemed,
} from 'telemed-core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  // axn: {
  //   marginTop: theme.spacing(3),
  //   marginBottom: theme.spacing(10),
  //   '& button': {
  //     marginLeft: theme.spacing(3),
  //     marginRight: theme.spacing(3),
  //   },
  // },
}));

export const AboutContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Layout title=''>
      <Container maxWidth='md' sx={{ marginTop: (theme) => theme.spacing(5) }}>
        <Box className={classes.root}>
          <Container maxWidth='lg'>
            <Box
              sx={{
                color: (theme) => theme.palette.grey[100],
              }}
            >
              <LogoTypeSvg width={415} />
            </Box>
            <Typography
              sx={{
                paddingTop: (theme) => theme.spacing(5),
                color: (theme) => theme.palette.grey[300],
                fontWeight: 'bold',
                fontSize: '1.25rem',
              }}
            >
              Versión 1.0
            </Typography>
            {/* <Typography
              component='div'
              sx={{
                color: (theme) => theme.palette.primary.main,
                marginTop: (theme) => theme.spacing(10),
                marginBottom: (theme) => theme.spacing(2),
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Acerca de
            </Typography> */}
            <Typography
              variant='body1'
              sx={{
                color: (theme) => theme.palette.grey[500],
                textAlign: 'left',
              }}
            >
              {/* Texto configurado de prueba */}
              {/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel illum dolore eu
              feugiat nulla facilisis at vero eros et accumsan et iusto odio
              dignissim qui blandit praesent luptatum zzril delenit augue duis
              dolore te feugait nulla facilisi.Lorem ipsum dolor sit amet, cons
              ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
              ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
              minim veniam, quis nostrud exerci tation ullamcorper suscipit
              lobortis nisl ut aliquip ex ea commodo consequat.
              <br />
              <br />
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel illum dolore eu
              feugiat nulla facilisis at vero eros et accumsan et iusto odio
              dignissim qui blandit praesent luptatum zzril delenit augue duis
              dolore te feugait nulla facilisi. */}
            </Typography>
          </Container>
          {/* {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />} */}
        </Box>
      </Container>
    </Layout>
  );
};
