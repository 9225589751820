import { SxProps } from '@material-ui/system';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import { Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const keyPrefix = 'components.MedicalRecordStepper';

type Props = {
  total_steps_to_show: number;
  activeStep: number;
  sx?: SxProps<Theme>;
};

export const MedicalRecordStepper: React.FC<Props> = ({
  total_steps_to_show,
  activeStep,
  sx,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={sx}>
      {[...new Array(total_steps_to_show)].map((_, idx) => (
        <Step key={idx} sx={{ minWidth: '93.4px' }}>
          <StepButton disableRipple>{t(`${idx}`)}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
