import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/es-mx';
import {
  calendarStrings,
  Consultation,
  useConsultationHistoryLazy,
  Separator,
  AddButton,
  Loading,
  Modal,
  Media,
  Note,
} from 'telemed-core';
import Stack from '@material-ui/core/Stack';
import { NotesList } from './NotesList';
import { AddNoteDialog } from '../dialogs';

type Props = {
  patientId: number;
};

const keyPrefix = 'components.ConsultationHistoryList';

export const ConsultationHistoryList: React.FC<Props> = ({
  patientId,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading, getByPatientId } = useConsultationHistoryLazy();
  const [consultations, setConsultations] = useState<Consultation[]>([]);

  const getConsultations = React.useCallback(async function getConsultations() {
    const consultationList = await getByPatientId(patientId);
    setConsultations(consultationList);
  }, []);

  useEffect(() => {
    getConsultations();
  }, [getConsultations]);

  if (consultations.length === 0) return <EmptyList />;
  if (loading) return <Loading />;

  return (
    <Box>
      <List>
        {consultations.map((consultation: Consultation, key: number) => (
          <Box key={key}>
            <ListItemContent {...consultation} />
            <Separator />
          </Box>
        ))}
      </List>
      {loading && <Loading />}
      {error != null && (
        <Modal title={t('modals.error.title')} message={error} />
      )}
    </Box>
  );
};

const ListItemContent: React.FC<Consultation> = (
  originalConsultation
): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItemContent`,
  });

  const [consultation, setConsultation] = useState<Consultation | null>(
    originalConsultation
  );
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const handleClose = () => {
    setShowAdd(false);
  };

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleEditedConsultation = (editedConsultation: Consultation) => {
    setConsultation(editedConsultation);
    setShowAdd(false);
  };

  const convertedDate = moment
    .utc(consultation!.consultationDate)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: (theme) => theme.spacing(3),
        marginBottom: (theme) => theme.spacing(5),
      }}
    >
      <Stack direction='row' width='100%' justifyContent='space-between'>
        <Typography
          variant='body2'
          color='primary'
          fontWeight='bold'
          alignSelf='center'
        >
          <Moment calendar={calendarStrings}>{convertedDate}</Moment>
        </Typography>
        <AddButton label={t('add')} onClick={handleAddClick} />
      </Stack>
      <Stack
        direction='row'
        display='grid'
        gridTemplateColumns='300px 1fr'
        width='100%'
        columnGap={4}
      >
        <RecordedVideo mediaInfo={consultation!.consultationInfo.mediaInfo} />
        <NotesList notes={consultation!.consultationInfo.notes || []} />
      </Stack>
      {showAdd && (
        <AddNoteDialog
          consultation={consultation!}
          onClose={handleClose}
          onEditedConsultation={handleEditedConsultation}
        />
      )}
    </ListItem>
  );
};

type RecordedVideoProps = {
  mediaInfo: Media | undefined;
};

const RecordedVideo: React.FC<RecordedVideoProps> = ({ mediaInfo }) => {
  if (!mediaInfo) return <EmptyVideo />;
  const { status } = mediaInfo;
  if (status === 0) return <EmptyVideo isPending />;
  return <CardMedia src={mediaInfo.url} component='video' controls={true} />;
};

type EmptyVideoProps = {
  isPending?: boolean;
};

const EmptyVideo: React.FC<EmptyVideoProps> = ({
  isPending = false,
}): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyVideo`,
  });

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='225px'
      sx={{
        backgroundColor: (theme) => `${theme.palette.grey[100]}33`,
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
      }}
    >
      <Typography
        variant='body1'
        color='GrayText'
        sx={{ opacity: isPending ? 0.8 : 0.5 }}
      >
        {t(isPending ? 'isPending' : 'message')}
      </Typography>
    </Box>
  );
};

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <Box display='flex' justifyContent='center' mt={7}>
      <Typography variant='body1' color='GrayText' sx={{ opacity: 0.5 }}>
        {t('message')}
      </Typography>
    </Box>
  );
};
