import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useVerifyCode } from 'telemed-core';

const REQUIRED_TEXT = 'Este campo es obligatorio';

const schema = yup.object().shape({
  code: yup.string().required(REQUIRED_TEXT),
});

type FormProps = {
  username: string;
  code: string;
};

export const useVerifyCodeFormManagement = () => {
  const { codeValidated, error, loading, verify } = useVerifyCode();

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({ username, code }) => {
    await verify(username, code);
  };

  return {
    codeValidated,
    error,
    loading,
    methods,
    submit,
  };
};
