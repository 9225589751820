import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UserLoginParams, useTelemed } from 'telemed-core';

const EMAIL_TEXT = 'Intenta con tu correo electrónico';
const REQUIRED_TEXT = 'Este campo es obligatorio';

const schema = yup.object().shape({
  username: yup.string().email(EMAIL_TEXT).required(REQUIRED_TEXT),
  password: yup.string().required(REQUIRED_TEXT),
});

export const useLoginFormManagement = () => {
  const { login, error, loading } = useTelemed();

  const methods = useForm<UserLoginParams>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<UserLoginParams> = (data) => {
    login(data);
  };

  return {
    error,
    loading,
    methods,
    submit,
  };
};
