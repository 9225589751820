import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  BasicTextInput,
  Modal,
  Loading,
  InputLabel,
} from 'telemed-core';

import { useUpdatePasswordFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  showMessage?: boolean;
}

const keyPrefix = 'forms.UpdatePasswordForm';

export const UpdatePasswordForm: React.FC<Props> = ({
  className,
  showMessage = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { updated, error, loading, methods, submit } =
    useUpdatePasswordFormManagement();

  //TODO: Crear opción para mostar mensaje de éxito

  return (
    <FormProvider {...methods}>
      <Typography
        sx={{
          fontSize: '1.2rem',
          fontFamily: 'AntennaCondBeta',
          fontWeight: 'bold',
          color: (theme) => theme.palette.info.dark,
          marginRight: (theme) => theme.spacing(0.25),
          paddingTop: (theme) => theme.spacing(4.5),
        }}
      >
        Cambio de Contraseña{' '}
      </Typography>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Typography
          variant='body2'
          sx={{
            textAlign: 'justify',
            lineHeight: '1.1rem',
            fontFamily: 'AntennaCondBeta',
            letterSpacing: 0,
          }}
        >
          {t('instructions')}
        </Typography>
        <BasicTextInput
          name='password'
          type='password'
          placeholder='Contraseña actual'
        />
        <Box className='new-password'>
          <BasicTextInput
            // label='Nueva Contraseña'
            name='newPassword'
            type='password'
            placeholder='Nueva contraseña'
          />
          <BasicTextInput
            name='reNewPassword'
            type='password'
            placeholder='Confirmar nueva contraseña'
          />
        </Box>
        {/* <Button type='submit' gray> */}
        <Button buttonProps={{ type: 'submit' }} gray>
          {t('submit.label')}
        </Button>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
      {updated && showMessage && (
        <Modal
          title={t('modals.success.title')}
          message={t('modals.success.message')}
        />
      )}
    </FormProvider>
  );
};
