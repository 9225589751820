import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MemberFamilyHistory } from 'telemed-core';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';

export interface OtherFamilyMemberRecordFormFields {
  otherFamilyMember: string | null;
}

const keyPrefix = 'general';

export const useOtherFamilyMemberRecordFormManagement = (
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getOtherFamilyMember, setOtherFamilyMember } = useMedicalRecord();
  const otherFamilyMember = getOtherFamilyMember();

  const schema = yup.object().shape({
    otherFamilyMember: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<OtherFamilyMemberRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: { otherFamilyMember },
  });

  useEffect(() => {
    methods.reset({ otherFamilyMember });
  }, [otherFamilyMember]);

  const submit: SubmitHandler<OtherFamilyMemberRecordFormFields> = async (
    data
  ) => {
    await setOtherFamilyMember(data.otherFamilyMember || undefined, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setOtherFamilyMember(data.otherFamilyMember || undefined, true);
  };

  return {
    methods,
    preSave,
    submit,
  };
};
