import React from 'react';
import { Router } from '@reach/router';
import { LayoutContainer, useTelemed, MenuOption } from 'telemed-core';

import { AlarmsProvider } from '../contexts';
import { useVideoCall } from '../hooks';
import {
  AboutContainer,
  ConfigContainer,
  HelpContainer,
  LoginContainer,
  MovilAccessContainer,
  PatientDetailContainer,
  PatientListContainer,
  RestorePassword,
  ScheduleContainer,
  TermsContainer,
  TyCContainer,
  UpdatePasswordContainer,
  VideoCallContainer,
} from '../containers';

import { Alarms } from '../components';

const Routes: React.FC = () => {
  const { isAuth, isMobile, user } = useTelemed();
  const { isInsideRoom } = useVideoCall();

  if (isMobile)
    return (
      <Router>
        <MovilAccessContainer path='/*' />
      </Router>
    );

  if (!isAuth) {
    return (
      <Router>
        <LoginContainer path='/*' />
        <RestorePassword path='/restore' />
      </Router>
    );
  }

  if (user.firstLogin) {
    return (
      <Router>
        <LayoutContainer path='/*'>
          <UpdatePasswordContainer path='/' />
        </LayoutContainer>
      </Router>
    );
  }

  if (!user.tcAccepted) {
    return (
      <Router>
        <TermsContainer path='/*' />
      </Router>
    );
  }

  return (
    <AlarmsProvider>
      <Alarms />
      <Router>
        {isInsideRoom ? (
          <VideoCallContainer path='/*' />
        ) : (
          <LayoutContainer path='/'>
            <ScheduleContainer path='/' />
            <PatientListContainer path='patients' />
            <PatientDetailContainer path='patient' />
            <ConfigContainer path='config' />
            <HelpContainer path='help' />
            <TyCContainer path='terms' />
            <AboutContainer path='about' />
          </LayoutContainer>
        )}
      </Router>
    </AlarmsProvider>
  );
};

export default Routes;

export const menuOptions: MenuOption[] = [
  { label: 'Tu Agenda', href: '/' },
  { label: 'Tus Pacientes', href: '/patients' },
  { label: 'Configuración', href: '/config' },
  { label: 'Soporte y Ayuda', href: '/help' },
];

export const menuFooterOptions: MenuOption[] = [
  { label: 'Ver Términos y Condiciones', href: '/terms' },
  { label: 'Acerca de Telemed', href: '/about' },
];
