import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Loading, BasicTextInput } from 'telemed-core';

import {
  useOtherFamilyMemberRecordFormManagement,
  useMedicalRecord,
} from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.OtherFamilyMemberRecordForm';

export const OtherFamilyMemberRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { methods, preSave, submit } =
    useOtherFamilyMemberRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid
            item
            md={12}
            marginBottom={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='otherFamilyMember'
              maxLength={300}
              minRows={3}
              label={t('otherFamilyMember.label')}
              placeholder={t('otherFamilyMember.placeholder')}
            />
          </Grid>

          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
      {/* {added && (
        <Modal
          title='Consulta Agendada'
          message='La consulta fue agendada satisfactoriamente, se envió un correo al paciente con la información de la consulta.'
          onClickOk={onAdded}
        />
      )} */}
    </FormProvider>
  );
};
