import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import {
  BasicTextInput,
  Button,
  Consultation,
  Loading,
  Modal,
  Note,
} from 'telemed-core';

import { useAddNoteFormManagement } from '../hooks';

type Props = {
  originalConsultation: Consultation;
  rows?: number;
  onAdd?: (newNote: Note) => void;
  onEditedConsultation?: (editedConsultation: Consultation) => void;
};

const keyPrefix = 'forms.AddNoteForm';

export const AddNoteForm: React.FC<Props> = ({
  originalConsultation,
  rows = 13,
  onEditedConsultation,
  onAdd,
}) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const {
    addedNote,
    editedConsultation,
    edited,
    error,
    loading,
    methods,
    submit,
  } = useAddNoteFormManagement(originalConsultation);

  const handleOnClickOk = () => {
    !!onAdd && onAdd(addedNote!);
    !!onEditedConsultation && onEditedConsultation(editedConsultation!);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <BasicTextInput
              name='description'
              maxLength={2000}
              minRows={rows}
              label={t('note.label')}
              placeholder={t('note.placeholder')}
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            justifyContent='center'
            mt={2}
            mb={1}
          >
            <Button
              buttonProps={{ type: 'submit', className: 'axnButton' }}
              gray
            >
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title={t('error.title')} message={error} />}
      {edited && (
        <Modal
          title={t('success.title')}
          message={t('success.message')}
          onClickOk={handleOnClickOk}
        />
      )}
    </FormProvider>
  );
};
