import React from 'react';
import Div100vh from 'react-div-100vh';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';

import {
  Button,
  Loading,
  LogoTypeSvg,
  Modal,
  useAcceptTyC,
  useTelemed,
} from 'telemed-core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
    // marginRight: theme.spacing(15),
    // marginLeft: theme.spacing(15),
    marginTop: theme.spacing(5),
    // overflow: 'clip',
  },
  axn: {
    paddingTop: theme.spacing(10),
    // marginBottom: theme.spacing(10),
    marginBottom: theme.spacing(5),
    '& button': {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  contract: {
    width: '100%',
    // height: '100vh',
    height: '150vh',
  },
}));

type Props = {
  showButtons?: boolean;
};

export const TermsContainer: React.FC<RouteComponentProps & Props> = ({
  showButtons = true,
}): JSX.Element => {
  const { error, loading, update } = useAcceptTyC();
  const { setAcceptTYC, logout } = useTelemed();

  const handleClickDecline = async () => {
    await update(false);
    setAcceptTYC(false);
    logout();
  };

  const handleClickAccept = async () => {
    await update(true);
    setAcceptTYC(true);
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box
        sx={{
          color: (theme) => theme.palette.grey[100],
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: (theme) => theme.spacing(5),
        }}
      >
        <LogoTypeSvg width={415} />
      </Box>
      <object
        type='text/html'
        data={`${
          process.env.REACT_APP_API_BASE_URL || 'https://qa.bk.sivtahc.com'
        }/TyC.html`}
        className={classes.contract}
      ></object>
      <Container>
        <Box className={classes.axn}>
          {showButtons && (
            <>
              <Button
                buttonProps={{ onClick: handleClickDecline }}
                rounded
                gray
              >
                DECLINAR
              </Button>
              <Button buttonProps={{ onClick: handleClickAccept }} rounded>
                ACEPTAR
              </Button>
            </>
          )}
        </Box>
      </Container>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
    </Box>
  );
};
