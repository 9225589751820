import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Loading,
  BasicTextInput,
  InputLabel,
} from 'telemed-core';

import {
  useCurrentConditionRecordFormManagement,
  useMedicalRecord,
} from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.CurrentConditionRecordForm';

export const CurrentConditionRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { methods, preSave, submit } =
    useCurrentConditionRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('currentCondition.label')} />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='currentConditionName'
              maxLength={250}
              minRows={4}
              label={t('currentCondition.name')}
            />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            marginBottom={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='currentConditionDescription'
              maxLength={250}
              minRows={4}
              label={t('currentCondition.description')}
            />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('diagnosisOfTheTreatingPhysician.label')} />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='diagnosisOfTheTreatingPhysicianName'
              maxLength={250}
              minRows={4}
              label={t('diagnosisOfTheTreatingPhysician.name')}
            />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            marginBottom={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='diagnosisOfTheTreatingPhysicianDescription'
              maxLength={250}
              minRows={4}
              label={t('diagnosisOfTheTreatingPhysician.description')}
            />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('addedDiagnostics.label')} />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='addedDiagnosticsName'
              maxLength={250}
              minRows={4}
              label={t('addedDiagnostics.name')}
            />
          </Grid>
          <Grid
            item
            md={12}
            marginLeft={3}
            marginRight={3}
            marginBottom={3}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <BasicTextInput
              name='addedDiagnosticsDescription'
              maxLength={250}
              minRows={4}
              label={t('addedDiagnostics.description')}
            />
          </Grid>

          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};
