import Div100vh from 'react-div-100vh';
import { RouteComponentProps } from '@reach/router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  AppStoreSvg,
  LogoTypeSvg,
  PlayStoreSvg,
  useTelemed,
} from 'telemed-core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export const MovilAccessContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const { isAndroid, isIOS } = useTelemed();
    const classes = useStyles();

    return (
      <Div100vh className={classes.root}>
        <Container maxWidth='md'>
          <Box
            sx={{
              color: (theme) => theme.palette.grey[100],
            }}
          >
            <LogoTypeSvg width={300} />
          </Box>
          <Typography
            component='div'
            sx={{
              fontFamily: 'AntennaCondBeta',
              fontSize: '1.2rem',
              color: (theme) => theme.palette.common.white,
              marginTop: (theme) => theme.spacing(10),
              marginBottom: (theme) => theme.spacing(10),
            }}
          >
            Descarga Sivta Home Care para tu dispositivo móvil, disponible en
            App Store y en Google Play.
          </Typography>
          <Box
            sx={{
              width: 250,
              margin: '0 auto',
            }}
          >
            {isIOS && (
              <Link
                href={
                  process.env.REACT_APP_IOS_APP_URL ||
                  'https://appstore.com/mac/'
                }
              >
                <AppStoreSvg />
              </Link>
            )}
            {isAndroid && (
              <Link
                href={
                  process.env.REACT_APP_ANDROID_APP_URL ||
                  'https://play.google.com/store/apps'
                }
              >
                <PlayStoreSvg />
              </Link>
            )}
          </Box>
        </Container>
      </Div100vh>
    );
  };
