import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MemberFamilyHistory } from 'telemed-core';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';

export interface HistoricalRecordFormFields {
  aliveOrDead: string | null;
  allergiesStatus: string | null;
  allergiesYears: string | null;
  allergiesMonths: string | null;
  arterialHypertensionStatus: string | null;
  arterialHypertensionYears: string | null;
  arterialHypertensionMonths: string | null;
  heartDiseaseStatus: string | null;
  heartDiseaseYears: string | null;
  heartDiseaseMonths: string | null;
  mellitusDiabetesStatus: string | null;
  mellitusDiabetesYears: string | null;
  mellitusDiabetesMonths: string | null;
  endocrinologicalStatus: string | null;
  endocrinologicalYears: string | null;
  endocrinologicalMonths: string | null;
  hematologicStatus: string | null;
  hematologicYears: string | null;
  hematologicMonths: string | null;
  nephropathyStatus: string | null;
  nephropathyYears: string | null;
  nephropathyMonths: string | null;
  pneumologicalStatus: string | null;
  pneumologicalYears: string | null;
  pneumologicalMonths: string | null;
  neurologicalStatus: string | null;
  neurologicalYears: string | null;
  neurologicalMonths: string | null;
  otherStatus: string | null;
  otherDesc: string | null;
}

const keyPrefix = 'general';

const getDefaultValues = (member: MemberFamilyHistory) => {
  return {
    aliveOrDead: member?.aliveOrDead?.toString() || null,
    allergiesStatus:
      !!member &&
      'allergies' in member.diseases &&
      !!member.diseases.allergies &&
      'status' in member.diseases?.allergies &&
      member.diseases.allergies.status !== undefined
        ? member.diseases.allergies.status
          ? 'true'
          : 'false'
        : undefined,
    allergiesYears:
      !!member &&
      'allergies' in member.diseases &&
      !!member.diseases.allergies &&
      'status' in member.diseases?.allergies
        ? member.diseases.allergies.years?.toString()
        : undefined,
    allergiesMonths:
      !!member &&
      'allergies' in member.diseases &&
      !!member.diseases.allergies &&
      'status' in member.diseases?.allergies
        ? member.diseases.allergies.months?.toString()
        : undefined,

    heartDiseaseStatus:
      !!member &&
      'heartDisease' in member.diseases &&
      !!member.diseases.heartDisease &&
      'status' in member.diseases?.heartDisease &&
      member.diseases.heartDisease.status !== undefined
        ? member.diseases.heartDisease.status
          ? 'true'
          : 'false'
        : undefined,
    heartDiseaseYears:
      !!member &&
      'heartDisease' in member.diseases &&
      !!member.diseases.heartDisease &&
      'status' in member.diseases?.heartDisease
        ? member.diseases.heartDisease.years?.toString()
        : undefined,
    heartDiseaseMonths:
      !!member &&
      'heartDisease' in member.diseases &&
      !!member.diseases.heartDisease &&
      'status' in member.diseases?.heartDisease
        ? member.diseases.heartDisease.months?.toString()
        : undefined,

    mellitusDiabetesStatus:
      !!member &&
      'mellitusDiabetes' in member.diseases &&
      !!member.diseases.mellitusDiabetes &&
      'status' in member.diseases?.mellitusDiabetes &&
      member.diseases.mellitusDiabetes.status !== undefined
        ? member.diseases.mellitusDiabetes.status
          ? 'true'
          : 'false'
        : undefined,
    mellitusDiabetesYears:
      !!member &&
      'mellitusDiabetes' in member.diseases &&
      !!member.diseases.mellitusDiabetes &&
      'status' in member.diseases?.mellitusDiabetes
        ? member.diseases.mellitusDiabetes.years?.toString()
        : undefined,
    mellitusDiabetesMonths:
      !!member &&
      'mellitusDiabetes' in member.diseases &&
      !!member.diseases.mellitusDiabetes &&
      'status' in member.diseases?.mellitusDiabetes
        ? member.diseases.mellitusDiabetes.months?.toString()
        : undefined,

    endocrinologicalStatus:
      !!member &&
      'endocrinological' in member.diseases &&
      !!member.diseases.endocrinological &&
      'status' in member.diseases?.endocrinological &&
      member.diseases.endocrinological.status !== undefined
        ? member.diseases.endocrinological.status
          ? 'true'
          : 'false'
        : undefined,
    endocrinologicalYears:
      !!member &&
      'endocrinological' in member.diseases &&
      !!member.diseases.endocrinological &&
      'status' in member.diseases?.endocrinological
        ? member.diseases.endocrinological.years?.toString()
        : undefined,
    endocrinologicalMonths:
      !!member &&
      'endocrinological' in member.diseases &&
      !!member.diseases.endocrinological &&
      'status' in member.diseases?.endocrinological
        ? member.diseases.endocrinological.months?.toString()
        : undefined,

    hematologicStatus:
      !!member &&
      'hematologic' in member.diseases &&
      !!member.diseases.hematologic &&
      'status' in member.diseases?.hematologic &&
      member.diseases.hematologic.status !== undefined
        ? member.diseases.hematologic.status
          ? 'true'
          : 'false'
        : undefined,
    hematologicYears:
      !!member &&
      'hematologic' in member.diseases &&
      !!member.diseases.hematologic &&
      'status' in member.diseases?.hematologic
        ? member.diseases.hematologic.years?.toString()
        : undefined,
    hematologicMonths:
      !!member &&
      'hematologic' in member.diseases &&
      !!member.diseases.hematologic &&
      'status' in member.diseases?.hematologic
        ? member.diseases.hematologic.months?.toString()
        : undefined,

    arterialHypertensionStatus:
      !!member &&
      'arterialHypertension' in member.diseases &&
      !!member.diseases.arterialHypertension &&
      'status' in member.diseases?.arterialHypertension &&
      member.diseases.arterialHypertension.status !== undefined
        ? member.diseases.arterialHypertension.status
          ? 'true'
          : 'false'
        : undefined,
    arterialHypertensionYears:
      !!member &&
      'arterialHypertension' in member.diseases &&
      !!member.diseases.arterialHypertension &&
      'status' in member.diseases?.arterialHypertension
        ? member.diseases.arterialHypertension.years?.toString()
        : undefined,
    arterialHypertensionMonths:
      !!member &&
      'arterialHypertension' in member.diseases &&
      !!member.diseases.arterialHypertension &&
      'status' in member.diseases?.arterialHypertension
        ? member.diseases.arterialHypertension.months?.toString()
        : undefined,

    nephropathyStatus:
      !!member &&
      'nephropathy' in member.diseases &&
      !!member.diseases.nephropathy &&
      'status' in member.diseases?.nephropathy &&
      member.diseases.nephropathy.status !== undefined
        ? member.diseases.nephropathy.status
          ? 'true'
          : 'false'
        : undefined,
    nephropathyYears:
      !!member &&
      'nephropathy' in member.diseases &&
      !!member.diseases.nephropathy &&
      'status' in member.diseases?.nephropathy
        ? member.diseases.nephropathy.years?.toString()
        : undefined,
    nephropathyMonths:
      !!member &&
      'nephropathy' in member.diseases &&
      !!member.diseases.nephropathy &&
      'status' in member.diseases?.nephropathy
        ? member.diseases.nephropathy.months?.toString()
        : undefined,

    neurologicalStatus:
      !!member &&
      'neurological' in member.diseases &&
      !!member.diseases.neurological &&
      'status' in member.diseases?.neurological &&
      member.diseases.neurological.status !== undefined
        ? member.diseases.neurological.status
          ? 'true'
          : 'false'
        : undefined,
    neurologicalYears:
      !!member &&
      'neurological' in member.diseases &&
      !!member.diseases.neurological &&
      'status' in member.diseases?.neurological
        ? member.diseases.neurological.years?.toString()
        : undefined,
    neurologicalMonths:
      !!member &&
      'neurological' in member.diseases &&
      !!member.diseases.neurological &&
      'status' in member.diseases?.neurological
        ? member.diseases.neurological.months?.toString()
        : undefined,

    pneumologicalStatus:
      !!member &&
      'pneumological' in member.diseases &&
      !!member.diseases.pneumological &&
      'status' in member.diseases?.pneumological &&
      member.diseases.pneumological.status !== undefined
        ? member.diseases.pneumological.status
          ? 'true'
          : 'false'
        : undefined,
    pneumologicalYears:
      !!member &&
      'pneumological' in member.diseases &&
      !!member.diseases.pneumological &&
      'status' in member.diseases?.pneumological
        ? member.diseases.pneumological.years?.toString()
        : undefined,
    pneumologicalMonths:
      !!member &&
      'pneumological' in member.diseases &&
      !!member.diseases.pneumological &&
      'status' in member.diseases?.pneumological
        ? member.diseases.pneumological.months?.toString()
        : undefined,

    otherStatus:
      !!member &&
      'other' in member.diseases &&
      !!member.diseases.other &&
      'status' in member.diseases?.other &&
      member.diseases.other.status !== undefined
        ? member.diseases.other.status
          ? 'true'
          : 'false'
        : undefined,
    otherDesc:
      !!member &&
      'other' in member.diseases &&
      !!member.diseases.other &&
      'status' in member.diseases?.other
        ? member.diseases.other.name
        : undefined,
  };
};

export const useHistoricalRecordFormManagement = (
  memberName: string,
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getMemberHistory, setMemberHistory } = useMedicalRecord();
  const member = getMemberHistory(memberName);

  const schema = yup.object().shape({
    aliveOrDead: yup.string().typeError(t('required')).required(t('required')),
    allergiesStatus: yup.string().required(t('required')),
    allergiesYears: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    allergiesMonths: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    heartDiseaseStatus: yup.string().required(t('required')),
    heartDiseaseYears: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    heartDiseaseMonths: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    mellitusDiabetesStatus: yup.string().required(t('required')),
    mellitusDiabetesYears: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    mellitusDiabetesMonths: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    endocrinologicalStatus: yup.string().required(t('required')),
    endocrinologicalYears: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    endocrinologicalMonths: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    hematologicStatus: yup.string().required(t('required')),
    hematologicYears: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    hematologicMonths: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    arterialHypertensionStatus: yup.string().required(t('required')),
    arterialHypertensionYears: yup.string().when('arterialHypertensionStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    arterialHypertensionMonths: yup
      .string()
      .when('arterialHypertensionStatus', {
        is: (val: string | null) => val === 'true',
        then: yup
          .string()
          .required(t('required'))
          .matches(/^([0-9]|10|11)$/, t('maxMonth')),
        otherwise: yup.string().notRequired(),
      }),

    nephropathyStatus: yup.string().required(t('required')),
    nephropathyYears: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    nephropathyMonths: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    neurologicalStatus: yup.string().required(t('required')),
    neurologicalYears: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    neurologicalMonths: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    pneumologicalStatus: yup.string().required(t('required')),
    pneumologicalYears: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    pneumologicalMonths: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    otherStatus: yup.string().required(t('required')),
    otherDesc: yup.string().when('otherStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<HistoricalRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(member!),
  });

  useEffect(() => {
    methods.reset(getDefaultValues(member!));
  }, [member]);

  const submit: SubmitHandler<HistoricalRecordFormFields> = async (data) => {
    await setMemberHistory(memberName, data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    setMemberHistory(memberName, data, true);
  };

  return {
    defaultValues: getDefaultValues(member!),
    methods,
    preSave,
    submit,
  };
};
