import React from 'react';

export const PhoneSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      id='Capa_1'
      x={0}
      y={0}
      viewBox='0 0 140 144'
      {...props}
    >
      <style>{'.st0{fill:currentColor}'}</style>
      <path
        d='M111.5 67.4h-.6c-2-.3-3.3-2.2-3-4.1.7-4.1.4-8.3-.8-12.3-1.4-4.6-4-9-7.3-12.3-3.5-3.5-7.6-5.7-11.9-6.3-2.6-.4-5.2-.2-7.6.5-1.9.6-3.9-.5-4.5-2.4-.6-1.9.5-3.9 2.4-4.5 3.5-1 7.1-1.3 10.8-.7 5.8.9 11.2 3.8 15.8 8.3 4.2 4.2 7.4 9.5 9.1 15.4 1.5 5.1 1.9 10.3 1 15.5-.1 1.7-1.6 2.9-3.4 2.9z'
        className='st0'
      />
      <path
        d='M134.1 78.4c-.4 0-.8-.1-1.1-.2-1.9-.6-2.9-2.7-2.3-4.6 3.4-10.4 3.4-21.4 0-31.9-2.4-7.5-6.6-14.5-12-20.2-5.5-5.8-12.1-10-19.2-12.4-8.4-2.7-17.3-2.7-25.8 0-1.9.6-3.9-.4-4.6-2.3-.6-1.9.4-3.9 2.3-4.6 10-3.2 20.5-3.3 30.3 0 8.2 2.7 15.9 7.6 22.2 14.3 6.1 6.4 10.8 14.4 13.6 22.9 3.9 12 3.9 24.6 0 36.4-.4 1.7-1.9 2.6-3.4 2.6zm-61.8 28c-9.6 0-21-6.5-29.5-17.7-11.5-15-13.6-32.7-4.9-41.1 1.4-1.4 3.7-1.4 5.1.1 1.4 1.4 1.4 3.7-.1 5.1-5.9 5.7-3.3 19.8 5.6 31.5 7 9.2 16.6 14.9 23.8 14.9 1.9 0 3.7-.4 5.2-1.3 1.7-1 3.9-.5 5 1.3 1 1.7.5 3.9-1.3 5-2.7 1.4-5.7 2.2-8.9 2.2z'
        className='st0'
      />
      <path
        d='M86.9 144c-6.1 0-13.5-2.5-21.6-7.5-12.5-7.6-26-20.4-38-36.1C1.8 67.2-7.2 33.8 6.2 22.7c7.6-6.2 8.7-6.8 10.2-7 2-.2 3.8 1.3 3.9 3.3.2 1.7-.9 3.3-2.5 3.8-.6.3-2.2 1.5-7 5.5-4.2 3.5-4.7 12.5-1.4 24C13.2 65.6 21.6 81.2 33 96c24.3 31.7 50.4 45.9 59.6 39.1 5.2-3.8 6.8-5.2 7.3-5.6 1.1-1.5 3.2-2 4.8-1 1.7 1 2.3 3.2 1.3 5-.6 1-1.2 1.7-9 7.5-2.9 2-6.2 3-10.1 3zm12.8-14.2zm.2-.3zM18 22.7z'
        className='st0'
      />
      <path
        d='M108.4 132.4c-.3 0-.7 0-1-.1-2-.3-3.8-1.3-5-2.9L79.7 99.8c-2.5-3.3-1.9-8 1.4-10.5l10.3-7.9c3.3-2.5 8-1.9 10.5 1.4l22.7 29.6c1.2 1.6 1.7 3.6 1.5 5.5-.3 2-1.3 3.8-2.9 5l-10.3 7.9c-1.3 1-2.9 1.6-4.5 1.6zM96 87.1c-.1 0-.1 0-.2.1L85.5 95c-.1.1-.1.3 0 .4l22.7 29.6c0 .1.1.1.2.1h.2l10.3-7.9c.1 0 .1-.1.1-.2v-.2L96.2 87.2c-.1 0-.1-.1-.2-.1zM47.4 52.8c-2.3 0-4.5-1-6-2.9L18.8 20.3c-2.5-3.3-1.9-8 1.4-10.5l10.3-7.9C33.8-.6 38.5 0 41 3.3l22.7 29.6c2.5 3.3 1.9 8-1.4 10.5L52 51.3c-1.4 1-3 1.5-4.6 1.5zm-.2-7.3c.1.1.3.1.4.1l10.3-7.9c.1-.1.1-.3 0-.4L35.2 7.7c-.1-.1-.3-.1-.4-.1l-10.3 7.9c-.1.1-.1.3 0 .4l22.7 29.6z'
        className='st0'
      />
    </svg>
  );
};
