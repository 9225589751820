import React from 'react';
import { Box, Grid, Stack, Typography } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  EachInput,
  Modal,
  Loading,
  BasicTextInput,
  InputLabel,
  BasicYesNoInput,
  ViaInput,
} from 'telemed-core';

import {
  useRespiratoryApparatusRecordFormManagement,
  useMedicalRecord,
} from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.RespiratoryApparatusRecordForm';

export const RespiratoryApparatusRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { methods, preSave, submit } =
    useRespiratoryApparatusRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('asthma.label')} />
          </Grid>
          <HowLong names={{ years: 'asthmaYears', months: 'asthmaMonths' }} />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='asthmaMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('emphysema.label')} />
          </Grid>
          <HowLong
            names={{ years: 'emphysemaYears', months: 'emphysemaMonths' }}
          />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='emphysemaMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('respiratoryInfections.label')} />
          </Grid>
          <HowLong
            names={{
              years: 'respiratoryInfectionsYears',
              months: 'respiratoryInfectionsMonths',
            }}
          />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='respiratoryInfectionsMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('chronicBronchitis.label')} />
          </Grid>
          <HowLong
            names={{
              years: 'chronicBronchitisYears',
              months: 'chronicBronchitisMonths',
            }}
          />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='chronicBronchitisMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('cancer.label')} />
          </Grid>
          <TypeFields name='typeOfCancer' label={t('cancer.type')} />
          <HowLong
            names={{
              years: 'cancerYears',
              months: 'cancerMonths',
            }}
          />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='cancerMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('surgeries.label')} />
          </Grid>
          <TypeFields name='typeOfSurgery' label={t('surgeries.type')} />
          <HowLong
            names={{
              years: 'surgeriesYears',
              months: 'surgeriesMonths',
            }}
          />
          <Grid item md={6} marginRight={2}>
            <Medicaments name='surgeriesMedicament' />
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('smokingIndex.label')} />
          </Grid>
          <Grid
            item
            md={5}
            display='flex'
            flexDirection='row'
            paddingX={0}
            marginLeft={6}
          >
            <Stack direction='row' spacing={3}>
              <BasicTextInput
                name='yearsSmoking'
                maxLength={2}
                onlyNumbers={true}
                label={t('smokingIndex.yearsSmoking')}
              />
              <BasicTextInput
                name='yearsWithoutSmoking'
                maxLength={2}
                onlyNumbers={true}
                label={t('smokingIndex.yearsWithoutSmoking')}
              />
            </Stack>
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('pulmonaryPhysiotherapyTreatments.label')} />
          </Grid>
          <Treatment
            name='percussionSpan'
            label={t('pulmonaryPhysiotherapyTreatments.percussionSpan')}
          />
          <Treatment
            name='vibration'
            label={t('pulmonaryPhysiotherapyTreatments.vibration')}
          />
          <Treatment
            name='IPV'
            label={t('pulmonaryPhysiotherapyTreatments.IPV')}
          />
          <Treatment
            name='strikerVest'
            label={t('pulmonaryPhysiotherapyTreatments.strikerVest')}
          />
          <Treatment
            name='assistedCough'
            label={t('pulmonaryPhysiotherapyTreatments.assistedCough')}
          />

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('medicinesInfo.label')} />
          </Grid>
          <TypeFields
            name='medicinesInfoName'
            label={t('medicinesInfo.name')}
          />
          <TypeFields
            name='medicinesInfoDose'
            label={t('medicinesInfo.dose')}
          />
          <Grid item md={4} paddingX={0} marginLeft={6}>
            <ViaInput name='medicinesInfoVia' label={t('medicinesInfo.via')} />
          </Grid>
          <Grid item md={4} paddingX={0} marginRight={3} paddingBottom={5}>
            <EachInput
              name='medicinesInfoEach'
              label={t('medicinesInfo.each')}
            />
          </Grid>

          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};

type TreatmentFieldsProps = {
  label: string;
  name: string;
};

const Treatment: React.FC<TreatmentFieldsProps> = ({
  label,
  name,
}): JSX.Element => {
  return (
    <Grid
      item
      md={12}
      display='flex'
      flexDirection='row'
      marginLeft={6}
      sx={{ paddingTop: '0px !important' }}
    >
      <BasicYesNoInput
        labels={{
          main: label,
        }}
        name={name}
      />
    </Grid>
  );
};

type TypeFieldsProps = {
  label: string;
  name: string;
};

const TypeFields: React.FC<TypeFieldsProps> = ({
  label,
  name,
}): JSX.Element => {
  return (
    <>
      <Grid
        item
        md={2}
        display='flex'
        alignItems='center'
        flexDirection='row'
        paddingX={0}
        marginLeft={6}
      >
        <InputLabel label={label} />
      </Grid>
      <Grid item md={7} display='flex' flexDirection='row' paddingX={0}>
        <BasicTextInput name={name} maxLength={30} />
      </Grid>
    </>
  );
};

const Medicaments: React.FC<{ name: string }> = ({ name }): JSX.Element => {
  return (
    <BasicTextInput
      name={name}
      maxLength={30}
      minRows={4}
      label='Medicamentos'
    />
  );
};

type HowLongProps = {
  names: {
    years: string;
    months: string;
  };
  labels?: {
    main?: string;
    years?: string;
    months?: string;
  };
};

const HowLong: React.FC<HowLongProps> = ({ names, labels }): JSX.Element => {
  return (
    <Grid item md={3} marginLeft={6}>
      <Stack spacing={3} sx={{ width: '176px', marginTop: '1px' }}>
        <Box sx={{ position: 'relative', paddingRight: '6px' }}>
          <BasicTextInput
            name={names.years}
            maxLength={2}
            onlyNumbers={true}
            label={labels?.main || 'Fecha de diagnóstico'}
          />
          <Typography
            sx={{
              textAlign: 'center',
              position: 'absolute',
              right: '15px',
              bottom: '10px',
            }}
          >
            {labels?.years || 'años'}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', paddingRight: '6px' }}>
          <BasicTextInput
            name={names.months}
            maxLength={2}
            onlyNumbers={true}
          />
          <Typography
            sx={{
              textAlign: 'center',
              position: 'absolute',
              right: '15px',
              bottom: '10px',
            }}
          >
            {labels?.months || 'meses'}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
};
