import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BasicSelectInput,
  BasicTextInput,
  Button,
  DurationTypeInput,
  ISelectItem,
  Loading,
  Modal,
  EquipmentInfo,
} from 'telemed-core';

import { useAddEquipFormManagement } from '../hooks';

type Props = {
  onAdd: (newEquipment: EquipmentInfo) => void;
  onClose: () => void;
};

const keyPrefix = 'forms.AddEquipForm';

export const AddEquipForm: React.FC<Props> = ({ onAdd, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { equipmentOptions, error, loading, methods, submit } =
    useAddEquipFormManagement();

  if (loading) {
    return <Loading />;
  }

  const onSubmit = async (fields: any) => {
    const newEquip = await submit(fields);
    console.log(' AddEquipForm-  newEquip: ', newEquip);
    onAdd(newEquip);
  };

  if (equipmentOptions.length === 0) {
    return <NoEquipmentAvailable onClose={onClose} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <EquipmentInput
              equipmentOptions={equipmentOptions}
              label={t('equipment.label')}
              placeholder={t('equipment.placeholder')}
            />
          </Grid>
          <Grid item md={4}>
            <BasicTextInput
              name='duration'
              maxLength={2}
              onlyNumbers={true}
              label={t('duration.label')}
              placeholder={t('duration.placeholder')}
            />
          </Grid>
          <Grid item md={8} display='flex' alignItems='flex-end'>
            <DurationTypeInput
              label={t('durationType.label')}
              placeholder={t('durationType.placeholder')}
            />
          </Grid>
          <Grid item md={12}>
            <BasicTextInput
              name='indications'
              maxLength={300}
              minRows={4}
              label={t('indications.label')}
              placeholder={t('indications.placeholder')}
            />
          </Grid>

          <Grid item md={12} mt={3} display='flex' justifyContent='center'>
            <Button buttonProps={{ type: 'submit' }}>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};

type EquipmentInputInputProps = {
  // maxMenuHeight?: number;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  equipmentOptions: ISelectItem[];
};

const EquipmentInput: React.FC<EquipmentInputInputProps> = ({
  // maxMenuHeight = 200,
  name = 'equipment',
  label = '',
  placeholder = 'Seleccione...',
  required = true,
  equipmentOptions,
}): JSX.Element => {
  return (
    <BasicSelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      options={equipmentOptions}
      // maxMenuHeight={maxMenuHeight}
    />
  );
};

type NoEquipmentAvailableProps = {
  onClose: () => void;
};

const NoEquipmentAvailable: React.FC<NoEquipmentAvailableProps> = ({
  onClose,
}): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.NoEquipmentAvailable`,
  });

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography
        variant='body1'
        color='GrayText'
        marginBottom={3}
        sx={{ opacity: 0.5 }}
      >
        {t('message')}
      </Typography>
      <Button buttonProps={{ onClick: onClose }}>{t('button')}</Button>
    </Box>
  );
};
