import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Consultation, InputLabel, LayoutDialog } from 'telemed-core';

import { EditConsultationForm } from '../forms';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: 400,
    display: 'grid',
    justifyItems: 'center',
    marginTop: theme.spacing(3.25),
    marginBottom: theme.spacing(3.25),
    rowGap: theme.spacing(3),
    '& .axnButton': {
      marginTop: theme.spacing(3),
    },
    '& span': {
      width: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-end',
      '& button': {
        marginTop: 0,
      },
    },
  },
}));

type Props = {
  nameText: string;
  originalDateText: string;
  open: boolean;
  originalConsultation: Consultation;
  handleClose: (needsRefresh?: boolean) => void;
};

export const EditConsultationDialog: React.FC<Props> = ({
  nameText,
  originalDateText,
  open,
  originalConsultation,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <LayoutDialog
      title='Reagendar Consulta'
      open={open}
      handleClose={handleClose}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '132px 1fr',
          paddingBottom: 0,
          '& p': {
            margin: 0,
          },
        }}
      >
        <InputLabel label='Paciente:' />
        <InputLabel label={nameText} />
        <InputLabel label='Fecha Original:' />
        <InputLabel label={originalDateText} />
      </Box>
      <EditConsultationForm
        originalConsultation={originalConsultation}
        className={classes.form}
        onEdited={() => handleClose(true)}
      />
    </LayoutDialog>
  );
};
