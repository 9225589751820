import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import {
  AddressInput,
  BloodTypeInput,
  Button,
  DateInput,
  EmailInput,
  GenderInput,
  MaritalStatusInput,
  Modal,
  NameInput,
  Loading,
  PhoneInput,
  ProfessionalLicenseInput,
  SpecialtyInput,
  UploadButtonInput,
  User,
  InputLabel,
  Output,
} from 'telemed-core';
import 'moment/locale/es-mx';

import { DoctorFormFields, useDoctorFormManagement } from '../hooks';
import Typography from '@material-ui/core/Typography';
import { AvatarUploadInput } from '../components/AvatarUploadInput';
import Grid from '@material-ui/core/Grid';

const keyPrefix = 'forms.DoctorForm';

// interface Props extends React.HTMLAttributes<HTMLDivElement> {
//   user: User;
// }

export const DoctorForm: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [certificate, setCertificate] = useState<File | null>(null);
  const [avatar, setAvatar] = useState<File | null>(null);

  const { error, loading, methods, processedUsername, user, submit } =
    useDoctorFormManagement();

  const navigate = useNavigate();

  const handleSelectFile = (file: File | null) => {
    setCertificate(file);
  };

  const handleSelectAvatar = (file: File | null) => {
    console.log('avatar', file);
    setAvatar(file);
  };

  const handleClickOk = () => {
    // navigate('/doctor/list');
  };

  const onSubmit = (fields: DoctorFormFields) => {
    submit({
      avatar,
      certificate,
      fields,
      validateFileUploadOptions: {
        maxSizeText: t('submit.validation.maxSizeText'),
        requiredText: t('submit.validation.requiredText'),
      },
      validateAvatarUploadOptions: {
        maxSizeText: t('submit.avatarValidation.maxSizeText'),
        requiredText: t('submit.avatarValidation.requiredText'),
        extensionRegExp: /(jpg|jpeg|png)/s,
      },
    });
  };

  !!user && console.log('user', user.username);

  return (
    <>
      <FormProvider {...methods}>
        <Typography
          sx={{
            fontSize: '1.2rem',
            fontFamily: 'AntennaCondBeta',
            fontWeight: 'bold',
            color: (theme) => theme.palette.info.dark,
            marginRight: (theme) => theme.spacing(0.25),
            marginBottom: (theme) => theme.spacing(1),
            paddingTop: (theme) => theme.spacing(4.5),
          }}
        >
          {t('title')}
        </Typography>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
          <Box sx={{ textAlign: 'center' }}>
            {!!user && (
              <AvatarUploadInput
                initialMedia={{
                  url: user.userInfo.profileImage?.url || null,
                }}
                onSelectFile={handleSelectAvatar}
              />
            )}
            {!!user && <InputLabel label={user.username} />}
          </Box>
          <Box>
            <NameInput
              label={t('name.label')}
              placeholders={{
                name: t('name.placeholder.firstName'),
                firstLastName: t('name.placeholder.firstLastName'),
                secondLastName: t('name.placeholder.secondLastName'),
              }}
            />

            <Box
              sx={{
                pt: (theme) => theme.spacing(2.5),
                pb: (theme) => theme.spacing(2.5),
                display: 'grid',
                alignItems: 'end',
                gridTemplateColumns: 'repeat(3,1fr)',
                columnGap: (theme) => theme.spacing(2),
              }}
            >
              <GenderInput
                label={t('gender.label')}
                placeholder={t('gender.placeholder')}
              />
              {/* <MaritalStatusInput
              label={t('maritalStatus.label')}
              placeholder={t('maritalStatus.placeholder')}
            /> */}
              <DateInput
                label={t('birthday.label')}
                placeholder={t('birthday.placeholder')}
              />
              <PhoneInput
                name='cellPhone'
                label={t('cellPhone.label')}
                placeholder={t('cellPhone.placeholder')}
              />
            </Box>
            {/* <Box
            sx={{
              pb: (theme) => theme.spacing(2.5),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(3,1fr)',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <BloodTypeInput
              label={t('bloodType.label')}
              placeholder={t('bloodType.placeholder')}
            /> 
            <EmailInput
              label={t('email.label')}
              placeholder={t('email.placeholder')}
              disabled={!!user}
            />
            <PhoneInput
              name='cellPhone'
              label={t('cellPhone.label')}
              placeholder={t('cellPhone.placeholder')}
            />
          </Box> */}
            {/* <AddressInput
            label={t('address.label')}
            placeholders={{
              street: t('address.placeholders.street'),
              extNumber: t('address.placeholders.extNumber'),
              intNumber: t('address.placeholders.intNumber'),
              cp: t('address.placeholders.cp'),
              state: t('address.placeholders.state'),
              delegation: t('address.placeholders.delegation'),
              colony: t('address.placeholders.colony'),
              extraInfo: t('address.placeholders.extraInfo'),
            }}
            // {...(!!user
            //   ? {
            //       initialSets: {
            //         cp: user.userInfo.address.cp,
            //         colony: user.userInfo.address.colony,
            //       },
            //     }
            //   : {})}
          /> */}
            <Box
              sx={{
                pt: (theme) => theme.spacing(1),
                pb: (theme) => theme.spacing(1),
                display: 'grid',
                alignItems: 'end',
                gridTemplateColumns: '2fr 2fr 3fr',
                columnGap: (theme) => theme.spacing(2),
              }}
            >
              <ProfessionalLicenseInput
                label={t('professionalLicense.label')}
                placeholder={t('professionalLicense.placeholder')}
              />
              <SpecialtyInput
                label={t('speciality.label')}
                placeholder={t('speciality.placeholder')}
                isOpenedField={false}
              />
              <UploadButtonInput
                label={t('file.label')}
                placeholder={t('file.placeholder')}
                buttonText={t('file.buttonText')}
                onSelectFile={handleSelectFile}
              />
            </Box>
            {!!user && (
              <Grid
                container
                sx={{
                  paddingTop: (theme) => theme.spacing(2),
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    // borderBottom: '1px solid #00010020',
                    padding: '6px',
                  }}
                >
                  <InputLabel label={`${t('address.placeholders.street')}:`} />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.street}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel
                    label={`${t('address.placeholders.extNumber')}:`}
                  />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.extNumber}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel
                    label={`${t('address.placeholders.intNumber')}:`}
                  />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                      minHeight: '2.3rem',
                    }}
                  >
                    {user.userInfo.address.intNumber}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel label={`${t('address.placeholders.cp')}:`} />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.cp}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel label={`${t('address.placeholders.state')}:`} />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.state}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    label={`${t('address.placeholders.delegation')}:`}
                  />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.delegation}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel label={`${t('address.placeholders.colony')}:`} />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      textAlign: 'left',
                      borderBottom: '1px solid #00000010',
                      padding: '6px',
                      width: '90%',
                    }}
                  >
                    {user.userInfo.address.colony}
                  </Typography>
                </Grid>
                {/*   <>
                  <InputLabel label={t('address.label')} />
                  <Typography
                    variant='body1'
                    color='GrayText'
                    sx={{
                      fontSize: '1rem',
                      padding: (theme) => `${theme.spacing(0.5)} 0 0 0`,
                      textAlign: 'left',
                    }}
                  >
                    {`${user.userInfo.address.street} ${
                      user.userInfo.address.extNumber
                    }${
                      !!user.userInfo.address.intNumber
                        ? ', ' + user.userInfo.address.intNumber
                        : ''
                    }, ${user.userInfo.address.delegation} Col. ${
                      user.userInfo.address.colony
                    }, ${user.userInfo.address.state}, CP ${
                      user.userInfo.address.cp
                    }`}
                  </Typography>
                </>*/}
              </Grid>
            )}
          </Box>
          <Box sx={{ gridColumn: '1/3' }}>
            <Box
              sx={{
                m: (theme) => `${theme.spacing(5)} auto`,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button buttonProps={{ type: 'submit' }} gray>
                {t('submit.label')}
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('error.title')} message={error} />}
      {!!processedUsername && (
        <Modal
          title={t('success.title')}
          message={t('success.message')}
          onClickOk={handleClickOk}
        />
      )}
    </>
  );
};
