import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PathologicalPersonalHistory } from 'telemed-core';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';

export interface PathologicalRecordFormFields {
  geneticDiseases: string | null;
  infectiousDiseasesOfChildhood: string | null;
  previousSurgeries: string | null;

  allergiesStatus: string | null;
  allergiesMedicine: string | null;
  allergiesDate: string | null;
  allergiesFrequency: string | null;
  allergiesYears: string | null;
  allergiesMonths: string | null;

  heartDiseaseStatus: string | null;
  heartDiseaseMedicine: string | null;
  heartDiseaseDate: string | null;
  heartDiseaseFrequency: string | null;
  heartDiseaseYears: string | null;
  heartDiseaseMonths: string | null;

  mellitusDiabetesStatus: string | null;
  mellitusDiabetesMedicine: string | null;
  mellitusDiabetesDate: string | null;
  mellitusDiabetesFrequency: string | null;
  mellitusDiabetesYears: string | null;
  mellitusDiabetesMonths: string | null;

  endocrinologicalStatus: string | null;
  endocrinologicalMedicine: string | null;
  endocrinologicalDate: string | null;
  endocrinologicalFrequency: string | null;
  endocrinologicalYears: string | null;
  endocrinologicalMonths: string | null;

  hematologicStatus: string | null;
  hematologicMedicine: string | null;
  hematologicDate: string | null;
  hematologicFrequency: string | null;
  hematologicYears: string | null;
  hematologicMonths: string | null;

  arterialHypertensionStatus: string | null;
  arterialHypertensionMedicine: string | null;
  arterialHypertensionDate: string | null;
  arterialHypertensionFrequency: string | null;
  arterialHypertensionYears: string | null;
  arterialHypertensionMonths: string | null;

  nephropathyStatus: string | null;
  nephropathyMedicine: string | null;
  nephropathyDate: string | null;
  nephropathyFrequency: string | null;
  nephropathyYears: string | null;
  nephropathyMonths: string | null;

  pneumologicalStatus: string | null;
  pneumologicalMedicine: string | null;
  pneumologicalDate: string | null;
  pneumologicalFrequency: string | null;
  pneumologicalYears: string | null;
  pneumologicalMonths: string | null;

  neurologicalStatus: string | null;
  neurologicalMedicine: string | null;
  neurologicalDate: string | null;
  neurologicalFrequency: string | null;
  neurologicalYears: string | null;
  neurologicalMonths: string | null;

  otherStatus: string | null;
  otherMedicine: string | null;
  otherDate: string | null;
  otherFrequency: string | null;
  otherYears: string | null;
  otherMonths: string | null;
}

const keyPrefix = 'general';

const getDefaultValues = (pathos: PathologicalPersonalHistory | {}) => {
  console.log('pathos', pathos);
  return {
    geneticDiseases:
      'geneticDiseases' in pathos ? pathos.geneticDiseases : null,
    infectiousDiseasesOfChildhood:
      'infectiousDiseasesOfChildhood' in pathos
        ? pathos.infectiousDiseasesOfChildhood
        : null,
    previousSurgeries:
      'previousSurgeries' in pathos ? pathos.previousSurgeries : null,
    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'allergies' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.allergies
      ? {
          allergiesStatus:
            'status' in pathos.chronicDiseases.allergies &&
            pathos.chronicDiseases.allergies.status !== undefined
              ? pathos.chronicDiseases.allergies.status
                ? 'true'
                : 'false'
              : undefined,
          allergiesMedicine:
            'medicine' in pathos.chronicDiseases.allergies
              ? pathos.chronicDiseases.allergies.medicine
              : undefined,
          allergiesDate:
            'startDate' in pathos.chronicDiseases.allergies
              ? pathos.chronicDiseases.allergies.startDate
              : undefined,
          allergiesFrequency:
            'frequency' in pathos.chronicDiseases?.allergies
              ? pathos.chronicDiseases.allergies.frequency
              : undefined,
          allergiesYears:
            'years' in pathos.chronicDiseases?.allergies &&
            pathos.chronicDiseases.allergies.years !== undefined
              ? pathos.chronicDiseases.allergies.years.toString()
              : undefined,
          allergiesMonths:
            'months' in pathos.chronicDiseases?.allergies &&
            pathos.chronicDiseases.allergies.months !== undefined
              ? pathos.chronicDiseases.allergies.months.toString()
              : undefined,
        }
      : {}),
    //FALTA TODO LO DEMAS PARA YEARS & MONTHS
    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'heartDisease' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.heartDisease
      ? {
          heartDiseaseStatus:
            'status' in pathos.chronicDiseases.heartDisease &&
            pathos.chronicDiseases.heartDisease.status !== undefined
              ? pathos.chronicDiseases.heartDisease.status
                ? 'true'
                : 'false'
              : undefined,
          heartDiseaseMedicine:
            'medicine' in pathos.chronicDiseases.heartDisease
              ? pathos.chronicDiseases.heartDisease.medicine
              : undefined,
          heartDiseaseDate:
            'startDate' in pathos.chronicDiseases.heartDisease
              ? pathos.chronicDiseases.heartDisease.startDate
              : undefined,
          heartDiseaseFrequency:
            'frequency' in pathos.chronicDiseases?.heartDisease
              ? pathos.chronicDiseases.heartDisease.frequency
              : undefined,
          heartDiseaseYears:
            'years' in pathos.chronicDiseases?.heartDisease &&
            pathos.chronicDiseases.heartDisease.years !== undefined
              ? pathos.chronicDiseases.heartDisease.years.toString()
              : undefined,
          heartDiseaseMonths:
            'months' in pathos.chronicDiseases?.heartDisease &&
            pathos.chronicDiseases.heartDisease.months !== undefined
              ? pathos.chronicDiseases.heartDisease.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'mellitusDiabetes' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.mellitusDiabetes
      ? {
          mellitusDiabetesStatus:
            'status' in pathos.chronicDiseases.mellitusDiabetes &&
            pathos.chronicDiseases.mellitusDiabetes.status !== undefined
              ? pathos.chronicDiseases.mellitusDiabetes.status
                ? 'true'
                : 'false'
              : undefined,
          mellitusDiabetesMedicine:
            'medicine' in pathos.chronicDiseases.mellitusDiabetes
              ? pathos.chronicDiseases.mellitusDiabetes.medicine
              : undefined,
          mellitusDiabetesDate:
            'startDate' in pathos.chronicDiseases.mellitusDiabetes
              ? pathos.chronicDiseases.mellitusDiabetes.startDate
              : undefined,
          mellitusDiabetesFrequency:
            'frequency' in pathos.chronicDiseases?.mellitusDiabetes
              ? pathos.chronicDiseases.mellitusDiabetes.frequency
              : undefined,
          mellitusDiabetesYears:
            'years' in pathos.chronicDiseases?.mellitusDiabetes &&
            pathos.chronicDiseases.mellitusDiabetes.years !== undefined
              ? pathos.chronicDiseases.mellitusDiabetes.years.toString()
              : undefined,
          mellitusDiabetesMonths:
            'months' in pathos.chronicDiseases?.mellitusDiabetes &&
            pathos.chronicDiseases.mellitusDiabetes.months !== undefined
              ? pathos.chronicDiseases.mellitusDiabetes.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'endocrinological' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.endocrinological
      ? {
          endocrinologicalStatus:
            'status' in pathos.chronicDiseases.endocrinological &&
            pathos.chronicDiseases.endocrinological.status !== undefined
              ? pathos.chronicDiseases.endocrinological.status
                ? 'true'
                : 'false'
              : undefined,
          endocrinologicalMedicine:
            'medicine' in pathos.chronicDiseases.endocrinological
              ? pathos.chronicDiseases.endocrinological.medicine
              : undefined,
          endocrinologicalDate:
            'startDate' in pathos.chronicDiseases.endocrinological
              ? pathos.chronicDiseases.endocrinological.startDate
              : undefined,
          endocrinologicalFrequency:
            'frequency' in pathos.chronicDiseases?.endocrinological
              ? pathos.chronicDiseases.endocrinological.frequency
              : undefined,
          endocrinologicalYears:
            'years' in pathos.chronicDiseases?.endocrinological &&
            pathos.chronicDiseases.endocrinological.years !== undefined
              ? pathos.chronicDiseases.endocrinological.years.toString()
              : undefined,
          endocrinologicalMonths:
            'months' in pathos.chronicDiseases?.endocrinological &&
            pathos.chronicDiseases.endocrinological.months !== undefined
              ? pathos.chronicDiseases.endocrinological.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'hematologic' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.hematologic
      ? {
          hematologicStatus:
            'status' in pathos.chronicDiseases.hematologic &&
            pathos.chronicDiseases.hematologic.status !== undefined
              ? pathos.chronicDiseases.hematologic.status
                ? 'true'
                : 'false'
              : undefined,
          hematologicMedicine:
            'medicine' in pathos.chronicDiseases.hematologic
              ? pathos.chronicDiseases.hematologic.medicine
              : undefined,
          hematologicDate:
            'startDate' in pathos.chronicDiseases.hematologic
              ? pathos.chronicDiseases.hematologic.startDate
              : undefined,
          hematologicFrequency:
            'frequency' in pathos.chronicDiseases?.hematologic
              ? pathos.chronicDiseases.hematologic.frequency
              : undefined,
          hematologicYears:
            'years' in pathos.chronicDiseases?.hematologic &&
            pathos.chronicDiseases.hematologic.years !== undefined
              ? pathos.chronicDiseases.hematologic.years.toString()
              : undefined,
          hematologicMonths:
            'months' in pathos.chronicDiseases?.hematologic &&
            pathos.chronicDiseases.hematologic.months !== undefined
              ? pathos.chronicDiseases.hematologic.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'arterialHypertension' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.arterialHypertension
      ? {
          arterialHypertensionStatus:
            'status' in pathos.chronicDiseases.arterialHypertension &&
            pathos.chronicDiseases.arterialHypertension.status !== undefined
              ? pathos.chronicDiseases.arterialHypertension.status
                ? 'true'
                : 'false'
              : undefined,
          arterialHypertensionMedicine:
            'medicine' in pathos.chronicDiseases.arterialHypertension
              ? pathos.chronicDiseases.arterialHypertension.medicine
              : undefined,
          arterialHypertensionDate:
            'startDate' in pathos.chronicDiseases.arterialHypertension
              ? pathos.chronicDiseases.arterialHypertension.startDate
              : undefined,
          arterialHypertensionFrequency:
            'frequency' in pathos.chronicDiseases?.arterialHypertension
              ? pathos.chronicDiseases.arterialHypertension.frequency
              : undefined,
          arterialHypertensionYears:
            'years' in pathos.chronicDiseases?.arterialHypertension &&
            pathos.chronicDiseases.arterialHypertension.years !== undefined
              ? pathos.chronicDiseases.arterialHypertension.years.toString()
              : undefined,
          arterialHypertensionMonths:
            'months' in pathos.chronicDiseases?.arterialHypertension &&
            pathos.chronicDiseases.arterialHypertension.months !== undefined
              ? pathos.chronicDiseases.arterialHypertension.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'nephropathy' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.nephropathy
      ? {
          nephropathyStatus:
            'status' in pathos.chronicDiseases.nephropathy &&
            pathos.chronicDiseases.nephropathy.status !== undefined
              ? pathos.chronicDiseases.nephropathy.status
                ? 'true'
                : 'false'
              : undefined,
          nephropathyMedicine:
            'medicine' in pathos.chronicDiseases.nephropathy
              ? pathos.chronicDiseases.nephropathy.medicine
              : undefined,
          nephropathyDate:
            'startDate' in pathos.chronicDiseases.nephropathy
              ? pathos.chronicDiseases.nephropathy.startDate
              : undefined,
          nephropathyFrequency:
            'frequency' in pathos.chronicDiseases?.nephropathy
              ? pathos.chronicDiseases.nephropathy.frequency
              : undefined,
          nephropathyYears:
            'years' in pathos.chronicDiseases?.nephropathy &&
            pathos.chronicDiseases.nephropathy.years !== undefined
              ? pathos.chronicDiseases.nephropathy.years.toString()
              : undefined,
          nephropathyMonths:
            'months' in pathos.chronicDiseases?.nephropathy &&
            pathos.chronicDiseases.nephropathy.months !== undefined
              ? pathos.chronicDiseases.nephropathy.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'pneumological' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.pneumological
      ? {
          pneumologicalStatus:
            'status' in pathos.chronicDiseases.pneumological &&
            pathos.chronicDiseases.pneumological.status !== undefined
              ? pathos.chronicDiseases.pneumological.status
                ? 'true'
                : 'false'
              : undefined,
          pneumologicalMedicine:
            'medicine' in pathos.chronicDiseases.pneumological
              ? pathos.chronicDiseases.pneumological.medicine
              : undefined,
          pneumologicalDate:
            'startDate' in pathos.chronicDiseases.pneumological
              ? pathos.chronicDiseases.pneumological.startDate
              : undefined,
          pneumologicalFrequency:
            'frequency' in pathos.chronicDiseases?.pneumological
              ? pathos.chronicDiseases.pneumological.frequency
              : undefined,
          pneumologicalYears:
            'years' in pathos.chronicDiseases?.pneumological &&
            pathos.chronicDiseases.pneumological.years !== undefined
              ? pathos.chronicDiseases.pneumological.years.toString()
              : undefined,
          pneumologicalMonths:
            'months' in pathos.chronicDiseases?.pneumological &&
            pathos.chronicDiseases.pneumological.months !== undefined
              ? pathos.chronicDiseases.pneumological.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'neurological' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.neurological
      ? {
          neurologicalStatus:
            'status' in pathos.chronicDiseases.neurological &&
            pathos.chronicDiseases.neurological.status !== undefined
              ? pathos.chronicDiseases.neurological.status
                ? 'true'
                : 'false'
              : undefined,
          neurologicalMedicine:
            'medicine' in pathos.chronicDiseases.neurological
              ? pathos.chronicDiseases.neurological.medicine
              : undefined,
          neurologicalDate:
            'startDate' in pathos.chronicDiseases.neurological
              ? pathos.chronicDiseases.neurological.startDate
              : undefined,
          neurologicalFrequency:
            'frequency' in pathos.chronicDiseases?.neurological
              ? pathos.chronicDiseases.neurological.frequency
              : undefined,
          neurologicalYears:
            'years' in pathos.chronicDiseases?.neurological &&
            pathos.chronicDiseases.neurological.years !== undefined
              ? pathos.chronicDiseases.neurological.years.toString()
              : undefined,
          neurologicalMonths:
            'months' in pathos.chronicDiseases?.neurological &&
            pathos.chronicDiseases.neurological.months !== undefined
              ? pathos.chronicDiseases.neurological.months.toString()
              : undefined,
        }
      : {}),

    ...(!!pathos &&
    'chronicDiseases' in pathos &&
    'other' in pathos.chronicDiseases &&
    !!pathos.chronicDiseases.other
      ? {
          otherStatus:
            'status' in pathos.chronicDiseases.other &&
            pathos.chronicDiseases.other.status !== undefined
              ? pathos.chronicDiseases.other.status
                ? 'true'
                : 'false'
              : undefined,
          otherMedicine:
            'medicine' in pathos.chronicDiseases.other
              ? pathos.chronicDiseases.other.medicine
              : undefined,
          otherDate:
            'startDate' in pathos.chronicDiseases.other
              ? pathos.chronicDiseases.other.startDate
              : undefined,
          otherFrequency:
            'frequency' in pathos.chronicDiseases?.other
              ? pathos.chronicDiseases.other.frequency
              : undefined,
          otherYears:
            'years' in pathos.chronicDiseases?.other &&
            pathos.chronicDiseases.other.years !== undefined
              ? pathos.chronicDiseases.other.years.toString()
              : undefined,
          otherMonths:
            'months' in pathos.chronicDiseases?.other &&
            pathos.chronicDiseases.other.months !== undefined
              ? pathos.chronicDiseases.other.months.toString()
              : undefined,
        }
      : {}),
  };
};

export const usePathologicalRecordFormManagement = (callback: () => void) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getPathological, setPathological } = useMedicalRecord();
  const pathos = getPathological();

  const schema = yup.object().shape({
    geneticDiseases: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    infectiousDiseasesOfChildhood: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    previousSurgeries: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    allergiesStatus: yup.string().required(t('required')),
    allergiesMedicine: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // allergiesDate: yup.string().when('allergiesStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    allergiesFrequency: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    allergiesYears: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    allergiesMonths: yup.string().when('allergiesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    heartDiseaseStatus: yup.string().required(t('required')),
    heartDiseaseMedicine: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // heartDiseaseDate: yup.string().when('heartDiseaseStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    heartDiseaseFrequency: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    heartDiseaseYears: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    heartDiseaseMonths: yup.string().when('heartDiseaseStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    mellitusDiabetesStatus: yup.string().required(t('required')),
    mellitusDiabetesMedicine: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // mellitusDiabetesDate: yup.string().when('mellitusDiabetesStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    mellitusDiabetesFrequency: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    mellitusDiabetesYears: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    mellitusDiabetesMonths: yup.string().when('mellitusDiabetesStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    endocrinologicalStatus: yup.string().required(t('required')),
    endocrinologicalMedicine: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // endocrinologicalDate: yup.string().when('endocrinologicalStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    endocrinologicalFrequency: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    endocrinologicalYears: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    endocrinologicalMonths: yup.string().when('endocrinologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    hematologicStatus: yup.string().required(t('required')),
    hematologicMedicine: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // hematologicDate: yup.string().when('hematologicStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    hematologicFrequency: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    hematologicYears: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    hematologicMonths: yup.string().when('hematologicStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    arterialHypertensionStatus: yup.string().required(t('required')),
    arterialHypertensionMedicine: yup
      .string()
      .when('arterialHypertensionStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    // arterialHypertensionDate: yup.string().when('arterialHypertensionStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    arterialHypertensionFrequency: yup
      .string()
      .when('arterialHypertensionStatus', {
        is: (val: string | null) => val === 'true',
        then: yup.string().required(t('required')),
        otherwise: yup.string().notRequired(),
      }),
    arterialHypertensionYears: yup.string().when('arterialHypertensionStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    arterialHypertensionMonths: yup
      .string()
      .when('arterialHypertensionStatus', {
        is: (val: string | null) => val === 'true',
        then: yup
          .string()
          .required(t('required'))
          .matches(/^([0-9]|10|11)$/, t('maxMonth')),
        otherwise: yup.string().notRequired(),
      }),

    nephropathyStatus: yup.string().required(t('required')),
    nephropathyMedicine: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // nephropathyDate: yup.string().when('nephropathyStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    nephropathyFrequency: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    nephropathyYears: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    nephropathyMonths: yup.string().when('nephropathyStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    pneumologicalStatus: yup.string().required(t('required')),
    pneumologicalMedicine: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // pneumologicalDate: yup.string().when('pneumologicalStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    pneumologicalFrequency: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    pneumologicalYears: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    pneumologicalMonths: yup.string().when('pneumologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    neurologicalStatus: yup.string().required(t('required')),
    neurologicalMedicine: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // neurologicalDate: yup.string().when('neurologicalStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    neurologicalFrequency: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    neurologicalYears: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    neurologicalMonths: yup.string().when('neurologicalStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),

    otherStatus: yup.string().required(t('required')),
    otherMedicine: yup.string().when('otherStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // otherDate: yup.string().when('otherStatus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    otherFrequency: yup.string().when('otherStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    otherYears: yup.string().when('otherStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    otherMonths: yup.string().when('otherStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .string()
        .required(t('required'))
        .matches(/^([0-9]|10|11)$/, t('maxMonth')),
      otherwise: yup.string().notRequired(),
    }),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<PathologicalRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(pathos!),
  });

  useEffect(() => {
    methods.reset(getDefaultValues(pathos!));
  }, [pathos]);

  const submit: SubmitHandler<PathologicalRecordFormFields> = async (data) => {
    await setPathological(data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setPathological(data, true);
  };

  return {
    defaultValues: getDefaultValues(pathos!),
    methods,
    preSave,
    submit,
  };
};
