import React, { useEffect } from 'react';
import { createContext, useState } from 'react';
import {
  Consultation,
  User,
  useTwilioAccessLazy,
  useTwilioRecordingLazy,
} from 'telemed-core';
import {
  connect,
  createLocalVideoTrack,
  RemoteParticipant,
  Room,
} from 'twilio-video';

type VideoCallContextProps = {
  callEnded: boolean | null;
  consultation: Consultation | null;
  error: string | null;
  isInsideRoom: boolean;
  loading: boolean;
  participants: RemoteParticipant[];
  patient: User | null;
  room: Room | null;
  enterRoom: (consultation: Consultation, patient: User) => void;
  endVideoCall: () => void;
  exitRoom: () => void;
  recordVideo: (flag: boolean) => Promise<boolean | null>;
  startVideoCall: () => void;
  toggleAudio: () => void;
  toggleVideo: () => void;
  verifyCamera: () => Promise<boolean>;
};

const defaultProps = {
  callEnded: null,
  consultation: null,
  error: null,
  isInsideRoom: false,
  loading: false,
  participants: [],
  patient: null,
  room: null,
  enterRoom: (consultation: Consultation, patient: User) => {},
  endVideoCall: () => {},
  exitRoom: () => {},
  recordVideo: async (flag: boolean) => null,
  startVideoCall: () => {},
  toggleAudio: () => {},
  toggleVideo: () => {},
  verifyCamera: async () => false,
};

export const VideoCallContext =
  createContext<VideoCallContextProps>(defaultProps);

export const VideoCallProvider: React.FC = ({ children }) => {
  const [callEnded, setCallEnded] = useState<boolean | null>(null);
  const [consultation, setConsultation] = useState<Consultation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isInsideRoom, setIsInsideRoom] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [patient, setPatient] = useState<User | null>(null);
  const [room, setRoom] = useState<Room | null>(null);

  const { error: twErr, get: getToken } = useTwilioAccessLazy();
  const { error: recErr, record } = useTwilioRecordingLazy();

  const participantConnected = (participant: RemoteParticipant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
  };
  const participantDisconnected = (participant: RemoteParticipant) => {
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );
  };

  const twilioConnect = async (token: string) => {
    const room = await connect(token);
    console.log('Connected to Room "%s"', room.name);
    setRoom(room);
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.participants.forEach(participantConnected);
  };

  const endCall = () => {
    setRoom((currentRoom) => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        currentRoom.localParticipant.tracks.forEach((trackPublication: any) => {
          trackPublication.track.stop();
        });
        currentRoom.disconnect();
        return null;
      } else {
        return currentRoom;
      }
    });
  };

  useEffect(() => {
    return () => {
      endCall();
    };
  }, []);

  useEffect(() => {
    !!twErr && setError(twErr);
  }, [twErr]);

  const endVideoCall = () => {
    setLoading(true);
    endCall();
    setRoom(null);
    setLoading(false);
    setCallEnded(true);
  };

  const enterRoom = (consultation: Consultation, patient: User) => {
    setConsultation(consultation);
    setPatient(patient);
    setIsInsideRoom(true);
  };

  const exitRoom = () => {
    setConsultation(null);
    setPatient(null);
    setIsInsideRoom(false);
  };

  const startVideoCall = async () => {
    console.log('startVideoCall...');
    setLoading(true);
    setCallEnded(null);

    try {
      const token = await getToken(consultation?.id!);
      await twilioConnect(token!);
      setCallEnded(false);
    } catch (err: any) {
      console.error('Error:', err);
      setError(err.message);
    }

    setLoading(false);
  };

  const toggleAudio = () => {
    room?.localParticipant.audioTracks.forEach(({ track }) => {
      track.isEnabled ? track.disable() : track.enable();
    });
  };

  const toggleVideo = () => {
    room?.localParticipant.videoTracks.forEach(({ track }) => {
      track.isEnabled ? track.disable() : track.enable();
    });
  };

  const recordVideo = async (flag: boolean) =>
    await record(consultation?.id!, flag);

  const verifyCamera = async () => {
    setLoading(true);
    try {
      const videoTrack = await createLocalVideoTrack();
      let isCameraActive = !!videoTrack;
      console.log(videoTrack);
      videoTrack.stop();
      setLoading(false);
      return isCameraActive;
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      return false;
    }
  };

  return (
    <VideoCallContext.Provider
      value={{
        callEnded,
        consultation,
        error,
        isInsideRoom,
        loading,
        participants,
        patient,
        room,
        enterRoom,
        endVideoCall,
        exitRoom,
        recordVideo,
        startVideoCall,
        toggleAudio,
        toggleVideo,
        verifyCamera,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};
