import { useEffect, useRef, useState } from 'react';
import * as Video from 'twilio-video';
import Box from '@material-ui/core/Box';
import { CSSProperties } from '@material-ui/styles';

type Props = {
  participant: Video.Participant;
  style: CSSProperties;
};

export const VideoCallParticipant: React.FC<Props> = ({
  participant,
  style,
}): JSX.Element => {
  const [opacity, setOpacity] = useState<number>(1);
  const [videoTracks, setVideoTracks] = useState<Video.VideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<Video.AudioTrack[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const trackSubscribed = (track: Video.VideoTrack | Video.AudioTrack) => {
    console.log('trackSubscribed');
    if (track.kind === 'video') {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
    } else {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track: Video.VideoTrack | Video.AudioTrack) => {
    console.log('trackUnsubscribed');
    if (track.kind === 'video') {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
    } else {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  const trackDisabled = (track: Video.VideoTrack | Video.AudioTrack) => {
    track.kind === 'video' && setOpacity(0);
  };
  const trackEnabled = (track: Video.VideoTrack | Video.AudioTrack) => {
    track.kind === 'video' && setOpacity(1);
  };

  const trackPubsToTracks = (
    trackMap:
      | any[]
      | Map<string, Video.VideoTrackPublication>
      | Map<string, Video.AudioTrackPublication>
  ) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks!));
    setAudioTracks(trackPubsToTracks(participant.audioTracks!));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.on('trackEnabled', trackEnabled);
    participant.on('trackDisabled', trackDisabled);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current!);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current!);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      <video ref={videoRef} autoPlay={true} style={{ ...style, opacity }} />
      <audio ref={audioRef} autoPlay={true} />
    </>
  );
};
