import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { Layout } from 'telemed-core';
import { UpdatePasswordForm } from '../forms';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'grid',
    color: theme.palette.grey[300],
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyItems: 'center',
    rowGap: theme.spacing(2),
    '& button': {
      marginTop: theme.spacing(3),
    },
    '& .new-password': {
      width: '100%',
      display: 'grid',
      rowGap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
}));

export const UpdatePasswordContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const classes = useStyles();

    return (
      <Layout title='Contraseña'>
        <Container maxWidth='xs'>
          <UpdatePasswordForm className={classes.form} />
        </Container>
      </Layout>
    );
  };
