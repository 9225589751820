import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  EquipmentInfo,
  ExerciseInfo,
  MedicineInfo,
  StudyInfo,
  Treatment,
  useTreatmentLazy,
} from 'telemed-core';
import { useEffect, useState } from 'react';

const keyPrefix = 'general';

export interface TreatmentFormFields {
  name: string | null;
  indications: string | null;
}

const defaultValues = {
  name: null,
  indications: null,
};

export const useTreatmentFormManagement = (patientId: number) => {
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation('translation', { keyPrefix });
  const { error: saveError, loading, successSubmit, axn } = useTreatmentLazy();

  useEffect(() => {
    console.log('useTreatmentFormManagement saveError', saveError);
    !!saveError && setError(saveError);
  }, [saveError]);

  const schema = yup.object().shape({
    name: yup.string().typeError(t('required')).required(t('required')),
    indications: yup.string().typeError(t('required')).required(t('required')),
  });

  const methods = useForm<TreatmentFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  type SubmitProps = {
    fields: TreatmentFormFields;
    exerciseList: ExerciseInfo[];
    equipList: EquipmentInfo[];
    medicineList: MedicineInfo[];
    studyList: StudyInfo[];
  };

  const submit: SubmitHandler<SubmitProps> = async ({
    fields: { name, indications },
    exerciseList,
    medicineList,
    studyList,
    equipList,
  }) => {
    console.log('useTreatmentFormManagement SUBMIT');

    setError(null);
    if (exerciseList.length === 0 && medicineList.length === 0) {
      setError(t('treatment.atListOne'));
      return;
    }

    const treatment = {
      patientId,
      name,
      indications,
      treatmentsInfo: {
        excerciseInfo: exerciseList,
        medicinesInfo: medicineList,
        studiesInfo: studyList,
        equip: equipList,
      },
    } as Treatment;

    console.log('treatment SUBMIT', treatment);

    await axn(treatment);
    // methods.reset();
  };

  return { error, loading, methods, successSubmit, submit };
};
