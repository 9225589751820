import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
  AddButton,
  Button,
  Consultation,
  DateTimeInput,
  Loading,
  Modal,
  ReminderFrequencyInput,
} from 'telemed-core';

import { useAlarms, useEditConsultationFormManagement } from '../hooks';

type Props = {
  originalConsultation: Consultation;
  onEdited: () => void;
};

const keyPrefix = 'forms.EditConsultationForm';

export const EditConsultationForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, originalConsultation, onEdited }) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { areNotificationDenied } = useAlarms();

  const [showSecondAlarm, setShowSecondAlarm] = useState(false);
  const [showNotificationDenied, setShowNotificationDenied] = useState(false);
  const { edited, error, loading, methods, submit } =
    useEditConsultationFormManagement(originalConsultation);

  const handleClickAdd = () => {
    setShowSecondAlarm(true);
  };

  const handleClickOk = () => {
    areNotificationDenied ? setShowNotificationDenied(true) : onEdited();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <DateTimeInput />
        <ReminderFrequencyInput
          name='reminderOne'
          label='Primer Alarma'
          maxMenuHeight={280}
        />
        {showSecondAlarm ? (
          <ReminderFrequencyInput
            name='reminderTwo'
            label='Segunda Alarma'
            maxMenuHeight={200}
          />
        ) : (
          <span>
            <AddButton label='Agregar otra Alarma' onClick={handleClickAdd} />
          </span>
        )}
        {/* <Button type='submit' className='axnButton' gray> */}
        <Button buttonProps={{ type: 'submit', className: 'axnButton' }} gray>
          guardar cambios
        </Button>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
      {edited && (
        <Modal
          title={t('success.title')}
          message={t('success.message')}
          onClickOk={handleClickOk}
        />
      )}
      {showNotificationDenied && (
        <Modal
          title={t('denied.title')}
          message={t('denied.message')}
          onClickOk={onEdited}
        />
      )}
    </FormProvider>
  );
};
