import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import Stack from '@material-ui/core/Stack';
import moment from 'moment';
import 'moment/locale/es-mx';

import Typography from '@material-ui/core/Typography';

import { MedicineInfo, Separator, useDrugsLazy } from 'telemed-core';
import { useEffect, useState } from 'react';

type Props = { list: MedicineInfo[] };

const keyPrefix = 'components.MedicineList';

export const MedicineList: React.FC<Props> = ({ list }): JSX.Element => {
  if (!list || list.length === 0) return <EmptyList />;

  return (
    <Box>
      {list.map((medicineInfo, idx) => {
        return (
          <Box key={idx}>
            <ListItem component='li'>
              <ListItemContent {...medicineInfo} />
            </ListItem>
            <Separator />
          </Box>
        );
      })}
    </Box>
  );
};

const ListItemContent: React.FC<MedicineInfo> = ({
  duration,
  durationType,
  each,
  indications,
  name,
  presentation,
  status,
  startDate,
  unique_id,
}): JSX.Element => {
  const [url, setUrl] = useState<string>('');
  const { get } = useDrugsLazy();
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItemContent`,
  });

  const getDrugImg = async () => {
    const drugList = await get(name);
    if (!!drugList && drugList.length > 0) {
      const drug = drugList.find((drug) => drug.unique_id === unique_id);
      !!drug && setUrl(drug.image_url);
    }
  };

  useEffect(() => {
    !!name && getDrugImg();
  }, [name]);

  return (
    <Box
      width='100%'
      display='grid'
      gridTemplateColumns='350px 1fr'
      paddingBottom={2}
      columnGap={2}
    >
      <CardMedia
        component='img'
        image={url}
        sx={{
          maxWidth: 350,
          maxHeight: 350,
          width: 'auto',
          height: 'auto',
        }}
      />
      <Box>
        <Stack direction='row' justifyContent='space-between'>
          <Box>
            <Typography variant='subtitle1' color='grey.300'>
              {name}
            </Typography>
            <Typography variant='body1'>{presentation}</Typography>
          </Box>
          <Chip
            label={t(`status.${status}.label`)}
            color={t(`status.${status}.color`)}
            size='small'
          />
        </Stack>
        <Typography color='primary' fontWeight={700} mt={2}>
          {t(`indications`)}
        </Typography>
        <Typography variant='body1'>{indications}</Typography>

        <DisplayData
          label={t(`startAt`)}
          value={
            !!startDate
              ? moment.utc(startDate).local().format('DD/MM/YYYY - h:mm a')
              : t('withoutStartDate')
          }
        />

        <DisplayData label={t(`each.label`)} value={t(`each.${each}`)} />

        <DisplayData
          label={t(`duration.label`)}
          value={`${duration} ${t(
            `duration.${durationType}.${duration > 1 ? 'plural' : 'singular'}`
          )}`}
        />
      </Box>
    </Box>
  );
};

type DisplayDataProps = {
  label: string;
  value: string;
};

const DisplayData: React.FC<DisplayDataProps> = ({
  label,
  value,
}): JSX.Element => (
  <>
    <Stack direction='row' columnGap={1} alignItems='baseline'>
      <Typography color='primary' fontWeight={700} mt={1}>
        {label}
      </Typography>
      <Typography variant='body1'>{value}</Typography>
    </Stack>
  </>
);

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <Box display='flex' justifyContent='center'>
      <Typography variant='body1' color='GrayText' sx={{ opacity: 0.5 }}>
        {t('message')}
      </Typography>
    </Box>
  );
};
