import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ISelectItem,
  MediaType,
  useEquipmentsLazy,
  EquipmentInfo,
  InventoryStatus,
} from 'telemed-core';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FormProps = {
  equipment: ISelectItem;
  duration: string;
  durationType: ISelectItem;
  indications: string;
};

export const useAddEquipFormManagement = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  const { error, get } = useEquipmentsLazy();
  const [equipmentOptions, setEquipmentOptions] = useState<ISelectItem[]>([]);

  const schema = yup.object().shape({
    equipment: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    duration: yup.string().required(t('required')),
    durationType: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    indications: yup.string().required(t('required')),
  });

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const getOptions = async () => {
    const equipmentList = await get();

    const filteredList = equipmentList.filter(
      ({ inventory: { status } }) => status === InventoryStatus.NOT_ASSIGN
    );

    const options = filteredList.map(
      ({
        inventory: {
          id,
          name,
          description,
          inventoryInfo: {
            media: { url, urlVideoThumbnail, mediaType },
          },
        },
      }) => {
        return {
          value: id!.toString(),
          label: name,
          desc: description,
          image: mediaType === MediaType.IMAGE ? url : urlVideoThumbnail,
        } as ISelectItem;
      }
    );

    setEquipmentOptions(options);
    setLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, [get]);

  const submit: SubmitHandler<FormProps> = ({
    equipment,
    duration,
    durationType,
    indications,
  }): EquipmentInfo => {
    console.log('submit AddEquipForm');

    return {
      id: +equipment.value,
      indications,
      duration: +duration,
      durationType: +durationType.value,
    } as EquipmentInfo;
  };

  return {
    equipmentOptions,
    error,
    loading,
    methods,
    submit,
  };
};
