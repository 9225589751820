import React from 'react';
// import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import { Layout } from 'telemed-core';

import { TermsContainer } from './TermsContainer';

// const keyPrefix = 'containers.TyCContainer';

export const TyCContainer: React.FC<RouteComponentProps> = (): JSX.Element => {
  // const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Layout title=''>
      {/* <Container maxWidth='md' sx={{ marginTop: (theme) => theme.spacing(5) }}> */}
      <TermsContainer showButtons={false} />
      {/* </Container> */}
    </Layout>
  );
};
