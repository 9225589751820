import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';
import {
  ISelectItem,
  RespiratoryApparatus,
  parseViaInputValue,
  parseEachValue,
} from 'telemed-core';

export interface RespiratoryApparatusRecordFormFields {
  asthmaYears: string | null;
  asthmaMonths: string | null;
  asthmaMedicament: string | null;

  emphysemaYears: string | null;
  emphysemaMonths: string | null;
  emphysemaMedicament: string | null;

  respiratoryInfectionsYears: string | null;
  respiratoryInfectionsMonths: string | null;
  respiratoryInfectionsMedicament: string | null;

  chronicBronchitisYears: string | null;
  chronicBronchitisMonths: string | null;
  chronicBronchitisMedicament: string | null;

  typeOfCancer: string | null;
  cancerYears: string | null;
  cancerMonths: string | null;
  cancerMedicament: string | null;

  typeOfSurgery: string | null;
  surgeriesYears: string | null;
  surgeriesMonths: string | null;
  surgeriesMedicament: string | null;

  yearsSmoking: string | null;
  yearsWithoutSmoking: string | null;

  percussionSpan: string | null;
  vibration: string | null;
  IPV: string | null;
  strikerVest: string | null;
  assistedCough: string | null;

  medicinesInfoName: string | null;
  medicinesInfoDose: string | null;
  medicinesInfoVia: ISelectItem | null;
  medicinesInfoEach: ISelectItem | null;
}

const keyPrefix = 'general';

const getDefaultValues = (data: RespiratoryApparatus | {}) => {
  console.log(data);
  return {
    ...('asthma' in data && !!data.asthma
      ? {
          asthmaYears:
            'years' in data.asthma && data.asthma.years !== undefined
              ? data.asthma.years.toString()
              : undefined,
          asthmaMonths:
            'months' in data.asthma && data.asthma.months !== undefined
              ? data.asthma.months.toString()
              : undefined,
          asthmaMedicament:
            'medicament' in data.asthma && !!data.asthma.medicament
              ? data.asthma.medicament
              : undefined,
        }
      : {}),
    ...('emphysema' in data && !!data.emphysema
      ? {
          emphysemaYears:
            'years' in data.emphysema && data.emphysema.years !== undefined
              ? data.emphysema.years.toString()
              : undefined,
          emphysemaMonths:
            'months' in data.emphysema && data.emphysema.months !== undefined
              ? data.emphysema.months.toString()
              : undefined,
          emphysemaMedicament:
            'medicament' in data.emphysema && !!data.emphysema.medicament
              ? data.emphysema.medicament
              : undefined,
        }
      : {}),
    ...('respiratoryInfections' in data && !!data.respiratoryInfections
      ? {
          respiratoryInfectionsYears:
            'years' in data.respiratoryInfections &&
            data.respiratoryInfections.years !== undefined
              ? data.respiratoryInfections.years.toString()
              : undefined,
          respiratoryInfectionsMonths:
            'months' in data.respiratoryInfections &&
            data.respiratoryInfections.months !== undefined
              ? data.respiratoryInfections.months.toString()
              : undefined,
          respiratoryInfectionsMedicament:
            'medicament' in data.respiratoryInfections &&
            !!data.respiratoryInfections.medicament
              ? data.respiratoryInfections.medicament
              : undefined,
        }
      : {}),

    ...('chronicBronchitis' in data && !!data.chronicBronchitis
      ? {
          chronicBronchitisYears:
            'years' in data.chronicBronchitis &&
            data.chronicBronchitis.years !== undefined
              ? data.chronicBronchitis.years.toString()
              : undefined,
          chronicBronchitisMonths:
            'months' in data.chronicBronchitis &&
            data.chronicBronchitis.months !== undefined
              ? data.chronicBronchitis.months.toString()
              : undefined,
          chronicBronchitisMedicament:
            'medicament' in data.chronicBronchitis &&
            !!data.chronicBronchitis.medicament
              ? data.chronicBronchitis.medicament
              : undefined,
        }
      : {}),

    ...('cancer' in data && !!data.cancer
      ? {
          typeOfCancer:
            'typeOfCancer' in data.cancer && !!data.cancer.typeOfCancer
              ? data.cancer.typeOfCancer
              : undefined,
          cancerYears:
            'years' in data.cancer && data.cancer.years !== undefined
              ? data.cancer.years.toString()
              : undefined,
          cancerMonths:
            'months' in data.cancer && data.cancer.months !== undefined
              ? data.cancer.months.toString()
              : undefined,
          cancerMedicament:
            'medicament' in data.cancer && !!data.cancer.medicament
              ? data.cancer.medicament
              : undefined,
        }
      : {}),

    ...('surgeries' in data && !!data.surgeries
      ? {
          typeOfSurgery:
            'typeOfSurgery' in data.surgeries && !!data.surgeries.typeOfSurgery
              ? data.surgeries.typeOfSurgery
              : undefined,
          surgeriesYears:
            'years' in data.surgeries && data.surgeries.years !== undefined
              ? data.surgeries.years.toString()
              : undefined,
          surgeriesMonths:
            'months' in data.surgeries && data.surgeries.months !== undefined
              ? data.surgeries.months.toString()
              : undefined,
          surgeriesMedicament:
            'medicament' in data.surgeries && !!data.surgeries.medicament
              ? data.surgeries.medicament
              : undefined,
        }
      : {}),

    ...('smokingIndex' in data && !!data.smokingIndex
      ? {
          yearsSmoking:
            'yearsSmoking' in data.smokingIndex &&
            data.smokingIndex.yearsSmoking !== undefined
              ? data.smokingIndex.yearsSmoking.toString()
              : undefined,
          yearsWithoutSmoking:
            'yearsWithoutSmoking' in data.smokingIndex &&
            data.smokingIndex.yearsWithoutSmoking !== undefined
              ? data.smokingIndex.yearsWithoutSmoking.toString()
              : undefined,
        }
      : {}),

    ...('pulmonaryPhysiotherapyTreatments' in data &&
    !!data.pulmonaryPhysiotherapyTreatments
      ? {
          percussionSpan:
            'percussionSpan' in data.pulmonaryPhysiotherapyTreatments &&
            data.pulmonaryPhysiotherapyTreatments.percussionSpan !== undefined
              ? data.pulmonaryPhysiotherapyTreatments.percussionSpan.toString()
              : undefined,
          vibration:
            'vibration' in data.pulmonaryPhysiotherapyTreatments &&
            data.pulmonaryPhysiotherapyTreatments.vibration !== undefined
              ? data.pulmonaryPhysiotherapyTreatments.vibration.toString()
              : undefined,
          IPV:
            'IPV' in data.pulmonaryPhysiotherapyTreatments &&
            data.pulmonaryPhysiotherapyTreatments.IPV !== undefined
              ? data.pulmonaryPhysiotherapyTreatments.IPV.toString()
              : undefined,
          strikerVest:
            'strikerVest' in data.pulmonaryPhysiotherapyTreatments &&
            data.pulmonaryPhysiotherapyTreatments.strikerVest !== undefined
              ? data.pulmonaryPhysiotherapyTreatments.strikerVest.toString()
              : undefined,
          assistedCough:
            'assistedCough' in data.pulmonaryPhysiotherapyTreatments &&
            data.pulmonaryPhysiotherapyTreatments.assistedCough !== undefined
              ? data.pulmonaryPhysiotherapyTreatments.assistedCough.toString()
              : undefined,
        }
      : {}),

    ...('medicinesInfo' in data && !!data.medicinesInfo
      ? {
          medicinesInfoName:
            'name' in data.medicinesInfo && !!data.medicinesInfo.name
              ? data.medicinesInfo.name
              : undefined,
          medicinesInfoDose:
            'dose' in data.medicinesInfo && !!data.medicinesInfo.dose
              ? data.medicinesInfo.dose
              : undefined,
          medicinesInfoVia:
            'via' in data.medicinesInfo && !!data.medicinesInfo.via
              ? parseViaInputValue(data.medicinesInfo.via)
              : null,
          medicinesInfoEach:
            'each' in data.medicinesInfo && !!data.medicinesInfo.each
              ? parseEachValue(data.medicinesInfo.each)
              : null,
        }
      : {}),
  };
};

export const useRespiratoryApparatusRecordFormManagement = (
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getRespiratoryApparatus, setRespiratoryApparatus } =
    useMedicalRecord();
  const data = getRespiratoryApparatus();

  const schema = yup.object().shape({
    asthmaYears: yup.string().typeError(t('required')).required(t('required')),
    asthmaMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    asthmaMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    emphysemaYears: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    emphysemaMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    emphysemaMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    respiratoryInfectionsYears: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    respiratoryInfectionsMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    respiratoryInfectionsMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    chronicBronchitisYears: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    chronicBronchitisMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    chronicBronchitisMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    typeOfCancer: yup.string().typeError(t('required')).required(t('required')),
    cancerYears: yup.string().typeError(t('required')).required(t('required')),
    cancerMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    cancerMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    typeOfSurgery: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    surgeriesYears: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    surgeriesMonths: yup
      .string()
      .typeError(t('required'))
      .required(t('required'))
      .matches(/^([0-9]|10|11)$/, t('maxMonth')),
    surgeriesMedicament: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    yearsSmoking: yup.string().typeError(t('required')).required(t('required')),
    yearsWithoutSmoking: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    percussionSpan: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    vibration: yup.string().typeError(t('required')).required(t('required')),
    IPV: yup.string().typeError(t('required')).required(t('required')),
    strikerVest: yup.string().typeError(t('required')).required(t('required')),
    assistedCough: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    medicinesInfoName: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    medicinesInfoDose: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    medicinesInfoVia: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    medicinesInfoEach: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<RespiratoryApparatusRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(data!),
  });

  useEffect(() => {
    methods.reset(getDefaultValues(data!));
  }, [data]);

  const submit: SubmitHandler<RespiratoryApparatusRecordFormFields> = async (
    data
  ) => {
    await setRespiratoryApparatus(data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setRespiratoryApparatus(data, true);
  };

  return {
    methods,
    preSave,
    submit,
  };
};
