import { useForm, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  MedicineInfo,
  ISelectItem,
  DrugInfo,
  MedicineStatus,
} from 'telemed-core';

import { useTranslation } from 'react-i18next';

type FormProps = {
  drug: DrugInfo;
  each: ISelectItem;
  duration: string;
  durationType: ISelectItem;
  indications: string;
};

export const useAddMedicineFormManagement = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  const schema = yup.object().shape({
    drug: yup
      .object()
      .shape({
        unique_id: yup.string(),
      })
      .nullable()
      .required(t('required')),
    each: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    duration: yup.string().required(t('required')),
    durationType: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    indications: yup.string().required(t('required')),
  });

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({
    drug,
    each,
    duration,
    durationType,
    indications,
  }) => {
    console.log('submit AddMedicineForm');

    return {
      category: drug.category,
      duration: +duration,
      durationType: +durationType.value,
      each: +each.value,
      indications,
      name: drug.package_desc,
      presentation: drug.presentation,
      status: MedicineStatus.PENDING,
      unique_id: drug.unique_id,
    } as MedicineInfo;
  };

  return {
    methods,
    submit,
  };
};
