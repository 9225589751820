import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@material-ui/core';
import { BasicTextInput, Modal, Loading, Button } from 'telemed-core';

import { useVerifyEmailFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onMailSent: (username: string) => void;
}

export const VerifyEmailForm: React.FC<Props> = ({ className, onMailSent }) => {
  const { username, error, loading, methods, submit } =
    useVerifyEmailFormManagement();

  useEffect(() => {
    if (username) {
      onMailSent(username);
    }
  }, [username, onMailSent]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Typography
          component='div'
          sx={{
            textTransform: 'uppercase',
          }}
        >
          Restablecer Contraseña
        </Typography>
        <BasicTextInput
          name='email'
          type='text'
          placeholder='e-mail'
          rounded
          grey
        />
        {/* <Button color='primary' type='submit'> */}
        <Button buttonProps={{ color: 'primary', type: 'submit' }}>
          enviar código
        </Button>
        <Link
          href='/'
          variant='caption'
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: '0.8rem',
            textDecoration: 'none',
            marginTop: (theme) => theme.spacing(1.5),
            textTransform: 'uppercase',
          }}
        >
          Regresar a Inicio
        </Link>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error.toString()} />}
    </FormProvider>
  );
};
