import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';
import { CurrentCondition } from 'telemed-core';

export interface CurrentConditionRecordFormFields {
  currentConditionName: string | null;
  currentConditionDescription: string | null;

  diagnosisOfTheTreatingPhysicianName: string | null;
  diagnosisOfTheTreatingPhysicianDescription: string | null;

  addedDiagnosticsName: string | null;
  addedDiagnosticsDescription: string | null;
}

const keyPrefix = 'general';

const getDefaultValues = (conditions: CurrentCondition | {}) => {
  console.log(conditions);

  return {
    currentConditionName:
      'currentCondition' in conditions &&
      !!conditions.currentCondition &&
      'name' in conditions.currentCondition
        ? conditions.currentCondition.name
        : null,
    currentConditionDescription:
      'currentCondition' in conditions &&
      !!conditions.currentCondition &&
      'description' in conditions.currentCondition
        ? conditions.currentCondition.description
        : null,

    diagnosisOfTheTreatingPhysicianName:
      'diagnosisOfTheTreatingPhysician' in conditions &&
      !!conditions.diagnosisOfTheTreatingPhysician &&
      'name' in conditions.diagnosisOfTheTreatingPhysician
        ? conditions.diagnosisOfTheTreatingPhysician.name
        : null,
    diagnosisOfTheTreatingPhysicianDescription:
      'diagnosisOfTheTreatingPhysician' in conditions &&
      !!conditions.diagnosisOfTheTreatingPhysician &&
      'description' in conditions.diagnosisOfTheTreatingPhysician
        ? conditions.diagnosisOfTheTreatingPhysician.description
        : null,

    addedDiagnosticsName:
      'addedDiagnostics' in conditions &&
      !!conditions.addedDiagnostics &&
      'name' in conditions.addedDiagnostics
        ? conditions.addedDiagnostics.name
        : null,
    addedDiagnosticsDescription:
      'addedDiagnostics' in conditions &&
      !!conditions.addedDiagnostics &&
      'description' in conditions.addedDiagnostics
        ? conditions.addedDiagnostics.description
        : null,
  };
};

export const useCurrentConditionRecordFormManagement = (
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getCurrentCondition, setCurrentCondition } = useMedicalRecord();
  const conditions = getCurrentCondition();

  const schema = yup.object().shape({
    currentConditionName: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    currentConditionDescription: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    diagnosisOfTheTreatingPhysicianName: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    diagnosisOfTheTreatingPhysicianDescription: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),

    addedDiagnosticsName: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    addedDiagnosticsDescription: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<CurrentConditionRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(conditions!),
  });

  useEffect(() => {
    methods.reset(getDefaultValues(conditions!));
  }, [conditions]);

  const submit: SubmitHandler<CurrentConditionRecordFormFields> = async (
    data
  ) => {
    await setCurrentCondition(data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setCurrentCondition(data, true);
  };

  return {
    methods,
    preSave,
    submit,
  };
};
