import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Consultation,
  DateData,
  ISelectItem,
  parseReminder,
  useEditConsultation,
} from 'telemed-core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAlarms } from '../useAlarms';

const REQUIRED_TEXT = 'Este campo es obligatorio';

const schema = yup.object().shape({
  date: yup.string().required(REQUIRED_TEXT),
  reminderOne: yup.object().shape({
    value: yup.string(),
  }),
  reminderTwo: yup.object().shape({
    value: yup.string(),
  }),
});

export const useEditConsultationFormManagement = (
  originalConsultation: Consultation
) => {
  const [error, setError] = useState<string | null>(null);
  const { edited, error: reqError, loading, edit } = useEditConsultation();
  const { replaceAlarms } = useAlarms();

  useEffect(() => {
    !!reqError && setError(reqError);
  }, [reqError]);

  type FormProps = {
    date: string;
    reminderOne: ISelectItem;
    reminderTwo: ISelectItem;
  };

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({
    date,
    reminderOne,
    reminderTwo,
  }) => {
    setError(null);

    if (moment(date).isBefore(moment(moment(new Date())), 'minutes')) {
      setError('Selecciona una fecha/hora posterior');
      return;
    }

    const consultationDate = moment(date).utc().format('YYYY-MM-DD HH:mm:00');
    const dates: DateData[] = [];

    if (!!reminderOne) {
      const date = parseReminder(consultationDate, reminderOne);
      !!date && dates.push({ id: 1, date });
    }

    if (!!reminderTwo) {
      const date = parseReminder(consultationDate, reminderTwo);
      !!date && dates.push({ id: 2, date });
    }

    const modifiedConsultation = {
      ...originalConsultation,
      consultationDate,
      referenceDate: { dates },
    };

    await edit(modifiedConsultation);
    replaceAlarms(modifiedConsultation);
  };

  return {
    edited,
    error,
    loading,
    methods,
    submit,
  };
};
