import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { EquipmentInfo, LayoutDialog } from 'telemed-core';

import { AddEquipForm } from '../forms';

type Props = {
  open: boolean;
  onClose: () => void;
  onAdd: (newEquipment: EquipmentInfo) => void;
};

const keyPrefix = 'dialogs.AddEquipDialog';

export const AddEquipDialog: React.FC<Props> = ({ open, onAdd, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <LayoutDialog title={t('title')} open={open} handleClose={onClose}>
      <Grid container width='500px' minHeight='346px'>
        <AddEquipForm onAdd={onAdd} onClose={onClose} />
      </Grid>
    </LayoutDialog>
  );
};
