import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ISelectItem,
  StudyConfigInfo,
  StudyInfo,
  StudyStatus,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';

type FormProps = {
  study: StudyConfigInfo;
  indications: string;
  name: string;
  studyType: ISelectItem;
  description: string;
};

export const useAddStudyFormManagement = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  const schema = yup.object().shape({
    study: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .required(t('required')),
    name: yup.string().when('study', {
      is: (study: StudyConfigInfo | null) => {
        console.log('STUDY', study);
        return !!study && +study.id === -1;
      },
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    studyType: yup.object().when('study', {
      is: (study: StudyConfigInfo | null) => !!study && +study.id === -1,
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired().nullable(),
    }),
    description: yup.string().when('study', {
      is: (study: StudyConfigInfo | null) => !!study && +study.id === -1,
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    indications: yup.string().required(t('required')),
  });

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  console.log('error', methods.formState.errors);

  const submit: SubmitHandler<FormProps> = async ({
    study,
    indications,
    name,
    studyType,
    description,
  }) => {
    console.log('submit AddEquipForm');

    const { id } = study;
    const isNew = id === -1;

    return {
      name: isNew ? name : study.name,
      description: isNew ? description : study.description,
      indications,
      status: StudyStatus.NEW,
      type: isNew ? +studyType.value : study.type,
    } as StudyInfo;
  };

  return {
    methods,
    submit,
  };
};
