import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { DateRange } from '@material-ui/lab/DateRangePicker';
import List from '@material-ui/core/List';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import StaticDateRangePicker from '@material-ui/lab/StaticDateRangePicker';
import moment from 'moment';
import 'moment/locale/es-mx';

import {
  Consultation,
  Layout,
  Modal,
  useAllConsultationLazy,
  User,
  UserListItem,
} from 'telemed-core';

import { EditConsultationDialog } from '../dialogs';
import { PhoneSvg } from '../resources/svg';
import { useVideoCall } from '../hooks';

const keyPrefix = 'containers.ScheduleContainer';

export const ScheduleContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const [consultations, setConsultations] = useState<Consultation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<Consultation[]>([]);
  const [selectedDates, setSelectedDates] = React.useState<DateRange<Date>>([
    new Date(),
    null,
  ]);

  const { t } = useTranslation('translation', { keyPrefix });
  const { error: conErr, loading: conLoad, getAll } = useAllConsultationLazy();
  const { error: callErr, loading: callLoad } = useVideoCall();

  const getConsultations = React.useCallback(async function () {
    const { history, actual } = await getAll();
    setHistory(history);
    setConsultations(actual);
  }, []);

  useEffect(() => {
    getConsultations();
  }, [getConsultations]);

  const handleCalendarChange = async (newSelectedDates: DateRange<Date>) => {
    setSelectedDates(newSelectedDates);
  };

  return (
    <Layout
      title={t('title')}
      error={conErr || callErr}
      loading={conLoad || callLoad}
    >
      <Container
        maxWidth='md'
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment} locale='es-MX'>
            <StaticDateRangePicker
              calendars={1}
              displayStaticWrapperAs='desktop'
              value={selectedDates}
              onChange={handleCalendarChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} label='inicio' />
                  <Box sx={{ mx: 2 }}> a </Box>
                  <TextField {...endProps} label='fin' />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
        <List>
          {!loading && (
            <Box
              sx={{
                marginTop: (theme) => theme.spacing(5),
                position: 'absolute',
                width: '100%',
              }}
            >
              <Typography
                variant='body1'
                color='GrayText'
                sx={{
                  textAlign: 'center',
                }}
              >
                {t('empty.inRange')}
              </Typography>
            </Box>
          )}

          {!!history &&
            history
              .filter((item) => filterList(item, selectedDates))
              .map((consultation: Consultation, key: number) =>
                renderList(consultation, key)
              )}
          {!!consultations &&
            consultations
              .filter((item) => filterList(item, selectedDates))
              .map((consultation: Consultation, key: number) =>
                renderList(consultation, key, getConsultations, true)
              )}
        </List>
      </Container>
    </Layout>
  );
};

const filterList = (
  { consultationDate }: Consultation,
  selectedDates: DateRange<Date>
) => {
  var local = moment
    .utc(consultationDate)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  if (selectedDates[1] === null) {
    return moment(local).isSame(selectedDates[0], 'days');
  }

  return moment(local).isBetween(
    selectedDates[0],
    selectedDates[1],
    'days',
    '[]'
  );
};

const renderList = (
  consultation: Consultation,
  key: number,
  reloadConsultations?: () => void,
  renderActions?: boolean
) => {
  const { user, consultationDate } = consultation;
  const { name, firstLastName, secondLastName } = user.userInfo;

  const local = moment.utc(consultationDate).local().format('YYYY-MM-DD HH:mm');
  const date = moment(local, 'YYYY-MM-DD hh:mm:ss');
  const formattedConsultationDate = date.calendar({
    lastDay: '[Ayer a las] h:mm a',
    sameDay: '[Hoy a las] h:mm a',
    nextDay: '[Mañana a las] h:mm a',
    lastWeek: '[El pasado] dddd [a las] h:mm a',
    nextWeek: '[EL próximo] dddd [a las] h:mm a',
    sameElse: 'L',
  });

  return (
    <ListItem
      consultation={consultation}
      disabled={!renderActions}
      key={key}
      subTitle={`${name} ${firstLastName} ${secondLastName}`}
      title={formattedConsultationDate}
      user={user}
      reloadConsultations={reloadConsultations}
    />
  );
};

type ListItemProps = {
  consultation: Consultation;
  disabled: boolean;
  subTitle: string;
  title: string;
  user: User;
  reloadConsultations?: () => void;
};

const ListItem: React.FC<ListItemProps> = ({
  consultation,
  disabled,
  subTitle,
  title,
  user,
  reloadConsultations,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItem`,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [showNoTimeToCallMessage, setShowNoTimeToCallMessage] =
    useState<boolean>(false);
  const [showNoCameraModal, setShowNoCameraModal] = useState<boolean>();
  const { enterRoom, verifyCamera } = useVideoCall();

  const handleClickEdit = () => {
    setOpenDialog(true);
  };

  const isTimeToCall = () =>
    moment.utc(consultation.consultationDate).local().isBefore(moment());

  const goToVideoCall = async () => {
    const isCameraAvailable = await verifyCamera();
    if (!isCameraAvailable) {
      setShowNoCameraModal(true);
    } else {
      enterRoom(consultation, user);
    }
  };

  const handleClickCall = () => {
    isTimeToCall() ? goToVideoCall() : setShowNoTimeToCallMessage(true);
  };

  const handleCloseDialog = (needsRefresh?: boolean) => {
    setOpenDialog(false);
    !!needsRefresh && !!reloadConsultations && reloadConsultations();
  };

  return (
    <>
      <UserListItem
        user={user}
        title={title}
        subTitle={subTitle}
        rightCol={
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '100px 50px',
              alignItems: 'center',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <Button
              variant='text'
              color='secondary'
              onClick={handleClickEdit}
              disabled={disabled}
            >
              reagendar
            </Button>
            <Tooltip title={t('initiate.tooltip')!} placement='bottom' arrow>
              <IconButton
                size='large'
                color='primary'
                disabled={disabled}
                onClick={handleClickCall}
              >
                <PhoneSvg />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <EditConsultationDialog
        nameText={subTitle}
        originalDateText={title}
        open={openDialog}
        originalConsultation={consultation}
        handleClose={handleCloseDialog}
      />
      {showNoTimeToCallMessage && (
        <Modal
          title={t('noTimeToCall.title')}
          message={t('noTimeToCall.message')}
          onClickOk={() => {
            setShowNoTimeToCallMessage(false);
          }}
        />
      )}
      {showNoCameraModal && (
        <Modal
          title={t('noCamera.title')}
          message={t('noCamera.message')}
          onClickOk={() => {
            setShowNoCameraModal(false);
          }}
        />
      )}
    </>
  );
};
