import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  BasicTextInput,
  ExerciseInfo,
  Loading,
  Modal,
  AddButton,
  InputLabel,
  MedicineInfo,
  StudyInfo,
  EquipmentInfo,
} from 'telemed-core';

import { TreatmentFormFields, useTreatmentFormManagement } from '../hooks';
import {
  ExerciseList,
  EquipList,
  MedicineList,
  StudyList,
} from '../components';
import {
  AddEquipDialog,
  AddExerciseDialog,
  AddStudyDialog,
  AddMedicineDialog,
} from '../dialogs';

type Props = { patientId: number; onClose: () => void };

const keyPrefix = 'forms.TreatmentForm';

export const TreatmentForm: React.FC<Props> = ({
  patientId,
  onClose,
}): JSX.Element => {
  const [exerciseList, setExerciseList] = useState<ExerciseInfo[]>([]);
  const [medicineList, setMedicineList] = useState<MedicineInfo[]>([]);
  const [studyList, setStudyList] = useState<StudyInfo[]>([]);
  const [equipList, setEquipList] = useState<EquipmentInfo[]>([]);

  const [openExerciseModal, setOpenExerciseModal] = useState<boolean>(false);
  const [openMedicineModal, setOpenMedicineModal] = useState<boolean>(false);
  const [openEquipModal, setOpenEquipModal] = useState<boolean>(false);
  const [openStudyModal, setOpenStudyModal] = useState<boolean>(false);

  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, methods, successSubmit, submit } =
    useTreatmentFormManagement(patientId);

  const handleBack = async () => {
    onClose();
  };

  // Exercise

  const handleOpenExerciseModal = () => {
    setOpenExerciseModal(true);
  };
  const handleCloseExerciseModal = () => {
    setOpenExerciseModal(false);
  };

  const handleOnAddExercise = (newExercise: ExerciseInfo) => {
    setExerciseList((list) => [...list, newExercise]);
    setOpenExerciseModal(false);
  };

  // Medicine

  const handleOpenMedicineModal = () => {
    setOpenMedicineModal(true);
  };
  const handleCloseMedicineModal = () => {
    setOpenMedicineModal(false);
  };

  const handleOnAddMedicine = (newMedicine: MedicineInfo) => {
    setMedicineList((list) => [...list, newMedicine]);
    setOpenMedicineModal(false);
  };

  // Study

  const handleOpenStudyModal = () => {
    setOpenStudyModal(true);
  };
  const handleCloseStudyModal = () => {
    setOpenStudyModal(false);
  };

  const handleOnAddStudy = (newStudy: StudyInfo) => {
    setStudyList((list) => [...list, newStudy]);
    setOpenStudyModal(false);
  };

  // Equipment

  const handleOpenEquipModal = () => {
    setOpenEquipModal(true);
  };
  const handleCloseEquipModal = () => {
    setOpenEquipModal(false);
  };

  const handleOnAddEquip = (newEquipment: EquipmentInfo) => {
    setEquipList((list) => [...list, newEquipment]);
    setOpenEquipModal(false);
  };

  const onSubmit = async (fields: TreatmentFormFields) => {
    await submit({ fields, exerciseList, medicineList, studyList, equipList });
  };

  return (
    <>
      {openExerciseModal && (
        <AddExerciseDialog
          open={true}
          onClose={handleCloseExerciseModal}
          onAdd={handleOnAddExercise}
        />
      )}
      {openMedicineModal && (
        <AddMedicineDialog
          open={true}
          onClose={handleCloseMedicineModal}
          onAdd={handleOnAddMedicine}
        />
      )}
      {openStudyModal && (
        <AddStudyDialog
          open={true}
          onClose={handleCloseStudyModal}
          onAdd={handleOnAddStudy}
        />
      )}
      {openEquipModal && (
        <AddEquipDialog
          open={true}
          onClose={handleCloseEquipModal}
          onAdd={handleOnAddEquip}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2} width='100%'>
            <Grid item md={12}>
              <BasicTextInput
                name='name'
                maxLength={35}
                label={t('name.label')}
                placeholder={t('name.placeholder')}
              />
            </Grid>
            <Grid item md={12}>
              <BasicTextInput
                name='indications'
                maxLength={300}
                minRows={3}
                label={t('indications.label')}
                placeholder={t('indications.placeholder')}
              />
            </Grid>

            <ListWrapper
              label={{ title: t('exercise.label'), button: t('exercise.add') }}
              onOpen={handleOpenExerciseModal}
            >
              <ExerciseList list={exerciseList} />
            </ListWrapper>

            <ListWrapper
              label={{
                title: t('medicines.label'),
                button: t('medicines.add'),
              }}
              onOpen={handleOpenMedicineModal}
            >
              <MedicineList list={medicineList} />
            </ListWrapper>

            <ListWrapper
              label={{
                title: t('studies.label'),
                button: t('studies.add'),
              }}
              onOpen={handleOpenStudyModal}
            >
              <StudyList list={studyList} />
            </ListWrapper>

            <ListWrapper
              label={{
                title: t('equip.label'),
                button: t('equip.add'),
              }}
              onOpen={handleOpenEquipModal}
            >
              <EquipList list={equipList} />
            </ListWrapper>

            <Grid
              item
              md={6}
              display='flex'
              justifyContent='center'
              marginTop={4}
            >
              <Button buttonProps={{ onClick: handleBack }} gray>
                {t('back.label')}
              </Button>
            </Grid>
            <Grid
              item
              md={6}
              display='flex'
              justifyContent='center'
              marginTop={4}
            >
              <Button buttonProps={{ type: 'submit' }} gray>
                {t('submit.label')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
      {loading && <Loading />}
      {!!successSubmit && (
        <Modal
          title={t('modals.success.title')}
          message={t('modals.success.message')}
          onClickOk={onClose}
        />
      )}
    </>
  );
};

type ListWrapperProps = {
  label: {
    title: string;
    button: string;
  };
  onOpen: () => void;
};

const ListWrapper: React.FC<ListWrapperProps> = ({
  children,
  label,
  onOpen,
}): JSX.Element => (
  <>
    <Grid item md={6}>
      <InputLabel label={label.title} />
    </Grid>
    <Grid item md={6} display='flex' justifyContent='end'>
      <AddButton label={label.button} onClick={onOpen} />
    </Grid>
    <Grid item md={12}>
      {children}
    </Grid>
  </>
);
