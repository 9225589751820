import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DiseaseInput,
  Button,
  Modal,
  Loading,
  RadioGroupInput,
} from 'telemed-core';

import { useHistoricalRecordFormManagement, useMedicalRecord } from '../hooks';

type Props = {
  gender?: string;
  memberName: string;
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.HistoricalRecordForm';

export const HistoricalRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, gender = 'M', memberName, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { defaultValues, methods, preSave, submit } =
    useHistoricalRecordFormManagement(memberName, onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  console.log('defaultValues', defaultValues);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <RadioGroupInput
              name='aliveOrDead'
              options={[
                { value: '1', label: t(`aliveOrDead.alive${gender}`) },
                { value: '0', label: t(`aliveOrDead.dead${gender}`) },
              ]}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Alergias' }}
              names={{
                status: 'allergiesStatus',
                years: 'allergiesYears',
                months: 'allergiesMonths',
              }}
              showTextBox={defaultValues.allergiesStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Cardiopatía' }}
              names={{
                status: 'heartDiseaseStatus',
                years: 'heartDiseaseYears',
                months: 'heartDiseaseMonths',
              }}
              showTextBox={defaultValues.heartDiseaseStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Diabetes mellitus' }}
              names={{
                status: 'mellitusDiabetesStatus',
                years: 'mellitusDiabetesYears',
                months: 'mellitusDiabetesMonths',
              }}
              showTextBox={defaultValues.mellitusDiabetesStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Endocrinológica' }}
              names={{
                status: 'endocrinologicalStatus',
                years: 'endocrinologicalYears',
                months: 'endocrinologicalMonths',
              }}
              showTextBox={defaultValues.endocrinologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Hematológica' }}
              names={{
                status: 'hematologicStatus',
                years: 'hematologicYears',
                months: 'hematologicMonths',
              }}
              showTextBox={defaultValues.hematologicStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Hipertensión arterial' }}
              names={{
                status: 'arterialHypertensionStatus',
                years: 'arterialHypertensionYears',
                months: 'arterialHypertensionMonths',
              }}
              showTextBox={defaultValues.arterialHypertensionStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Nefropatía' }}
              names={{
                status: 'nephropathyStatus',
                years: 'nephropathyYears',
                months: 'nephropathyMonths',
              }}
              showTextBox={defaultValues.nephropathyStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Neumológicas' }}
              names={{
                status: 'pneumologicalStatus',
                years: 'pneumologicalYears',
                months: 'pneumologicalMonths',
              }}
              showTextBox={defaultValues.pneumologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <DiseaseInput
              labels={{ main: 'Neurológica' }}
              names={{
                status: 'neurologicalStatus',
                years: 'neurologicalYears',
                months: 'neurologicalMonths',
              }}
              showTextBox={defaultValues.neurologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} marginBottom={4}>
            <DiseaseInput
              labels={{ main: 'Otra', extra: 'Describa brevemente' }}
              other={true}
              names={{
                status: 'otherStatus',
                years: 'otherDesc',
                months: 'otherMonths',
              }}
              showTextBox={defaultValues.otherStatus === 'true'}
            />
          </Grid>
          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
      {/* {added && (
        <Modal
          title='Consulta Agendada'
          message='La consulta fue agendada satisfactoriamente, se envió un correo al paciente con la información de la consulta.'
          onClickOk={onAdded}
        />
      )} */}
    </FormProvider>
  );
};
