import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, Link, Typography } from '@material-ui/core';
import { BasicTextInput, Modal, Loading, Button } from 'telemed-core';

import { useRestorePasswordFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  username: string;
  onValidate: () => void;
}

export const RestorePasswordForm: React.FC<Props> = ({
  className,
  onValidate,
  username,
}) => {
  const { restored, error, loading, methods, submit } =
    useRestorePasswordFormManagement();

  useEffect(() => {
    if (restored) {
      onValidate();
    }
  }, [restored, onValidate]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(({ password }) =>
          submit({ username, password })
        )}
        className={className}
      >
        <Box>
          <Typography
            component='div'
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              marginBottom: (theme) => theme.spacing(1),
            }}
          >
            Restablecer Contraseña
          </Typography>
          <Typography
            variant='body2'
            sx={{
              textAlign: 'justify',
              lineHeight: '1.1rem',
              fontFamily: 'AntennaCondBeta',
              letterSpacing: 0,
            }}
          >
            La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1
            número y un carácter especial.
          </Typography>
        </Box>
        <BasicTextInput
          name='password'
          type='password'
          placeholder='nueva contraseña'
          rounded
          grey
        />
        <BasicTextInput
          name='repassword'
          type='password'
          placeholder='repetir contraseña'
          rounded
          grey
        />
        {/* <Button color='primary' type='submit'> */}
        <Button buttonProps={{ type: 'submit', color: 'primary' }}>
          restablecer contraseña
        </Button>
        <Link
          href='/'
          variant='caption'
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: '0.8rem',
            textDecoration: 'none',
            marginTop: (theme) => theme.spacing(1.5),
            textTransform: 'uppercase',
          }}
        >
          Regresar a Inicio
        </Link>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error.toString()} />}
    </FormProvider>
  );
};
