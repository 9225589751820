import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BasicTextInput,
  BasicYesNoInput,
  Button,
  BreathingInput,
  CyanosisInput,
  EyesInput,
  ExpectorationColorInput,
  HandleInput,
  InputLabel,
  Loading,
  Modal,
  MouthInput,
  TimeInput,
  YesNoContentInput,
  YesNoInput,
  BorgInput,
  QuantityInput,
} from 'telemed-core';
import {
  useMedicalRecord,
  usePhysicalExplorationRecordFormManagement,
} from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.PhysicalExplorationRecordForm';

export const PhysicalExplorationRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading } = useMedicalRecord();

  const { defaultValues, methods, preSave, submit } =
    usePhysicalExplorationRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <EyesInput label={t('eyes.label')} />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <MouthInput label={t('mouth.label')} />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BreathingInput label={t('breathingType.label')} />
          </Grid>
          <Grid
            item
            md={4}
            sx={{ display: 'flex', flexDirection: 'row', position: 'relative' }}
          >
            <BasicTextInput
              name='weight'
              label={t('weight.label')}
              maxLength={5}
            />
            <Typography
              sx={{
                textAlign: 'center',
                position: 'absolute',
                right: '15px',
                bottom: '10px',
              }}
            >
              {t('weight.unit')}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sx={{ display: 'flex', flexDirection: 'row', position: 'relative' }}
          >
            <BasicTextInput name='size' label={t('size.label')} maxLength={5} />
            <Typography
              sx={{
                textAlign: 'center',
                position: 'absolute',
                right: '15px',
                bottom: '10px',
              }}
            >
              {t('size.unit')}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sx={{ display: 'flex', flexDirection: 'row', position: 'relative' }}
          >
            <BasicTextInput
              name='height'
              label={t('height.label')}
              maxLength={5}
            />
            <Typography
              sx={{
                textAlign: 'center',
                position: 'absolute',
                right: '15px',
                bottom: '10px',
              }}
            >
              {t('height.unit')}
            </Typography>
          </Grid>
          {/* Aspctos de paciente */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('patientAspects.label')} />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.hydration.main'),
              }}
              name='hydration'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.awake.main'),
              }}
              name='awake'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.cooperativeConscious.main'),
              }}
              name='cooperativeConscious'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.clouded.main'),
              }}
              name='clouded'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.unconscious.main'),
              }}
              name='unconscious'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.quiet.main'),
              }}
              name='quiet'
            />
          </Grid>
          <Grid
            item
            md={12}
            display='flex'
            flexDirection='row'
            marginLeft={3}
            sx={{ paddingTop: '0px !important' }}
          >
            <BasicYesNoInput
              labels={{
                main: t('patientAspects.depressed.main'),
              }}
              name='depressed'
            />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row'>
            <YesNoInput
              labels={{ main: t('cyanosis.label') }}
              names={{ status: 'cyanosisStatus' }}
              showTextBox={defaultValues.cyanosisStatus === 'true'}
            >
              <CyanosisInput />
            </YesNoInput>
          </Grid>

          <Grid item md={12}>
            <BasicTextInput
              name='otherRelevantData'
              maxLength={100}
              label={t('otherRelevantData.label')}
            />
          </Grid>

          <YesNoContentInput
            name='lastSixMonthsWeightChangeStatus'
            labels={{ main: t('weightChange.label') }}
            showTextBox={
              defaultValues.lastSixMonthsWeightChangeStatus === 'true'
            }
          >
            <Grid
              item
              md={12}
              display='flex'
              flexDirection='row'
              marginLeft={3}
              sx={{ paddingTop: '0px !important' }}
            >
              <BasicYesNoInput
                name='intentional'
                labels={{
                  main: t('weightChange.intentional'),
                  yes: 'No',
                  not: 'Si',
                }}
                showTextBox={defaultValues.intentional === 'true'}
              >
                <Grid
                  item
                  md={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}
                >
                  <BasicTextInput
                    name='gain'
                    label={t('weightChange.gain.label')}
                    maxLength={5}
                    onlyNumbers={true}
                  />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      position: 'absolute',
                      right: '15px',
                      bottom: '10px',
                    }}
                  >
                    {t('weightChange.gain.unit')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}
                >
                  <BasicTextInput
                    name='lost'
                    label={t('weightChange.lost.label')}
                    maxLength={5}
                    onlyNumbers={true}
                  />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      position: 'absolute',
                      right: '15px',
                      bottom: '10px',
                    }}
                  >
                    {t('weightChange.lost.unit')}
                  </Typography>
                </Grid>
              </BasicYesNoInput>
            </Grid>
          </YesNoContentInput>
          <YesNoContentInput
            name='useAccessoryMusclesStatus'
            labels={{ main: t('useAccessoryMuscles.label') }}
            showTextBox={defaultValues.useAccessoryMusclesStatus === 'true'}
          >
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='nasalElevator'
                labels={{ main: t('useAccessoryMuscles.nasalElevator') }}
              />
            </Grid>
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='sternocleidomastoid'
                labels={{ main: t('useAccessoryMuscles.sternocleidomastoid') }}
              />
            </Grid>
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='previousScalene'
                labels={{ main: t('useAccessoryMuscles.previousScalene') }}
              />
            </Grid>
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='middleAndLater'
                labels={{ main: t('useAccessoryMuscles.middleAndLater') }}
              />
            </Grid>
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='externalIntercostals'
                labels={{ main: t('useAccessoryMuscles.externalIntercostals') }}
              />
            </Grid>
            <Grid item md={12} marginLeft={3}>
              <BasicYesNoInput
                name='pectoralisMajorMinor'
                labels={{ main: t('useAccessoryMuscles.pectoralisMajorMinor') }}
              />
            </Grid>
          </YesNoContentInput>
          <YesNoContentInput
            name='respiratoryMusclesAssessmentStatus'
            labels={{ main: t('respiratoryMusclesAssessment.label') }}
            showTextBox={
              defaultValues.respiratoryMusclesAssessmentStatus === 'true'
            }
          >
            <Grid
              item
              md={12}
              display='flex'
              flexDirection='row'
              paddingX={0}
              marginLeft={3}
            >
              <InputLabel
                label={t('respiratoryMusclesAssessment.inspiratory.label')}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='trapezoids'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.inspiratory.trapezoids'
                  ),
                }}
              />
            </Grid>

            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='scalenes'
                labels={{
                  main: t('respiratoryMusclesAssessment.inspiratory.scalenes'),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='pectorals'
                labels={{
                  main: t('respiratoryMusclesAssessment.inspiratory.pectorals'),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='rmaSternocleidomastoid'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.inspiratory.sternocleidomastoid'
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='rmaExternalIntercostals'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.inspiratory.externalIntercostals'
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='diaphragm'
                labels={{
                  main: t('respiratoryMusclesAssessment.inspiratory.diaphragm'),
                }}
              />
            </Grid>

            <Grid
              item
              md={12}
              display='flex'
              flexDirection='row'
              paddingX={0}
              marginLeft={3}
            >
              <InputLabel
                label={t('respiratoryMusclesAssessment.expiratory.label')}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='serratos'
                labels={{
                  main: t('respiratoryMusclesAssessment.expiratory.serratos'),
                }}
              />
            </Grid>

            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='oblique'
                labels={{
                  main: t('respiratoryMusclesAssessment.expiratory.oblique'),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='rectusAbdominis'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.expiratory.rectusAbdominis'
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='transversusAbdominis'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.expiratory.transversusAbdominis'
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} marginLeft={6}>
              <BasicYesNoInput
                name='internalIntercostals'
                labels={{
                  main: t(
                    'respiratoryMusclesAssessment.expiratory.internalIntercostals'
                  ),
                }}
              />
            </Grid>
          </YesNoContentInput>

          {/*  Auscultación */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('auscultation.label')} />
          </Grid>
          <Grid item md={12} display='flex' flexDirection='row' marginLeft={3}>
            <Typography variant='body2'>
              {t('auscultation.rales.label')}
            </Typography>
          </Grid>
          <Grid item md={12} marginLeft={6}>
            <BasicYesNoInput
              name='alveolars'
              labels={{
                main: t('auscultation.rales.alveolars'),
              }}
            />
          </Grid>
          <Grid item md={12} marginLeft={6}>
            <BasicYesNoInput
              name='bronchialAlveolar'
              labels={{
                main: t('auscultation.rales.bronchialAlveolar'),
              }}
            />
          </Grid>
          <Grid item md={12} marginLeft={6}>
            <BasicYesNoInput
              name='bronchial'
              labels={{
                main: t('auscultation.rales.bronchial'),
              }}
            />
          </Grid>
          <Grid item md={12} marginLeft={3}>
            <BasicYesNoInput
              name='wheezing'
              labels={{
                main: t('auscultation.wheezing'),
              }}
            />
          </Grid>
          <Grid item md={12} marginLeft={3}>
            <BasicYesNoInput
              name='condensationSyndrome'
              labels={{
                main: t('auscultation.condensationSyndrome'),
              }}
            />
          </Grid>

          {/*  Síntomas actuales */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('currentSymptoms.label')} />
          </Grid>

          <Grid item md={12} marginLeft={3}>
            <BasicYesNoInput
              name='coughStatus'
              labels={{
                main: t('currentSymptoms.cough.label'),
              }}
              showTextBox={defaultValues.coughStatus === 'true'}
            >
              <Grid item md={12} marginLeft={3}>
                <BasicYesNoInput
                  name='uponAwakening'
                  labels={{
                    main: t('currentSymptoms.cough.uponAwakening'),
                  }}
                />
              </Grid>
              <Grid item md={12} marginLeft={3}>
                <BasicYesNoInput
                  name='duringTheDay'
                  labels={{
                    main: t('currentSymptoms.cough.duringTheDay'),
                  }}
                />
              </Grid>
              <Grid item md={12} marginLeft={3}>
                <BasicYesNoInput
                  name='atNight'
                  labels={{
                    main: t('currentSymptoms.cough.atNight'),
                  }}
                />
              </Grid>
              <Grid item md={12} marginLeft={3}>
                <BasicYesNoInput
                  name='continuous'
                  labels={{
                    main: t('currentSymptoms.cough.continuous'),
                  }}
                />
              </Grid>
            </BasicYesNoInput>
          </Grid>

          <Grid item md={12} marginLeft={3}>
            <BasicYesNoInput
              name='expectorationStatus'
              labels={{
                main: t('currentSymptoms.expectoration.label'),
              }}
              showTextBox={defaultValues.expectorationStatus === 'true'}
            >
              <Grid container spacing={2} marginLeft={2} marginRight={2}>
                <Grid item md={4}>
                  <QuantityInput
                    label={t('currentSymptoms.expectoration.quantity')}
                  />
                </Grid>
                <Grid item md={4}>
                  <TimeInput label={t('currentSymptoms.expectoration.time')} />
                </Grid>
                <Grid item md={4}>
                  <ExpectorationColorInput
                    name='color'
                    label={t('currentSymptoms.expectoration.color')}
                  />
                </Grid>
                <Grid item md={4}>
                  <BasicTextInput
                    name='consistencies'
                    maxLength={30}
                    label={t('currentSymptoms.expectoration.consistencies')}
                  />
                </Grid>
                <Grid item md={4}>
                  <HandleInput
                    name='handling'
                    label={t('currentSymptoms.expectoration.handling')}
                  />
                </Grid>
              </Grid>
            </BasicYesNoInput>
          </Grid>
          <Grid item md={12} marginLeft={3}>
            <BasicYesNoInput
              name='feverStatus'
              labels={{
                main: t('currentSymptoms.fever.label'),
              }}
              showTextBox={defaultValues.feverStatus === 'true'}
            >
              <Grid
                item
                md={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                }}
              >
                <BasicTextInput name='feverAnswer' label='' maxLength={5} />
                <Typography
                  sx={{
                    textAlign: 'center',
                    position: 'absolute',
                    right: '15px',
                    bottom: '10px',
                  }}
                >
                  {t('currentSymptoms.fever.unit')}
                </Typography>
              </Grid>
            </BasicYesNoInput>
          </Grid>

          {/*  Escala de Borg */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('borgScale.label')} />
          </Grid>
          <Grid container md={12} paddingLeft={4} paddingRight={4} spacing={3}>
            <Grid item md={4}>
              <BorgInput name='borgScaleRepose' label={t('borgScale.repose')} />
            </Grid>
            <Grid item md={4}>
              <BorgInput name='borgScaleTalk' label={t('borgScale.talk')} />
            </Grid>
            <Grid item md={4}>
              <BorgInput name='borgScaleEat' label={t('borgScale.eat')} />
            </Grid>
            <Grid item md={4}>
              <BorgInput name='borgScaleBath' label={t('borgScale.bath')} />
            </Grid>
            <Grid item md={4}>
              <BorgInput name='borgScaleWalk' label={t('borgScale.walk')} />
            </Grid>
          </Grid>

          {/*  Escala de Borg */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('exerciseCapacity.label')} />
          </Grid>
          <Grid container md={12} paddingLeft={4} paddingRight={4} spacing={3}>
            <Grid item md={6}>
              <BasicTextInput
                name='hike'
                maxLength={30}
                label={t('exerciseCapacity.hike')}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='exerciseCapacityOther'
                maxLength={30}
                label={t('exerciseCapacity.other')}
              />
            </Grid>
          </Grid>

          {/*  Pruebas de funciones pulmonares */}
          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('lungFunctionTests.label')} />
          </Grid>
          <Grid container md={12} paddingLeft={4} paddingRight={4} spacing={3}>
            <Grid item md={6}>
              <BasicTextInput
                name='vitalCapacity'
                maxLength={30}
                label={t('lungFunctionTests.vitalCapacity')}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='bloodPressure'
                maxLength={30}
                label={t('lungFunctionTests.bloodPressure')}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='breathingFrequency'
                maxLength={30}
                label={t('lungFunctionTests.breathingFrequency')}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='heartRate'
                maxLength={30}
                label={t('lungFunctionTests.heartRate')}
              />
            </Grid>
          </Grid>

          <Grid item md={12} display='flex' flexDirection='row' paddingX={0}>
            <InputLabel label={t('lungFunctionTests.oxygenSaturation.label')} />
          </Grid>
          <Grid container md={12} paddingLeft={4} paddingRight={4} spacing={3}>
            <Grid item md={6}>
              <BasicTextInput
                name='activeInAmbientAir'
                maxLength={30}
                label={t(
                  'lungFunctionTests.oxygenSaturation.activeInAmbientAir'
                )}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='activeWithOxygen'
                maxLength={30}
                label={t('lungFunctionTests.oxygenSaturation.activeWithOxygen')}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='atRestInAmbientAir'
                maxLength={30}
                label={t(
                  'lungFunctionTests.oxygenSaturation.atRestInAmbientAir'
                )}
              />
            </Grid>
            <Grid item md={6}>
              <BasicTextInput
                name='atRestWithOxygen'
                maxLength={30}
                label={t('lungFunctionTests.oxygenSaturation.atRestWithOxygen')}
              />
            </Grid>
          </Grid>

          <Grid item md={6} marginTop={5}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            marginTop={5}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};
