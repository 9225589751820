import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  ISelectItem,
  NonPathologicalPersonalHistory,
  parseAlcoholFrequencyValue,
  parseWeeklyFeedingValue,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';
import { useMedicalRecord } from '../useMedicalRecord';
import { useEffect } from 'react';

export interface NonPathologicalRecordFormFields {
  bathFrecuency: string | null;
  toothBrushingFrecuency: string | null;
  weeklyFeeding: ISelectItem | null;
  smokeStatus: string | null;
  smokeStart: string | null;
  smokeYears: string | null;
  tobaccoPerPay: string | null;
  alcoholStatus: string | null;
  alcoholAnswer: ISelectItem | null;
  alcoholStartingAge: string | null;
  drugAddictionStatus: string | null;
  drugAddiction: string | null;

  petStatus: string | null;
  petType: string | null;
  childhoodInfluence: string | null;
  childhoodChickenpox: string | null;
  childhoodMeasles: string | null;
  childhoodHepatitis: string | null;
  childhoodPentavalent: string | null;
  childhoodTetanus: string | null;
  childhoodHPV: string | null;
  childhoodPolio: string | null;
  childhoodBCG: string | null;
  childhoodPneumococcus: string | null;
  childhoodPneumococcusExcersice: string | null;
  childhoodOther: string | null;

  adultHepatitis: string | null;
  adultTetanus: string | null;
  adultInfluence: string | null;
  adultPneumococcus: string | null;
  // adultPneumococcusExcersice: string | null;
  adultOther: string | null;

  tripsStatus: string | null;
  tripsAnswer: string | null;

  exercise: string | null;
}

const keyPrefix = 'general';

const getDefaultValues = (nonPathos: NonPathologicalPersonalHistory | {}) => {
  console.log('nonPathos', nonPathos);
  return {
    bathFrecuency:
      'bathFrecuency' in nonPathos ? nonPathos.bathFrecuency : null,
    toothBrushingFrecuency:
      'toothBrushingFrecuency' in nonPathos
        ? nonPathos.toothBrushingFrecuency
        : null,
    weeklyFeeding:
      'weeklyFeeding' in nonPathos
        ? parseWeeklyFeedingValue(nonPathos.weeklyFeeding)
        : null,
    ...(!!nonPathos && 'smoke' in nonPathos && nonPathos.smoke !== undefined
      ? {
          smokeStatus:
            'status' in nonPathos.smoke && nonPathos.smoke.status !== undefined
              ? nonPathos.smoke.status
                ? 'true'
                : 'false'
              : undefined,
          smokeStart:
            'startDate' in nonPathos.smoke &&
            nonPathos.smoke.startDate !== undefined
              ? nonPathos.smoke.startDate
              : undefined,
          smokeYears:
            'years' in nonPathos.smoke && nonPathos.smoke.years !== undefined
              ? nonPathos.smoke.years.toString()
              : undefined,
          tobaccoPerPay:
            'tobaccoPerPay' in nonPathos.smoke &&
            nonPathos.smoke.tobaccoPerPay !== undefined
              ? nonPathos.smoke.tobaccoPerPay.toString()
              : undefined,
        }
      : {}),
    ...(!!nonPathos && 'alcohol' in nonPathos && nonPathos.alcohol !== undefined
      ? {
          alcoholStatus:
            'status' in nonPathos.alcohol &&
            nonPathos.alcohol.status !== undefined
              ? nonPathos.alcohol.status
                ? 'true'
                : 'false'
              : undefined,
          alcoholAnswer:
            'answer' in nonPathos.alcohol &&
            nonPathos.alcohol.answer !== undefined
              ? parseAlcoholFrequencyValue(nonPathos.alcohol.answer)
              : undefined,
          alcoholStartingAge:
            'startingAge' in nonPathos.alcohol &&
            nonPathos.alcohol.startingAge !== undefined
              ? nonPathos.alcohol.startingAge.toString()
              : undefined,
        }
      : {}),
    ...(!!nonPathos &&
    'drugAddiction' in nonPathos &&
    nonPathos.drugAddiction !== undefined
      ? {
          drugAddictionStatus:
            'status' in nonPathos.drugAddiction &&
            nonPathos.drugAddiction.status !== undefined
              ? nonPathos.drugAddiction.status
                ? 'true'
                : 'false'
              : undefined,
          drugAddiction:
            'answer' in nonPathos.drugAddiction &&
            nonPathos.drugAddiction.answer !== undefined
              ? nonPathos.drugAddiction.answer
              : undefined,
        }
      : {}),
    ...(!!nonPathos && 'petType' in nonPathos && nonPathos.petType !== undefined
      ? {
          petStatus:
            'status' in nonPathos.petType &&
            nonPathos.petType.status !== undefined
              ? nonPathos.petType.status
                ? 'true'
                : 'false'
              : undefined,
          petType:
            'answer' in nonPathos.petType &&
            nonPathos.petType.answer !== undefined
              ? nonPathos.petType.answer
              : undefined,
        }
      : {}),
    childhoodInfluence:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'influence' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.influence !== undefined
        ? nonPathos.immunizations.childhood.influence
          ? 'true'
          : 'false'
        : undefined,

    childhoodChickenpox:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'chickenpox' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.chickenpox !== undefined
        ? nonPathos.immunizations.childhood.chickenpox
          ? 'true'
          : 'false'
        : undefined,
    childhoodMeasles:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'measles' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.measles !== undefined
        ? nonPathos.immunizations.childhood.measles
          ? 'true'
          : 'false'
        : undefined,
    childhoodHepatitis:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'hepatitis' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.hepatitis !== undefined
        ? nonPathos.immunizations.childhood.hepatitis
          ? 'true'
          : 'false'
        : undefined,
    childhoodPentavalent:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'pentavalent' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.pentavalent !== undefined
        ? nonPathos.immunizations.childhood.pentavalent
          ? 'true'
          : 'false'
        : undefined,
    childhoodTetanus:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'tetanus' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.tetanus !== undefined
        ? nonPathos.immunizations.childhood.tetanus
          ? 'true'
          : 'false'
        : undefined,
    childhoodHPV:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'HPV' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.HPV !== undefined
        ? nonPathos.immunizations.childhood.HPV
          ? 'true'
          : 'false'
        : undefined,
    childhoodPolio:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'polio' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.polio !== undefined
        ? nonPathos.immunizations.childhood.polio
          ? 'true'
          : 'false'
        : undefined,
    childhoodBCG:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'BCG' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.BCG !== undefined
        ? nonPathos.immunizations.childhood.BCG
          ? 'true'
          : 'false'
        : undefined,
    childhoodPneumococcus:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'pneumococcus' in nonPathos.immunizations.childhood &&
      nonPathos.immunizations.childhood.pneumococcus !== undefined
        ? nonPathos.immunizations.childhood.pneumococcus
          ? 'true'
          : 'false'
        : undefined,
    childhoodPneumococcusExcersice:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'pneumococcusExcersice' in nonPathos.immunizations.childhood
        ? nonPathos.immunizations.childhood.pneumococcusExcersice
        : undefined,
    childhoodOther:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'childhood' in nonPathos.immunizations &&
      nonPathos.immunizations.childhood !== undefined &&
      'other' in nonPathos.immunizations.childhood
        ? nonPathos.immunizations.childhood.other
        : undefined,

    adultHepatitis:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'adult' in nonPathos.immunizations &&
      nonPathos.immunizations.adult !== undefined &&
      'hepatitisB' in nonPathos.immunizations.adult &&
      nonPathos.immunizations.adult.hepatitisB !== undefined
        ? nonPathos.immunizations.adult.hepatitisB
          ? 'true'
          : 'false'
        : undefined,

    adultTetanus:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'adult' in nonPathos.immunizations &&
      nonPathos.immunizations.adult !== undefined &&
      'tetanus' in nonPathos.immunizations.adult &&
      nonPathos.immunizations.adult.tetanus !== undefined
        ? nonPathos.immunizations.adult.tetanus
          ? 'true'
          : 'false'
        : undefined,
    adultInfluence:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'adult' in nonPathos.immunizations &&
      nonPathos.immunizations.adult !== undefined &&
      'influence' in nonPathos.immunizations.adult &&
      nonPathos.immunizations.adult.influence !== undefined
        ? nonPathos.immunizations.adult.influence
          ? 'true'
          : 'false'
        : undefined,
    adultPneumococcus:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'adult' in nonPathos.immunizations &&
      nonPathos.immunizations.adult !== undefined &&
      'pneumococcus' in nonPathos.immunizations.adult &&
      nonPathos.immunizations.adult.pneumococcus !== undefined
        ? nonPathos.immunizations.adult.pneumococcus
          ? 'true'
          : 'false'
        : undefined,
    // adultPneumococcusExcersice:
    //   !!nonPathos &&
    //   'immunizations' in nonPathos &&
    //   nonPathos.immunizations !== undefined &&
    //   'adult' in nonPathos.immunizations &&
    //   nonPathos.immunizations.adult !== undefined &&
    //   'pneumococcusExcersice' in nonPathos.immunizations.adult
    //     ? nonPathos.immunizations.adult.pneumococcusExcersice
    //     : undefined,
    adultOther:
      !!nonPathos &&
      'immunizations' in nonPathos &&
      nonPathos.immunizations !== undefined &&
      'adult' in nonPathos.immunizations &&
      nonPathos.immunizations.adult !== undefined &&
      'other' in nonPathos.immunizations.adult
        ? nonPathos.immunizations.adult.other
        : undefined,

    ...(!!nonPathos && 'trips' in nonPathos && nonPathos.trips !== undefined
      ? {
          tripsStatus:
            'status' in nonPathos.trips && nonPathos.trips.status !== undefined
              ? nonPathos.trips.status
                ? 'true'
                : 'false'
              : undefined,
          tripsAnswer:
            'answer' in nonPathos.trips && nonPathos.trips.answer !== undefined
              ? nonPathos.trips.answer
              : undefined,
        }
      : {}),
    exercise: 'bathFrecuency' in nonPathos ? nonPathos.exercise : null,
  };
};

export const useNonPathologicalRecordFormManagement = (
  callback: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { getNonPathological, setNonPathological } = useMedicalRecord();
  const nonPathos = getNonPathological();

  const schema = yup.object().shape({
    bathFrecuency: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    toothBrushingFrecuency: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    weeklyFeeding: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    smokeStatus: yup.string().required(t('required')),
    smokeStart: yup.string().when('smokeStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    smokeYears: yup.string().when('smokeStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    tobaccoPerPay: yup.string().when('smokeStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    alcoholStatus: yup.string().required(t('required')),
    alcoholAnswer: yup.object().when('alcoholStatus', {
      is: (val: string | null) => val === 'true',
      then: yup
        .object()
        .shape({
          value: yup.string(),
        })
        .required(t('required'))
        .nullable(),
      otherwise: yup.object().notRequired().nullable(),
    }),
    alcoholStartingAge: yup.string().when('alcoholStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    drugAddictionStatus: yup.string().required(t('required')),
    drugAddiction: yup.string().when('drugAddictionStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    petStatus: yup.string().required(t('required')),
    petType: yup.string().when('petStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    childhoodInfluence: yup.string().required(t('required')),
    childhoodChickenpox: yup.string().required(t('required')),
    childhoodMeasles: yup.string().required(t('required')),
    childhoodHepatitis: yup.string().required(t('required')),
    childhoodPentavalent: yup.string().required(t('required')),
    childhoodTetanus: yup.string().required(t('required')),
    childhoodHPV: yup.string().required(t('required')),
    childhoodPolio: yup.string().required(t('required')),
    childhoodBCG: yup.string().required(t('required')),
    childhoodPneumococcus: yup.string().required(t('required')),
    childhoodPneumococcusExcersice: yup.string().when('childhoodPneumococcus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),
    // childhoodOther: yup.string().required(t('required')),

    adultHepatitis: yup.string().required(t('required')),
    adultTetanus: yup.string().required(t('required')),
    adultInfluence: yup.string().required(t('required')),
    adultPneumococcus: yup.string().required(t('required')),
    // adultPneumococcusExcersice: yup.string().when('adultPneumococcus', {
    //   is: (val: string | null) => val === 'true',
    //   then: yup.string().required(t('required')),
    //   otherwise: yup.string().notRequired(),
    // }),
    // adultOther: yup.string().required(t('required')),
    tripsStatus: yup.string().required(t('required')),
    tripsAnswer: yup.string().when('tripsStatus', {
      is: (val: string | null) => val === 'true',
      then: yup.string().required(t('required')),
      otherwise: yup.string().notRequired(),
    }),

    // exercise: yup.string().typeError(t('required')).required(t('required')),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = useForm<NonPathologicalRecordFormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(nonPathos!),
  });

  console.log('methods.formState.errors', methods.formState.errors);

  useEffect(() => {
    methods.reset(getDefaultValues(nonPathos!));
  }, [nonPathos]);

  const submit: SubmitHandler<NonPathologicalRecordFormFields> = async (
    data
  ) => {
    console.log('submit', data);
    await setNonPathological(data, true);
    callback();
  };

  const preSave = async () => {
    const data = methods.getValues();
    await setNonPathological(data, true);
  };

  return {
    defaultValues: getDefaultValues(nonPathos!),
    methods,
    preSave,
    submit,
  };
};
