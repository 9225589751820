import { months } from 'moment';
import React, { useEffect } from 'react';
import { createContext, useState } from 'react';
import {
  UserInfo,
  MedicalRecord,
  MedicalRecordInfo,
  MemberFamilyHistory,
  useGetMedicalRecord,
  useUpdateMedicalRecordLazy,
  PathologicalPersonalHistory,
  NonPathologicalPersonalHistory,
  PhysicalExploration,
  CurrentCondition,
  RespiratoryApparatus,
} from 'telemed-core';
import {
  CurrentConditionRecordFormFields,
  HistoricalRecordFormFields,
  NonPathologicalRecordFormFields,
  PathologicalRecordFormFields,
  PhysicalExplorationRecordFormFields,
  RespiratoryApparatusRecordFormFields,
} from '../hooks';

type MedicalRecordContextProps = {
  error: string | null;
  loading: boolean;
  medicalRecordInfo: MedicalRecordInfo;
  patientInfo: UserInfo | undefined;
  getMemberHistory: (member: string) => MemberFamilyHistory | undefined;
  getOtherFamilyMember: () => string | undefined;
  getPathological: () => PathologicalPersonalHistory | {} | undefined;
  getNonPathological: () => NonPathologicalPersonalHistory | {} | undefined;
  getPhysicalExploration: () => PhysicalExploration | {} | undefined;
  getRespiratoryApparatus: () => RespiratoryApparatus | {} | undefined;
  getCurrentCondition: () => CurrentCondition | {} | undefined;
  setMemberHistory: (
    member: string,
    data: HistoricalRecordFormFields,
    save: boolean
  ) => void;
  setOtherFamilyMember: (
    otherFamilyMember: string | undefined,
    save: boolean
  ) => void;
  setPathological: (
    pathos: PathologicalRecordFormFields,
    save: boolean
  ) => void;
  setPhysicalExploration: (
    data: PhysicalExplorationRecordFormFields,
    save: boolean
  ) => void;
  setNonPathological: (
    nonPathos: NonPathologicalRecordFormFields,
    save: boolean
  ) => void;
  setRespiratoryApparatus: (
    data: RespiratoryApparatusRecordFormFields,
    save: boolean
  ) => void;
  setCurrentCondition: (
    conditions: CurrentConditionRecordFormFields,
    save: boolean
  ) => void;
};

const defaultProps = {
  error: null,
  loading: false,
  medicalRecordInfo: {
    currentCondition: {},
    heroFamilyHistory: {},
    nonPathologicalPersonalHistory: {},
    pathologicalPersonalHistory: {},
    physicalExploration: {},
    respiratoryApparatus: {},
  },
  patientInfo: undefined,
  getMemberHistory: (member: string) => undefined,
  getOtherFamilyMember: () => undefined,
  getPathological: () => undefined,
  getNonPathological: () => undefined,
  getPhysicalExploration: () => undefined,
  getRespiratoryApparatus: () => undefined,
  getCurrentCondition: () => undefined,
  setMemberHistory: (
    member: string,
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {},
  setOtherFamilyMember: (
    otherFamilyMember: string | undefined,
    save: boolean
  ) => undefined,
  setPathological: (pathos: PathologicalRecordFormFields, save: boolean) => {},
  setNonPathological: (
    nonPathos: NonPathologicalRecordFormFields,
    save: boolean
  ) => {},
  setPhysicalExploration: (
    data: PhysicalExplorationRecordFormFields,
    save: boolean
  ) => {},
  setRespiratoryApparatus: (
    data: RespiratoryApparatusRecordFormFields,
    save: boolean
  ) => {},
  setCurrentCondition: (
    conditions: CurrentConditionRecordFormFields,
    save: boolean
  ) => {},
};

export const MedicalRecordContext =
  createContext<MedicalRecordContextProps>(defaultProps);

type Props = {
  patientId: number;
};

export const MedicalRecordProvider: React.FC<Props> = ({
  children,
  patientId,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [medicalRecordInfo, setMedicalRecordInfo] = useState<MedicalRecordInfo>(
    defaultProps.medicalRecordInfo
  );
  const [patientInfo, setPatientInfo] = useState<UserInfo | undefined>(
    undefined
  );

  const [{ data, loading: dataLoading, error: dataError }] =
    useGetMedicalRecord(patientId);

  const {
    error: upError,
    loading: upLoading,
    axn: update,
  } = useUpdateMedicalRecordLazy(patientId);

  const parseData = ({
    patientId,
    medicalRecordInfo: mriData,
  }: MedicalRecord) => {
    console.log('id', patientId.id);
    console.log('userInfo', patientId.userInfo);
    console.log('mriData', mriData);
    setPatientInfo(patientId.userInfo);
    setMedicalRecordInfo((value) => ({ ...value, ...mriData }));
  };

  useEffect(() => {
    !!data && parseData(data.medicalRecord);
  }, [data]);

  useEffect(() => {
    setError(dataError?.message || null);
    setError(upError || null);
  }, [dataError, upError]);

  useEffect(() => {
    setLoading(dataLoading || upLoading);
  }, [dataLoading, upLoading]);

  const getFatherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'father' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.father &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.father
    )
      return medicalRecordInfo.heroFamilyHistory.father;
    return undefined;
  };

  const getMotherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'mother' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.mother &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.mother
    )
      return medicalRecordInfo.heroFamilyHistory.mother;
    return undefined;
  };

  const getPaternalGrandFatherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'paternalGrandfather' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.paternalGrandfather &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.paternalGrandfather
    )
      return medicalRecordInfo.heroFamilyHistory.paternalGrandfather;
    return undefined;
  };

  const getPaternalGrandmotherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'paternalGrandmother' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.paternalGrandmother &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.paternalGrandmother
    )
      return medicalRecordInfo.heroFamilyHistory.paternalGrandmother;
    return undefined;
  };

  const getMaternalGrandfatherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'maternalGrandfather' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.maternalGrandfather &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.maternalGrandfather
    )
      return medicalRecordInfo.heroFamilyHistory.maternalGrandfather;
    return undefined;
  };

  const getMaternalGrandmotherHistory = (): MemberFamilyHistory | undefined => {
    if (
      !!medicalRecordInfo &&
      'maternalGrandmother' in medicalRecordInfo?.heroFamilyHistory &&
      !!medicalRecordInfo?.heroFamilyHistory.maternalGrandmother &&
      'aliveOrDead' in medicalRecordInfo?.heroFamilyHistory.maternalGrandmother
    )
      return medicalRecordInfo.heroFamilyHistory.maternalGrandmother;
    return undefined;
  };

  const getMemberHistory = (memberName: string) => {
    switch (memberName) {
      case 'father':
        return getFatherHistory();
      case 'mother':
        return getMotherHistory();
      case 'paternalGrandfather':
        return getPaternalGrandFatherHistory();
      case 'paternalGrandmother':
        return getPaternalGrandmotherHistory();
      case 'maternalGrandfather':
        return getMaternalGrandfatherHistory();
      case 'maternalGrandmother':
        return getMaternalGrandmotherHistory();
      default:
        return undefined;
    }
  };

  const getOtherFamilyMember = () => {
    if (
      !!medicalRecordInfo &&
      'otherFamilyMember' in medicalRecordInfo?.heroFamilyHistory
    )
      return medicalRecordInfo.heroFamilyHistory.otherFamilyMember;
    return undefined;
  };

  const getPathological = () => {
    console.log(
      'getPathological.pathologicalPersonalHistory',
      medicalRecordInfo.pathologicalPersonalHistory
    );
    if (!!medicalRecordInfo)
      return medicalRecordInfo.pathologicalPersonalHistory;
    return undefined;
  };

  const getNonPathological = () => {
    if (!!medicalRecordInfo)
      return medicalRecordInfo.nonPathologicalPersonalHistory;
    return undefined;
  };

  const getPhysicalExploration = () => {
    if (!!medicalRecordInfo) return medicalRecordInfo.physicalExploration;
    return undefined;
  };

  const getCurrentCondition = () => {
    if (!!medicalRecordInfo) return medicalRecordInfo.currentCondition;
    return undefined;
  };

  const getRespiratoryApparatus = () => {
    if (!!medicalRecordInfo) return medicalRecordInfo.respiratoryApparatus;
    return undefined;
  };

  const parseMemberFamilyHistoryData = (
    data: HistoricalRecordFormFields
  ): MemberFamilyHistory => {
    console.log('data', data);

    return {
      aliveOrDead: !!data.aliveOrDead ? parseInt(data.aliveOrDead) : undefined,
      diseases: {
        allergies: {
          status: !!data.allergiesStatus
            ? data.allergiesStatus === 'true'
            : undefined,
          years: !!data.allergiesYears
            ? parseInt(data.allergiesYears)
            : undefined,
          months: !!data.allergiesMonths
            ? parseInt(data.allergiesMonths)
            : undefined,
        },
        arterialHypertension: {
          status: data.arterialHypertensionStatus
            ? data.arterialHypertensionStatus === 'true'
            : undefined,
          years: !!data.arterialHypertensionYears
            ? parseInt(data.arterialHypertensionYears)
            : undefined,
          months: !!data.arterialHypertensionMonths
            ? parseInt(data.arterialHypertensionMonths)
            : undefined,
        },
        endocrinological: {
          status: data.endocrinologicalStatus
            ? data.endocrinologicalStatus === 'true'
            : undefined,
          years: !!data.endocrinologicalYears
            ? parseInt(data.endocrinologicalYears)
            : undefined,
          months: !!data.endocrinologicalMonths
            ? parseInt(data.endocrinologicalMonths)
            : undefined,
        },
        heartDisease: {
          status: data.heartDiseaseStatus
            ? data.heartDiseaseStatus === 'true'
            : undefined,
          years: !!data.heartDiseaseYears
            ? parseInt(data.heartDiseaseYears)
            : undefined,
          months: !!data.heartDiseaseMonths
            ? parseInt(data.heartDiseaseMonths)
            : undefined,
        },
        hematologic: {
          status: data.hematologicStatus
            ? data.hematologicStatus === 'true'
            : undefined,
          years: !!data.hematologicYears
            ? parseInt(data.hematologicYears)
            : undefined,
          months: !!data.hematologicMonths
            ? parseInt(data.hematologicMonths)
            : undefined,
        },
        mellitusDiabetes: {
          status: data.mellitusDiabetesStatus
            ? data.mellitusDiabetesStatus === 'true'
            : undefined,
          years: !!data.mellitusDiabetesYears
            ? parseInt(data.mellitusDiabetesYears)
            : undefined,
          months: !!data.mellitusDiabetesMonths
            ? parseInt(data.mellitusDiabetesMonths)
            : undefined,
        },
        // neither: {
        //   status: data.nephropathyStatus,
        //   years: !!data.allergiesYears
        //     ? parseInt(data.allergiesYears)
        //     : undefined,
        // },
        nephropathy: {
          status: data.nephropathyStatus
            ? data.nephropathyStatus === 'true'
            : undefined,
          years: !!data.nephropathyYears
            ? parseInt(data.nephropathyYears)
            : undefined,
          months: !!data.nephropathyMonths
            ? parseInt(data.nephropathyMonths)
            : undefined,
        },
        neurological: {
          status: data.neurologicalStatus
            ? data.neurologicalStatus === 'true'
            : undefined,
          years: !!data.neurologicalYears
            ? parseInt(data.neurologicalYears)
            : undefined,
          months: !!data.neurologicalMonths
            ? parseInt(data.neurologicalMonths)
            : undefined,
        },
        other: {
          status: data.otherStatus ? data.otherStatus === 'true' : undefined,
          name: !!data.otherDesc ? data.otherDesc : undefined,
        },
        pneumological: {
          status: data.pneumologicalStatus
            ? data.pneumologicalStatus === 'true'
            : undefined,
          years: !!data.pneumologicalYears
            ? parseInt(data.pneumologicalYears)
            : undefined,
          months: !!data.pneumologicalMonths
            ? parseInt(data.pneumologicalMonths)
            : undefined,
        },
      },
    };
  };

  const parsePathologicalPersonalHistory = (
    pathos: PathologicalRecordFormFields
  ) => {
    console.log('pathos', pathos);
    return {
      geneticDiseases: pathos.geneticDiseases,
      infectiousDiseasesOfChildhood: pathos.infectiousDiseasesOfChildhood,
      previousSurgeries: pathos.previousSurgeries,
      chronicDiseases: {
        allergies: {
          status: !!pathos.allergiesStatus
            ? pathos.allergiesStatus === 'true'
            : undefined,
          medicine: pathos.allergiesMedicine,
          // startDate: pathos.allergiesDate,
          frequency: pathos.allergiesFrequency,
          years:
            pathos.allergiesYears !== null &&
            pathos.allergiesYears !== undefined &&
            pathos.allergiesYears.length > 0
              ? +pathos.allergiesYears
              : undefined,
          months:
            pathos.allergiesMonths !== null &&
            pathos.allergiesMonths !== undefined &&
            pathos.allergiesMonths.length > 0
              ? +pathos.allergiesMonths
              : undefined,
        },
        heartDisease: {
          status: !!pathos.heartDiseaseStatus
            ? pathos.heartDiseaseStatus === 'true'
            : undefined,
          medicine: pathos.heartDiseaseMedicine,
          // startDate: pathos.heartDiseaseDate,
          frequency: pathos.heartDiseaseFrequency,
          years:
            pathos.heartDiseaseYears !== null &&
            pathos.heartDiseaseYears !== undefined &&
            pathos.heartDiseaseYears.length > 0
              ? +pathos.heartDiseaseYears
              : undefined,
          months:
            pathos.heartDiseaseMonths !== null &&
            pathos.heartDiseaseMonths !== undefined &&
            pathos.heartDiseaseMonths.length > 0
              ? +pathos.heartDiseaseMonths
              : undefined,
        },
        mellitusDiabetes: {
          status: !!pathos.mellitusDiabetesStatus
            ? pathos.mellitusDiabetesStatus === 'true'
            : undefined,
          medicine: pathos.mellitusDiabetesMedicine,
          // startDate: pathos.mellitusDiabetesDate,
          frequency: pathos.mellitusDiabetesFrequency,
          years:
            pathos.mellitusDiabetesYears !== null &&
            pathos.mellitusDiabetesYears !== undefined &&
            pathos.mellitusDiabetesYears.length > 0
              ? +pathos.mellitusDiabetesYears
              : undefined,
          months:
            pathos.mellitusDiabetesMonths !== null &&
            pathos.mellitusDiabetesMonths !== undefined &&
            pathos.mellitusDiabetesMonths.length > 0
              ? +pathos.mellitusDiabetesMonths
              : undefined,
        },
        endocrinological: {
          status: !!pathos.endocrinologicalStatus
            ? pathos.endocrinologicalStatus === 'true'
            : undefined,
          medicine: pathos.endocrinologicalMedicine,
          // startDate: pathos.endocrinologicalDate,
          frequency: pathos.endocrinologicalFrequency,
          years:
            pathos.endocrinologicalYears !== null &&
            pathos.endocrinologicalYears !== undefined &&
            pathos.endocrinologicalYears.length > 0
              ? +pathos.endocrinologicalYears
              : undefined,
          months:
            pathos.endocrinologicalMonths !== null &&
            pathos.endocrinologicalMonths !== undefined &&
            pathos.endocrinologicalMonths.length > 0
              ? +pathos.endocrinologicalMonths
              : undefined,
        },
        hematologic: {
          status: !!pathos.hematologicStatus
            ? pathos.hematologicStatus === 'true'
            : undefined,
          medicine: pathos.hematologicMedicine,
          // startDate: pathos.hematologicDate,
          frequency: pathos.hematologicFrequency,
          years:
            pathos.hematologicYears !== null &&
            pathos.hematologicYears !== undefined &&
            pathos.hematologicYears.length > 0
              ? +pathos.hematologicYears
              : undefined,
          months:
            pathos.hematologicMonths !== null &&
            pathos.hematologicMonths !== undefined &&
            pathos.hematologicMonths.length > 0
              ? +pathos.hematologicMonths
              : undefined,
        },
        arterialHypertension: {
          status: !!pathos.arterialHypertensionStatus
            ? pathos.arterialHypertensionStatus === 'true'
            : undefined,
          medicine: pathos.arterialHypertensionMedicine,
          // startDate: pathos.arterialHypertensionDate,
          frequency: pathos.arterialHypertensionFrequency,
          years:
            pathos.arterialHypertensionYears !== null &&
            pathos.arterialHypertensionYears !== undefined &&
            pathos.arterialHypertensionYears.length > 0
              ? +pathos.arterialHypertensionYears
              : undefined,
          months:
            pathos.arterialHypertensionMonths !== null &&
            pathos.arterialHypertensionMonths !== undefined &&
            pathos.arterialHypertensionMonths.length > 0
              ? +pathos.arterialHypertensionMonths
              : undefined,
        },
        nephropathy: {
          status: !!pathos.nephropathyStatus
            ? pathos.nephropathyStatus === 'true'
            : undefined,
          medicine: pathos.nephropathyMedicine,
          // startDate: pathos.nephropathyDate,
          frequency: pathos.nephropathyFrequency,
          years:
            pathos.nephropathyYears !== null &&
            pathos.nephropathyYears !== undefined &&
            pathos.nephropathyYears.length > 0
              ? +pathos.nephropathyYears
              : undefined,
          months:
            pathos.nephropathyMonths !== null &&
            pathos.nephropathyMonths !== undefined &&
            pathos.nephropathyMonths.length > 0
              ? +pathos.nephropathyMonths
              : undefined,
        },
        pneumological: {
          status: !!pathos.pneumologicalStatus
            ? pathos.pneumologicalStatus === 'true'
            : undefined,
          medicine: pathos.pneumologicalMedicine,
          // startDate: pathos.pneumologicalDate,
          frequency: pathos.pneumologicalFrequency,
          years:
            pathos.pneumologicalYears !== null &&
            pathos.pneumologicalYears !== undefined &&
            pathos.pneumologicalYears.length > 0
              ? +pathos.pneumologicalYears
              : undefined,
          months:
            pathos.pneumologicalMonths !== null &&
            pathos.pneumologicalMonths !== undefined &&
            pathos.pneumologicalMonths.length > 0
              ? +pathos.pneumologicalMonths
              : undefined,
        },
        neurological: {
          status: !!pathos.neurologicalStatus
            ? pathos.neurologicalStatus === 'true'
            : undefined,
          medicine: pathos.neurologicalMedicine,
          // startDate: pathos.neurologicalDate,
          frequency: pathos.neurologicalFrequency,
          years:
            pathos.neurologicalYears !== null &&
            pathos.neurologicalYears !== undefined &&
            pathos.neurologicalYears.length > 0
              ? +pathos.neurologicalYears
              : undefined,
          months:
            pathos.neurologicalMonths !== null &&
            pathos.neurologicalMonths !== undefined &&
            pathos.neurologicalMonths.length > 0
              ? +pathos.neurologicalMonths
              : undefined,
        },
        other: {
          status: !!pathos.otherStatus
            ? pathos.otherStatus === 'true'
            : undefined,
          medicine: pathos.otherMedicine,
          // startDate: pathos.otherDate,
          frequency: pathos.otherFrequency,
          years:
            pathos.otherYears !== null &&
            pathos.otherYears !== undefined &&
            pathos.otherYears.length > 0
              ? +pathos.otherYears
              : undefined,
          months:
            pathos.otherMonths !== null &&
            pathos.otherMonths !== undefined &&
            pathos.otherMonths.length > 0
              ? +pathos.otherMonths
              : undefined,
        },
      },
    };
  };

  const parseNonPathologicalPersonalHistory = (
    nonPathos: NonPathologicalRecordFormFields
  ) => {
    console.log('pathos', nonPathos);
    return {
      bathFrecuency: nonPathos.bathFrecuency,
      toothBrushingFrecuency: nonPathos.toothBrushingFrecuency,
      weeklyFeeding: nonPathos.weeklyFeeding?.value,
      smoke: {
        status: !!nonPathos.smokeStatus
          ? nonPathos.smokeStatus === 'true'
          : undefined,
        startDate: nonPathos.smokeStart,
        tobaccoPerPay: !!nonPathos.tobaccoPerPay
          ? parseInt(nonPathos.tobaccoPerPay)
          : undefined,
        years: !!nonPathos.smokeYears
          ? parseInt(nonPathos.smokeYears)
          : undefined,
      },
      alcohol: {
        status: !!nonPathos.alcoholStatus
          ? nonPathos.alcoholStatus === 'true'
          : undefined,
        answer: nonPathos.alcoholAnswer?.value
          ? parseInt(nonPathos.alcoholAnswer.value)
          : undefined,
        startingAge: !!nonPathos.alcoholStartingAge
          ? parseInt(nonPathos.alcoholStartingAge)
          : undefined,
      },
      drugAddiction: {
        status: !!nonPathos.drugAddictionStatus
          ? nonPathos.drugAddictionStatus === 'true'
          : undefined,
        answer: nonPathos.drugAddiction,
      },
      petType: {
        status: !!nonPathos.petStatus
          ? nonPathos.petStatus === 'true'
          : undefined,
        answer: nonPathos.petType,
      },
      immunizations: {
        childhood: {
          BCG: !!nonPathos.childhoodBCG
            ? nonPathos.childhoodBCG === 'true'
            : undefined,
          HPV: !!nonPathos.childhoodHPV
            ? nonPathos.childhoodHPV === 'true'
            : undefined,
          chickenpox: !!nonPathos.childhoodChickenpox
            ? nonPathos.childhoodChickenpox === 'true'
            : undefined,
          hepatitis: !!nonPathos.childhoodHepatitis
            ? nonPathos.childhoodHepatitis === 'true'
            : undefined,
          influence: !!nonPathos.childhoodInfluence
            ? nonPathos.childhoodInfluence === 'true'
            : undefined,
          measles: !!nonPathos.childhoodMeasles
            ? nonPathos.childhoodMeasles === 'true'
            : undefined,
          other: nonPathos.childhoodOther,
          pentavalent: !!nonPathos.childhoodPentavalent
            ? nonPathos.childhoodPentavalent === 'true'
            : undefined,
          pneumococcus: !!nonPathos.childhoodPneumococcus
            ? nonPathos.childhoodPneumococcus === 'true'
            : undefined,
          pneumococcusExcersice: nonPathos.childhoodPneumococcusExcersice,
          polio: !!nonPathos.childhoodPolio
            ? nonPathos.childhoodPolio === 'true'
            : undefined,
          tetanus: !!nonPathos.childhoodTetanus
            ? nonPathos.childhoodTetanus === 'true'
            : undefined,
        },
        adult: {
          hepatitisB: !!nonPathos.adultHepatitis
            ? nonPathos.adultHepatitis === 'true'
            : undefined,
          influence: !!nonPathos.adultInfluence
            ? nonPathos.adultInfluence === 'true'
            : undefined,
          other: nonPathos.adultOther,
          pneumococcus: !!nonPathos.adultPneumococcus
            ? nonPathos.adultPneumococcus === 'true'
            : undefined,
          // pneumococcusExcersice: nonPathos.adultPneumococcusExcersice,
          tetanus: !!nonPathos.adultHepatitis
            ? nonPathos.adultHepatitis === 'true'
            : undefined,
        },
      },
      trips: {
        status: !!nonPathos.tripsStatus
          ? nonPathos.tripsStatus === 'true'
          : undefined,
        answer: nonPathos.tripsAnswer,
      },
      exercise: nonPathos.exercise,
    };
  };

  const parseRespiratoryApparatus = (
    data: RespiratoryApparatusRecordFormFields
  ) => {
    console.log('data', data);

    return {
      asthma: {
        years:
          data.asthmaYears !== null &&
          data.asthmaYears !== undefined &&
          data.asthmaYears.length > 0
            ? +data.asthmaYears
            : undefined,
        months:
          data.asthmaMonths !== null &&
          data.asthmaMonths !== undefined &&
          data.asthmaMonths.length > 0
            ? +data.asthmaMonths
            : undefined,
        medicament: data.asthmaMedicament,
      },
      cancer: {
        years:
          data.cancerYears !== null &&
          data.cancerYears !== undefined &&
          data.cancerYears.length > 0
            ? +data.cancerYears
            : undefined,
        months:
          data.cancerMonths !== null &&
          data.cancerMonths !== undefined &&
          data.cancerMonths.length > 0
            ? +data.cancerMonths
            : undefined,
        medicament: data.cancerMedicament,
        typeOfCancer: data.typeOfCancer,
      },
      chronicBronchitis: {
        years:
          data.chronicBronchitisYears !== null &&
          data.chronicBronchitisYears !== undefined &&
          data.chronicBronchitisYears.length > 0
            ? +data.chronicBronchitisYears
            : undefined,
        months:
          data.chronicBronchitisMonths !== null &&
          data.chronicBronchitisMonths !== undefined &&
          data.chronicBronchitisMonths.length > 0
            ? +data.chronicBronchitisMonths
            : undefined,
        medicament: data.chronicBronchitisMedicament,
      },
      emphysema: {
        years:
          data.emphysemaYears !== null &&
          data.emphysemaYears !== undefined &&
          data.emphysemaYears.length > 0
            ? +data.emphysemaYears
            : undefined,
        months:
          data.emphysemaMonths !== null &&
          data.emphysemaMonths !== undefined &&
          data.emphysemaMonths.length > 0
            ? +data.emphysemaMonths
            : undefined,
        medicament: data.emphysemaMedicament,
      },
      medicinesInfo: {
        name: data.medicinesInfoName,
        dose: data.medicinesInfoDose,
        via: !!data.medicinesInfoVia?.value
          ? +data.medicinesInfoVia.value
          : undefined,
        each: !!data.medicinesInfoEach?.value
          ? +data.medicinesInfoEach.value
          : undefined,
      },
      pulmonaryPhysiotherapyTreatments: {
        percussionSpan: data.percussionSpan === 'true',
        vibration: data.vibration === 'true',
        IPV: data.IPV === 'true',
        strikerVest: data.strikerVest === 'true',
        assistedCough: data.assistedCough === 'true',
      },
      respiratoryInfections: {
        years:
          data.respiratoryInfectionsYears !== null &&
          data.respiratoryInfectionsYears !== undefined &&
          data.respiratoryInfectionsYears.length > 0
            ? +data.respiratoryInfectionsYears
            : undefined,
        months:
          data.respiratoryInfectionsMonths !== null &&
          data.respiratoryInfectionsMonths !== undefined &&
          data.respiratoryInfectionsMonths.length > 0
            ? +data.respiratoryInfectionsMonths
            : undefined,
        medicament: data.respiratoryInfectionsMedicament,
      },
      smokingIndex: {
        yearsSmoking:
          data.yearsSmoking !== null &&
          data.yearsSmoking !== undefined &&
          data.yearsSmoking.length > 0
            ? +data.yearsSmoking
            : undefined,
        yearsWithoutSmoking:
          data.yearsWithoutSmoking !== null &&
          data.yearsWithoutSmoking !== undefined &&
          data.yearsWithoutSmoking.length > 0
            ? +data.yearsWithoutSmoking
            : undefined,
      },
      surgeries: {
        years:
          data.surgeriesYears !== null &&
          data.surgeriesYears !== undefined &&
          data.surgeriesYears.length > 0
            ? +data.surgeriesYears
            : undefined,
        months:
          data.surgeriesMonths !== null &&
          data.surgeriesMonths !== undefined &&
          data.surgeriesMonths.length > 0
            ? +data.surgeriesMonths
            : undefined,
        medicament: data.cancerMedicament,
        typeOfSurgery: data.typeOfSurgery,
      },
    };
  };

  const parseCurrentCondition = (
    conditions: CurrentConditionRecordFormFields
  ) => {
    return {
      currentCondition: {
        name: conditions.currentConditionName,
        description: conditions.currentConditionDescription,
      },
      diagnosisOfTheTreatingPhysician: {
        name: conditions.diagnosisOfTheTreatingPhysicianName,
        description: conditions.diagnosisOfTheTreatingPhysicianDescription,
      },
      addedDiagnostics: {
        name: conditions.addedDiagnosticsName,
        description: conditions.addedDiagnosticsDescription,
      },
    };
  };

  const parsePhysicalExploration = (
    data: PhysicalExplorationRecordFormFields
  ) => {
    console.log(data);
    return {
      eyes: !!data.eyes?.value ? +data.eyes.value : undefined,
      mouth: !!data.mouth?.value ? +data.mouth.value : undefined,
      breathingType: !!data.breathingType?.value
        ? +data.breathingType.value
        : undefined,
      weight: data.weight,
      size: data.size,
      height: data.height,
      patientAspects: {
        hydration: !!data.hydration ? data.hydration === 'true' : undefined,
        awake: !!data.awake ? data.awake === 'true' : undefined,
        cooperativeConscious: !!data.cooperativeConscious
          ? data.cooperativeConscious === 'true'
          : undefined,
        clouded: !!data.clouded ? data.clouded === 'true' : undefined,
        unconscious: !!data.unconscious
          ? data.unconscious === 'true'
          : undefined,
        quiet: !!data.quiet ? data.quiet === 'true' : undefined,
        depressed: !!data.depressed ? data.depressed === 'true' : undefined,
      },
      cyanosis: {
        status: !!data.cyanosisStatus
          ? data.cyanosisStatus === 'true'
          : undefined,
        answer: !!data.cyanosis?.value ? +data.cyanosis.value : undefined,
      },
      otherRelevantData: data.otherRelevantData,
      lastSixMonthsWeightChange: {
        status: !!data.lastSixMonthsWeightChangeStatus
          ? data.lastSixMonthsWeightChangeStatus === 'true'
          : undefined,
        intentional: !!data.intentional
          ? data.intentional === 'false'
          : undefined,
        gain: data.gain !== null ? +data.gain : undefined,
        lost: data.lost !== null ? +data.lost : undefined,
      },
      useAccessoryMuscles: {
        status: !!data.useAccessoryMusclesStatus
          ? data.useAccessoryMusclesStatus === 'true'
          : undefined,
        nasalElevator: !!data.nasalElevator
          ? data.nasalElevator === 'true'
          : undefined,
        sternocleidomastoid: !!data.sternocleidomastoid
          ? data.sternocleidomastoid === 'true'
          : undefined,
        previousScalene: !!data.previousScalene
          ? data.previousScalene === 'true'
          : undefined,
        middleAndLater: !!data.middleAndLater
          ? data.middleAndLater === 'true'
          : undefined,
        externalIntercostals: !!data.externalIntercostals
          ? data.externalIntercostals === 'true'
          : undefined,
        pectoralisMajorMinor: !!data.pectoralisMajorMinor
          ? data.pectoralisMajorMinor === 'true'
          : undefined,
      },
      respiratoryMusclesAssessment: {
        status: !!data.respiratoryMusclesAssessmentStatus
          ? data.respiratoryMusclesAssessmentStatus === 'true'
          : undefined,
        inspiratory: {
          trapezoids: !!data.trapezoids
            ? data.trapezoids === 'true'
            : undefined,
          scalenes: !!data.scalenes ? data.scalenes === 'true' : undefined,
          pectorals: !!data.pectorals ? data.pectorals === 'true' : undefined,
          sternocleidomastoid: !!data.rmaSternocleidomastoid
            ? data.rmaSternocleidomastoid === 'true'
            : undefined,
          externalIntercostals: !!data.rmaExternalIntercostals
            ? data.rmaExternalIntercostals === 'true'
            : undefined,
          diaphragm: !!data.diaphragm ? data.diaphragm === 'true' : undefined,
        },
        expiratory: {
          serratos: !!data.serratos ? data.serratos === 'true' : undefined,
          oblique: !!data.serratos ? data.oblique === 'true' : undefined,
          rectusAbdominis: !!data.rectusAbdominis
            ? data.rectusAbdominis === 'true'
            : undefined,
          transversusAbdominis: !!data.transversusAbdominis
            ? data.transversusAbdominis === 'true'
            : undefined,
          internalIntercostals: !!data.internalIntercostals
            ? data.internalIntercostals === 'true'
            : undefined,
        },
      },
      auscultation: {
        // status: true,
        rales: {
          alveolars: !!data.alveolars ? data.alveolars === 'true' : undefined,
          bronchialAlveolar: !!data.bronchialAlveolar
            ? data.bronchialAlveolar === 'true'
            : undefined,
          bronchial: !!data.bronchial ? data.bronchial === 'true' : undefined,
        },
        wheezing: !!data.wheezing ? data.wheezing === 'true' : undefined,
        condensationSyndrome: !!data.condensationSyndrome
          ? data.condensationSyndrome === 'true'
          : undefined,
      },
      currentSymptoms: {
        cough: {
          status: !!data.coughStatus ? data.coughStatus === 'true' : undefined,
          uponAwakening: !!data.uponAwakening
            ? data.uponAwakening === 'true'
            : undefined,
          duringTheDay: !!data.duringTheDay
            ? data.duringTheDay === 'true'
            : undefined,
          atNight: !!data.atNight ? data.atNight === 'true' : undefined,
          continuous: !!data.continuous
            ? data.continuous === 'true'
            : undefined,
        },
        expectoration: {
          status: !!data.expectorationStatus
            ? data.expectorationStatus === 'true'
            : undefined,
          quantity: !!data.quantity?.value ? +data.quantity.value : undefined,
          time: !!data.time?.value ? +data.time.value : undefined,
          color: !!data.color?.value ? +data.color.value : undefined,
          consistencies: data.consistencies,
          handling: !!data.handling?.value ? +data.handling.value : undefined,
        },
        fever: {
          status: !!data.feverStatus ? data.feverStatus === 'true' : undefined,
          answer: !!data.feverAnswer ? +data.feverAnswer : undefined,
        },
      },
      borgScale: {
        repose: !!data.borgScaleRepose?.value
          ? +data.borgScaleRepose.value
          : undefined,
        talk: !!data.borgScaleTalk?.value
          ? +data.borgScaleTalk.value
          : undefined,
        eat: !!data.borgScaleEat?.value ? +data.borgScaleEat.value : undefined,
        bath: !!data.borgScaleBath?.value
          ? +data.borgScaleBath.value
          : undefined,
        walk: !!data.borgScaleWalk?.value
          ? +data.borgScaleWalk.value
          : undefined,
      },
      exerciseCapacity: {
        hike: data.hike,
        other: data.exerciseCapacityOther,
      },
      lungFunctionTests: {
        vitalCapacity: data.vitalCapacity,
        breathingFrequency: data.breathingFrequency,
        heartRate: data.heartRate,
        bloodPressure: data.bloodPressure,
        oxygenSaturation: {
          atRestInAmbientAir: data.atRestInAmbientAir,
          atRestWithOxygen: data.atRestWithOxygen,
          activeInAmbientAir: data.activeInAmbientAir,
          activeWithOxygen: data.activeWithOxygen,
        },
      },
    };
  };

  const setFatherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const father = parseMemberFamilyHistoryData(data);
    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        father: { ...father },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setMotherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const mother = parseMemberFamilyHistoryData(data);
    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        mother: { ...mother },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setPaternalGrandfatherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const paternalGrandfather = parseMemberFamilyHistoryData(data);
    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        paternalGrandfather: { ...paternalGrandfather },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setPaternalGrandmotherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const paternalGrandmother = parseMemberFamilyHistoryData(data);
    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        paternalGrandmother: { ...paternalGrandmother },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setMaternalGrandfatherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const maternalGrandfather = parseMemberFamilyHistoryData(data);

    console.log('maternalGrandfather', maternalGrandfather);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        maternalGrandfather: { ...maternalGrandfather },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);

    console.log('newMedicalRecordInfo', newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setMaternalGrandmotherHistory = async (
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    const maternalGrandmother = parseMemberFamilyHistoryData(data);

    console.log('maternalGrandmother', maternalGrandmother);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        maternalGrandmother: { ...maternalGrandmother },
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setMemberHistory = async (
    memberName: string,
    data: HistoricalRecordFormFields,
    save: boolean
  ) => {
    switch (memberName) {
      case 'father':
        return await setFatherHistory(data, save);
      case 'mother':
        return await setMotherHistory(data, save);
      case 'paternalGrandfather':
        return await setPaternalGrandfatherHistory(data, save);
      case 'paternalGrandmother':
        return await setPaternalGrandmotherHistory(data, save);
      case 'maternalGrandfather':
        return await setMaternalGrandfatherHistory(data, save);
      case 'maternalGrandmother':
        return await setMaternalGrandmotherHistory(data, save);
      default:
        setError('Member not found');
    }
  };

  const setOtherFamilyMember = async (
    otherFamilyMember: string | undefined,
    save: boolean
  ) => {
    console.log('setOtherFamilyMember', otherFamilyMember);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      heroFamilyHistory: {
        ...medicalRecordInfo.heroFamilyHistory,
        otherFamilyMember,
      },
    };
    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setPathological = async (
    pathos: PathologicalRecordFormFields,
    save: boolean
  ) => {
    const pathologicalPersonalHistory =
      parsePathologicalPersonalHistory(pathos);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      pathologicalPersonalHistory,
    };

    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setNonPathological = async (
    nonPathos: NonPathologicalRecordFormFields,
    save: boolean
  ) => {
    const nonPathologicalPersonalHistory =
      parseNonPathologicalPersonalHistory(nonPathos);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      nonPathologicalPersonalHistory,
    };

    console.log('newMedicalRecordInfo', newMedicalRecordInfo);

    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setPhysicalExploration = async (
    data: PhysicalExplorationRecordFormFields,
    save: boolean
  ) => {
    const physicalExploration = parsePhysicalExploration(data);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      physicalExploration,
    };

    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setRespiratoryApparatus = async (
    data: RespiratoryApparatusRecordFormFields,
    save: boolean
  ) => {
    const respiratoryApparatus = parseRespiratoryApparatus(data);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      respiratoryApparatus,
    };

    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  const setCurrentCondition = async (
    conditions: CurrentConditionRecordFormFields,
    save: boolean
  ) => {
    const currentCondition = parseCurrentCondition(conditions);

    const newMedicalRecordInfo = {
      ...medicalRecordInfo,
      currentCondition,
    };

    setMedicalRecordInfo(newMedicalRecordInfo);
    save && (await update(newMedicalRecordInfo));
  };

  return (
    <MedicalRecordContext.Provider
      value={{
        error,
        loading,
        medicalRecordInfo,
        patientInfo,
        getMemberHistory,
        getOtherFamilyMember,
        getPathological,
        getNonPathological,
        getPhysicalExploration,
        getRespiratoryApparatus,
        getCurrentCondition,
        setMemberHistory,
        setOtherFamilyMember,
        setPathological,
        setNonPathological,
        setPhysicalExploration,
        setRespiratoryApparatus,
        setCurrentCondition,
      }}
    >
      {children}
    </MedicalRecordContext.Provider>
  );
};
