import React from 'react';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { Layout } from 'telemed-core';

import { UpdatePasswordForm } from '../forms';
import { DoctorForm } from '../forms/DoctorForm';

// const keyPrefix = 'containers.ConfigContainer';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'grid',
    maxWidth: '450px',
    color: theme.palette.grey[300],
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyItems: 'center',
    rowGap: theme.spacing(2),
    '& button': {
      marginTop: theme.spacing(3),
    },
    '& .new-password': {
      width: '100%',
      display: 'grid',
      rowGap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  doctorForm: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    columnGap: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

export const ConfigContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  // const { t } = useTranslation('translation', { keyPrefix });

  const classes = useStyles();

  return (
    <Layout title=''>
      <Container
        maxWidth='lg'
        sx={{
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <DoctorForm className={classes.doctorForm} />
        <Divider />
        <UpdatePasswordForm className={classes.form} showMessage={true} />
      </Container>
    </Layout>
  );
};
