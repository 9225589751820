import { createContext, useCallback, useState } from 'react';
import { Alarm, Consultation, useAllConsultationLazy } from 'telemed-core';
import moment from 'moment';

type AlarmsContextProps = {
  alarms: Alarm[];
  areNotificationDenied: boolean;
  addAlarm: (consultation: Consultation) => void;
  fetchAlarms: () => void;
  onDeniedNotification: () => void;
  replaceAlarms: (consultation: Consultation) => void;
};

const defaultProps = {
  alarms: [],
  areNotificationDenied: false,
  addAlarm: (consultation: Consultation) => {},
  fetchAlarms: () => {},
  onDeniedNotification: () => {},
  replaceAlarms: (consultation: Consultation) => {},
};

export const AlarmsContext = createContext<AlarmsContextProps>(defaultProps);

type Props = {};

const extractAlarms = ({
  consultationDate,
  id: consultationId,
  referenceDate,
  user,
}: Consultation): Alarm[] => {
  const extractedAlarms: Alarm[] = [];

  const localDate = moment.utc(consultationDate).local();
  const timeToShowAlarm = localDate.diff(moment(), 'milliseconds');

  if (timeToShowAlarm <= 0) return [];

  const { name, firstLastName, secondLastName } = user.userInfo;
  const patientName = `${name} ${firstLastName} ${secondLastName}`;
  const formattedDate = localDate.format('DD-MM-YYYY - h:mm a');

  extractedAlarms.push({
    consultationId,
    dateId: 0,
    date: formattedDate,
    isReminder: false,
    patientName,
    timeToShowAlarm,
  });

  !!referenceDate &&
    referenceDate.dates.forEach(({ date, id: dateId }) => {
      // if (consultationDate === date) return; // Use it for ignore alarms setting for show at consultation time

      const timeToShowAlarm = moment
        .utc(date)
        .local()
        .diff(moment(), 'milliseconds');

      if (timeToShowAlarm <= 0) return;

      extractedAlarms.push({
        consultationId,
        dateId,
        date: formattedDate,
        isReminder: true,
        patientName,
        timeToShowAlarm,
      });
    });

  return extractedAlarms;
};

export const AlarmsProvider: React.FC<Props> = ({ children }) => {
  const [alarms, setAlarms] = useState<Alarm[]>([]);
  const [areNotificationDenied, setAreNotificationsDenied] =
    useState<boolean>(false);
  const { getAll } = useAllConsultationLazy();

  const parseConsultations = (list: Consultation[]) => {
    const newAlarms: Alarm[] = [];

    list.forEach((consultation) => {
      const extractedAlarms = extractAlarms(consultation);
      newAlarms.push(...extractedAlarms);
    });

    setAlarms(newAlarms);
  };

  const fetchAlarms = useCallback(async () => {
    const { actual } = await getAll();
    if (!actual) return;
    parseConsultations(actual);
  }, []);

  const addAlarm = (consultation: Consultation) => {
    const extractedAlarms = extractAlarms(consultation);
    setAlarms((alarms) => [...alarms, ...extractedAlarms]);
  };

  const replaceAlarms = (consultation: Consultation) => {
    const extractedAlarms = extractAlarms(consultation);
    if (extractedAlarms.length === 0) return;

    const { id } = consultation;

    const filteredAlarms = alarms.filter(
      ({ consultationId }) => consultationId !== id
    );

    setAlarms([...filteredAlarms, ...extractedAlarms]);
  };

  const onDeniedNotification = () => {
    setAreNotificationsDenied(true);
  };

  return (
    <AlarmsContext.Provider
      value={{
        alarms,
        areNotificationDenied,
        addAlarm,
        fetchAlarms,
        onDeniedNotification,
        replaceAlarms,
      }}
    >
      {children}
    </AlarmsContext.Provider>
  );
};
