import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { User, Layout, AddButton } from 'telemed-core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import {
  ConsultationHistoryList,
  MedicalRecord,
  TreatmentsList,
} from '../../components';
import { AddConsultationDialog } from '../../dialogs';
import { MedicalRecordProvider } from '../../contexts';

export const PatientDetailContainer = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [openDialog, setOpenDialog] = useState(false);

  const state = location?.state as { key: string; user: User };

  useEffect(() => {
    // !state && navigate('/patients');
    if (!state) window.location.href = `/patients`;
  }, [state]);

  const {
    user: {
      id: patientId,
      userInfo: { name, firstLastName, secondLastName },
    },
  } = state;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleAddClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (needsRefresh?: boolean) => {
    setOpenDialog(false);
  };

  return (
    <Layout title={`${name} ${firstLastName} ${secondLastName}`}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: (theme) => theme.spacing(3),
          }}
        >
          <Button
            variant='text'
            color='secondary'
            onClick={async () => {
              window.location.href = `/patients`;
              // await navigate('/patients');
            }}
          >
            Ver todos tus pacientes
          </Button>
          <AddButton label='Agendar Nueva Consulta' onClick={handleAddClick} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <MedicalRecordProvider patientId={patientId}>
            <TabContext value={value}>
              <TabList onChange={handleChange} centered>
                <Tab label='Tratamientos' value='1' />
                <Tab label='Expediente Médico' value='2' />
                <Tab label='Historial de Consultas' value='3' />
              </TabList>
              <TabPanel value='1'>
                <TreatmentsList patientId={patientId} />
              </TabPanel>
              <TabPanel value='2'>
                <MedicalRecord patientId={patientId} />
              </TabPanel>
              <TabPanel value='3'>
                <ConsultationHistoryList patientId={patientId} />
              </TabPanel>
            </TabContext>
          </MedicalRecordProvider>
        </Box>
      </Container>
      <AddConsultationDialog
        nameText={`${name} ${firstLastName} ${secondLastName}`}
        open={openDialog}
        patient={state.user}
        handleClose={handleCloseDialog}
      />
    </Layout>
  );
};
