import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ChronicleDiseaseInput,
  Loading,
  Modal,
  BasicTextInput,
} from 'telemed-core';

import {
  usePathologicalRecordFormManagement,
  useMedicalRecord,
} from '../hooks';

type Props = {
  onNext: () => void;
  onBack: () => void;
};

const keyPrefix = 'forms.PathologicalRecordForm';

export const PathologicalRecordForm: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ className, onBack, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { error, loading } = useMedicalRecord();

  const { defaultValues, methods, preSave, submit } =
    usePathologicalRecordFormManagement(onNext);

  const handleBack = async () => {
    await preSave();
    onBack();
  };

  if (loading) return <Loading />;

  console.log('defaultValues', defaultValues);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Grid container spacing={3} marginTop={5} sx={{ width: '100%' }}>
          <Grid item md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput
              name='geneticDiseases'
              maxLength={100}
              minRows={3}
              label={t('geneticDiseases.label')}
              placeholder={t('geneticDiseases.placeholder')}
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput
              name='infectiousDiseasesOfChildhood'
              maxLength={100}
              minRows={3}
              label={t('infectiousDiseasesOfChildhood.label')}
              placeholder={t('infectiousDiseasesOfChildhood.placeholder')}
            />
          </Grid>
          <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <BasicTextInput
              name='previousSurgeries'
              maxLength={100}
              minRows={1}
              label={t('previousSurgeries.label')}
              placeholder={t('previousSurgeries.placeholder')}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Alergias' }}
              names={{
                status: 'allergiesStatus',
                medicine: 'allergiesMedicine',
                date: 'allergiesDate',
                frequency: 'allergiesFrequency',
                years: 'allergiesYears',
                months: 'allergiesMonths',
              }}
              showTextBoxes={defaultValues.allergiesStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Cardiopatía' }}
              names={{
                status: 'heartDiseaseStatus',
                medicine: 'heartDiseaseMedicine',
                date: 'heartDiseaseDate',
                frequency: 'heartDiseaseFrequency',
                years: 'heartDiseaseYears',
                months: 'heartDiseaseMonths',
              }}
              showTextBoxes={defaultValues.heartDiseaseStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Diabetes mellitus' }}
              names={{
                status: 'mellitusDiabetesStatus',
                medicine: 'mellitusDiabetesMedicine',
                date: 'mellitusDiabetesDate',
                frequency: 'mellitusDiabetesFrequency',
                years: 'mellitusDiabetesYears',
                months: 'mellitusDiabetesMonths',
              }}
              showTextBoxes={defaultValues.mellitusDiabetesStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Endocrinológica' }}
              names={{
                status: 'endocrinologicalStatus',
                medicine: 'endocrinologicalMedicine',
                date: 'endocrinologicalDate',
                frequency: 'endocrinologicalFrequency',
                years: 'endocrinologicalYears',
                months: 'endocrinologicalMonths',
              }}
              showTextBoxes={defaultValues.endocrinologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Hematológica' }}
              names={{
                status: 'hematologicStatus',
                medicine: 'hematologicMedicine',
                date: 'hematologicDate',
                frequency: 'hematologicFrequency',
                years: 'hematologicYears',
                months: 'hematologicMonths',
              }}
              showTextBoxes={defaultValues.hematologicStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Hipertensión arterial' }}
              names={{
                status: 'arterialHypertensionStatus',
                medicine: 'arterialHypertensionMedicine',
                date: 'arterialHypertensionDate',
                frequency: 'arterialHypertensionFrequency',
                years: 'arterialHypertensionYears',
                months: 'arterialHypertensionMonths',
              }}
              showTextBoxes={
                defaultValues.arterialHypertensionStatus === 'true'
              }
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Nefropatía' }}
              names={{
                status: 'nephropathyStatus',
                medicine: 'nephropathyMedicine',
                date: 'nephropathyDate',
                frequency: 'nephropathyFrequency',
                years: 'nephropathyYears',
                months: 'nephropathyMonths',
              }}
              showTextBoxes={defaultValues.nephropathyStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Neumológicas' }}
              names={{
                status: 'pneumologicalStatus',
                medicine: 'pneumologicalMedicine',
                date: 'pneumologicalDate',
                frequency: 'pneumologicalFrequency',
                years: 'pneumologicalYears',
                months: 'pneumologicalMonths',
              }}
              showTextBoxes={defaultValues.pneumologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12}>
            <ChronicleDiseaseInput
              labels={{ main: 'Neurológica' }}
              names={{
                status: 'neurologicalStatus',
                medicine: 'neurologicalMedicine',
                date: 'neurologicalDate',
                frequency: 'neurologicalFrequency',
                years: 'neurologicalYears',
                months: 'neurologicalMonths',
              }}
              showTextBoxes={defaultValues.neurologicalStatus === 'true'}
            />
          </Grid>
          <Grid item md={12} marginBottom={4}>
            <ChronicleDiseaseInput
              labels={{ main: 'Otra' }}
              names={{
                status: 'otherStatus',
                medicine: 'otherMedicine',
                date: 'otherDate',
                frequency: 'otherFrequency',
                years: 'otherYears',
                months: 'otherMonths',
              }}
              showTextBoxes={defaultValues.otherStatus === 'true'}
            />
          </Grid>
          <Grid item md={6}>
            <Button buttonProps={{ onClick: handleBack }} gray>
              {t('back.label')}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};
