import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRestorePassword } from 'telemed-core';

const REQUIRED_TEXT = 'Este campo es obligatorio';
const MATCH_TEXT = 'La contraseña debe de coincidir';
const MIN_REQUIREMENTS = 'Debe cumplir con los requisitos mínimos';

const schema = yup.object().shape({
  password: yup
    .string()
    .required(REQUIRED_TEXT)
    .matches(
      /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
      // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#%&/=+_])[A-Za-z\d!@#%&/=+_]{8,}$/,
      MIN_REQUIREMENTS
    )
    .test(
      MIN_REQUIREMENTS,
      MIN_REQUIREMENTS,
      (value) => !!value && !value.includes(' ')
    ),
  repassword: yup
    .string()
    .required(REQUIRED_TEXT)
    .oneOf([yup.ref('password'), null], MATCH_TEXT),
});

type FormProps = {
  username: string;
  password: string;
};

export const useRestorePasswordFormManagement = () => {
  const { restored, error, loading, restore } = useRestorePassword();

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({ username, password }) => {
    await restore(username, password);
  };

  return {
    restored,
    error,
    loading,
    methods,
    submit,
  };
};
