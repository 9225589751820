import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import moment from 'moment';
import 'moment/locale/es-mx';

import {
  Content,
  ExerciseInfo,
  Loading,
  MediaType,
  Modal as TelemedModal,
  Separator,
  useContentsLazy,
} from 'telemed-core';

type Props = { list: ExerciseInfo[] };

const keyPrefix = 'components.ExerciseList';

export const ExerciseList: React.FC<Props> = ({ list }): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { contentList, error, loading, get } = useContentsLazy();

  console.log('ExerciseList -list', list);

  useEffect(() => {
    get();
  }, [get]);

  if (!list || list.length === 0) return <EmptyList />;
  if (loading) return <Loading />;

  return (
    <Box>
      {list.map((exerciseInfo, idx) => {
        const {
          media: { id: mediaId },
        } = exerciseInfo;
        const content = contentList.find(({ id }) => id === mediaId);
        return (
          <Box key={idx}>
            <ListItem component='li'>
              {!!content && <ListItemContent {...content} {...exerciseInfo} />}
            </ListItem>
            <Separator />
          </Box>
        );
      })}
      {!!error && (
        <TelemedModal title={t('modals.error.title')} message={error} />
      )}
    </Box>
  );
};

const Img = styled('img')({
  maxWidth: '85vw',
  maxHeight: '85vh',
  height: 'auto',
  width: 'auto',
});

type ListItemContentProps = Content & ExerciseInfo;

const ListItemContent: React.FC<ListItemContentProps> = ({
  contentInfo: {
    media: { mediaType, url, urlVideoThumbnail },
  },
  description,
  durationType,
  duration,
  indications,
  name,
  status,
  startDate,
}): JSX.Element => {
  const [lightBox, setLightBox] = useState<boolean>(false);

  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItemContent`,
  });

  const handleCloseLightBox = () => {
    setLightBox(false);
  };

  const handleClick = () => {
    if (mediaType === MediaType.IMAGE) {
      setLightBox(true);
    }
  };

  return (
    <Box
      width='100%'
      display='grid'
      gridTemplateColumns='350px 1fr'
      paddingBottom={2}
      columnGap={2}
    >
      <CardMedia
        sx={{
          maxWidth: 350,
          maxHeight: 350,
          width: mediaType === MediaType.VIDEO ? 350 : 'auto',
          height: mediaType === MediaType.VIDEO ? 218 : 'auto',
          cursor: mediaType === MediaType.IMAGE ? 'pointer' : 'default',
        }}
        {...(mediaType === MediaType.IMAGE && {
          component: 'img',
          image: url,
          cursor: 'pointer',
        })}
        {...(mediaType === MediaType.VIDEO && {
          src: url,
          component: 'video',
          controls: true,
        })}
        {...(mediaType === MediaType.VIDEO &&
          !!urlVideoThumbnail && {
            poster: urlVideoThumbnail,
          })}
        onClick={handleClick}
      />
      <Box>
        <Stack direction='row' justifyContent='space-between'>
          <Box>
            <Typography variant='subtitle1' color='grey.300'>
              {name}
            </Typography>
            <Typography variant='body1'>{description}</Typography>
          </Box>
          {status !== undefined && (
            <Chip
              label={t(`status.${status}.label`)}
              color={t(`status.${status}.color`)}
              size='small'
            />
          )}
        </Stack>
        <Typography color='primary' fontWeight={700} mt={2}>
          {t(`indications`)}
        </Typography>
        <Typography variant='body1'>{indications}</Typography>
        <Stack direction='row' columnGap={1} alignItems='baseline'>
          <Typography color='primary' fontWeight={700} mt={1}>
            {t(`startAt`)}
          </Typography>
          <Typography variant='body1'>
            {!!startDate
              ? moment.utc(startDate).local().format('DD/MM/YYYY - h:mm a')
              : t('withoutStartDate')}
          </Typography>
        </Stack>
        <Stack direction='row' columnGap={1} alignItems='baseline'>
          <Typography color='primary' fontWeight={700} mt={1}>
            {t(`duration.label`)}
          </Typography>
          <Typography variant='body1'>{`${duration} ${t(
            `duration.${durationType}.${duration > 1 ? 'plural' : 'singular'}`
          )}`}</Typography>
        </Stack>
      </Box>
      <Modal
        sx={{
          zIndex: 3000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={lightBox}
        onClose={handleCloseLightBox}
        keepMounted
      >
        <Img src={url} />
      </Modal>
    </Box>
  );
};

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <Box display='flex' justifyContent='center'>
      <Typography variant='body1' color='GrayText' sx={{ opacity: 0.5 }}>
        {t('message')}
      </Typography>
    </Box>
  );
};
