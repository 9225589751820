import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@material-ui/core';
import { Button, BasicTextInput, Modal, Loading } from 'telemed-core';

import { useLoginFormManagement } from '../hooks';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const LoginForm: React.FC<Props> = ({ className }) => {
  const { error, loading, methods, submit } = useLoginFormManagement();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <Typography
          component='div'
          sx={{
            textTransform: 'uppercase',
          }}
        >
          Ingresar
        </Typography>
        <BasicTextInput
          name='username'
          type='text'
          placeholder='e-mail'
          rounded={true}
          errorColorText='white'
        />
        <BasicTextInput
          name='password'
          type='password'
          placeholder='contraseña'
          rounded={true}
          errorColorText='white'
        />
        <Link
          href='/restore'
          variant='caption'
          sx={{
            color: (theme) => theme.palette.common.white,
            marginTop: (theme) => theme.spacing(1.5),
            textTransform: 'uppercase',
          }}
        >
          ¿Olvidaste tu Contraseña?
        </Link>
        {/* <Button type='submit' rounded> */}
        <Button buttonProps={{ type: 'submit' }} rounded>
          INGRESAR
        </Button>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
    </FormProvider>
  );
};
