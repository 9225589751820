import React from 'react';

export const CameraSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53 53' {...props}>
      <circle cx={26.2} cy={26.5} r={26.2} fill='#fff' />
      <path
        fill='none'
        stroke='#334c5e'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={22.9256}
        strokeWidth={1.4775}
        d='M11.2 17.4h19c1.4 0 2.6 1.2 2.6 2.6v15.4c0 1.4-1.2 2.6-2.6 2.6h-19c-1.4 0-2.6-1.2-2.6-2.6V20c0-1.4 1.2-2.6 2.6-2.6h0zM33 23.8L44.8 19c1.3-.5 2.6.9 2.6 2.1v13.4c0 1.1-1.3 2.6-2.6 2.1L33 31.7'
      />
    </svg>
  );
};
