import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { InputLabel, TreatmentInfo } from 'telemed-core';

import { EquipList } from './EquipList';
import { ExerciseList } from './ExerciseList';
import { MedicineList } from './MedicineList';
import { StudyList } from './StudyList';

interface Props {
  creationDate: string;
  indications?: string;
  mode?: number;
  treatmentInfo?: TreatmentInfo;
}

const keyPrefix = 'components.TreatmentData';

export const TreatmentData: React.FC<Props> = ({
  creationDate,
  indications,
  treatmentInfo,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { excerciseInfo, medicinesInfo, studiesInfo, equip } = treatmentInfo!;

  return (
    <Box>
      <ElementWrapper title={t('indications')}>
        <Typography sx={{ overflowWrap: 'break-word' }}>
          {indications}
        </Typography>
      </ElementWrapper>

      {excerciseInfo.length > 0 && (
        <ElementWrapper title={t('exercise.label')}>
          <ExerciseList list={excerciseInfo} />
        </ElementWrapper>
      )}

      {medicinesInfo.length > 0 && (
        <ElementWrapper title={t('medicines.label')}>
          <MedicineList list={medicinesInfo} />
        </ElementWrapper>
      )}

      {studiesInfo.length > 0 && (
        <ElementWrapper title={t('studies.label')}>
          <StudyList list={studiesInfo} />
        </ElementWrapper>
      )}

      {equip.length > 0 && (
        <ElementWrapper title={t('equips.label')}>
          <EquipList list={equip} />
        </ElementWrapper>
      )}
    </Box>
  );
};

type ElementWrapperProps = {
  title: string;
};

const ElementWrapper: React.FC<ElementWrapperProps> = ({
  children,
  title,
}): JSX.Element => (
  <Box>
    <Stack mt={1}>
      <InputLabel label={title} />
    </Stack>
    <Box mt={1}>{children}</Box>
  </Box>
);
