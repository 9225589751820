import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import {
  BasicSelectInput,
  BasicTextInput,
  Button,
  ISelectItem,
  StudyInfo,
  StudiesSelectInput,
  StudyConfigInfo,
  StudyTypeInput,
} from 'telemed-core';

import { useAddStudyFormManagement } from '../hooks';

type Props = {
  onAdd: (newStudy: StudyInfo) => void;
};

const keyPrefix = 'forms.AddStudyForm';

export const AddStudyForm: React.FC<Props> = ({ onAdd }) => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix });
  const { methods, submit } = useAddStudyFormManagement();

  const handleChange = ({ id }: StudyConfigInfo) => {
    setIsNew(id === -1);
  };

  const onSubmit = async (fields: any) => {
    const newStudy = await submit(fields);
    onAdd(newStudy);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <StudiesSelectInput
              label={t('study.label')}
              placeholder={t('study.placeholder')}
              onChange={handleChange}
            />
          </Grid>
          {/* {isNew && (
            <> */}
          <Grid item md={7} display={isNew ? 'block' : 'none'}>
            <BasicTextInput
              name='name'
              maxLength={50}
              // minRows={2}
              label={t('name.label')}
              placeholder={t('name.placeholder')}
            />
          </Grid>
          <Grid item md={5} display={isNew ? 'block' : 'none'}>
            <StudyTypeInput
              // minRows={2}
              label={t('type.label')}
              placeholder={t('type.placeholder')}
            />
          </Grid>
          <Grid item md={12} display={isNew ? 'block' : 'none'}>
            <BasicTextInput
              name='description'
              maxLength={300}
              minRows={4}
              label={t('description.label')}
              placeholder={t('description.placeholder')}
            />
          </Grid>
          {/* </>
          )} */}
          <Grid item md={12}>
            <BasicTextInput
              name='indications'
              maxLength={300}
              minRows={4}
              label={t('indications.label')}
              placeholder={t('indications.placeholder')}
            />
          </Grid>

          <Grid item md={12} mt={3} display='flex' justifyContent='center'>
            <Button buttonProps={{ type: 'submit' }}>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

type StudyInputInputProps = {
  // maxMenuHeight?: number;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  contentOptions: ISelectItem[];
};

const StudyInput: React.FC<StudyInputInputProps> = ({
  // maxMenuHeight = 200,
  name = 'study',
  label = '',
  placeholder = 'Seleccione...',
  required = true,
  contentOptions,
}): JSX.Element => {
  return (
    <BasicSelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      options={contentOptions}
      // maxMenuHeight={maxMenuHeight}
    />
  );
};
