import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { styled, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import PersonIcon from '@material-ui/icons/Person';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { goDeep } from 'telemed-core';
import Box from '@material-ui/core/Box';

import CardMedia from '@material-ui/core/CardMedia';

const Input = styled('input')({
  display: 'none',
});

const useStyles = makeStyles({
  image: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    padding: 3,
    transition: 'all 0.3s ease-in-out',
  },
});

type Props = {
  initialMedia?: { url: string | null };
  name?: string;
  onSelectFile: (file: File | null) => void;
};

export const AvatarUploadInput: React.FC<Props> = ({
  initialMedia = null,
  name = 'avatarFileName',
  onSelectFile,
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(
    initialMedia?.url || null
  );

  const classes = useStyles();
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const value = watch(name);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    clearErrors(name);
    const file: File = event.target.files[0];

    if (!file) return;

    setValue(name, file.name);
    setFile(null);
    setFile(file);
    onSelectFile(file);
  };

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (!file) {
      initialMedia?.url !== preview && setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const getError = () =>
    goDeep(errors, name) ? goDeep(errors, name).message : null;

  return (
    <FormControl>
      <Box
        sx={{
          position: 'relative',
          borderRadius: '50%',
          backgroundColor: (theme) => `${theme.palette.grey[100]}`,
          width: (theme) => theme.spacing(16),
          height: (theme) => theme.spacing(16),
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          boxShadow: `inset 0px 0px 0px 4spx white`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover img': {
            opacity: 0.15,
          },
          '&:hover svg': {
            opacity: 0.45,
          },
          '&:hover span': {
            opacity: 1,
          },
        }}
      >
        <Box sx={{ position: 'absolute', zIndex: 100 }}>
          <label htmlFor='contained-button-avatar-file'>
            <Input
              ref={inputRef}
              accept={`image/png, image/jpeg`}
              id='contained-button-avatar-file'
              type='file'
              onChange={handleFileChange}
            />
            <IconButton
              component='span'
              sx={{ opacity: 0, padding: '50px', borderRadius: '50%' }}
            >
              <CloudUploadIcon fontSize='large' />
            </IconButton>
          </label>
        </Box>
        {!!preview ? (
          <CardMedia
            image={preview}
            component='img'
            className={classes.image}
          />
        ) : (
          <PersonIcon
            sx={{
              fontSize: 98,
              color: '#fff',
              transition: 'all 0.3s ease-in-out',
            }}
          />
        )}
      </Box>
      <InputBase type='hidden' name={name} value={value} />
      <FormHelperText
        error={getError()}
        sx={{
          position: 'absolute',
          bottom: '-22px',
          right: '-12px',
        }}
      >
        {getError()}
      </FormHelperText>
    </FormControl>
  );
};
