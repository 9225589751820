import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Consultation, Note, useEditConsultation } from 'telemed-core';
import moment from 'moment';
import { useEffect, useState } from 'react';

const REQUIRED_TEXT = 'Este campo es obligatorio';

const schema = yup.object().shape({
  description: yup.string().required(REQUIRED_TEXT),
});

export const useAddNoteFormManagement = (
  originalConsultation: Consultation
) => {
  const [editedConsultation, setEditedConsultation] =
    useState<Consultation | null>(null);
  const [addedNote, setAddedNote] = useState<Note | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { edited, error: reqError, loading, edit } = useEditConsultation();

  useEffect(() => {
    !!reqError && setError(reqError);
  }, [reqError]);

  type FormProps = {
    description: string;
  };

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({ description }) => {
    setError(null);

    const creationDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const { consultationInfo } = originalConsultation;
    const notes = consultationInfo.notes || [];
    const newNote = { description, creationDate } as Note;
    notes.push(newNote);

    const modifiedConsultation = {
      ...originalConsultation,
      consultationInfo: {
        ...consultationInfo,
        notes,
      },
    };

    const res = await edit(modifiedConsultation);
    if (!!res) {
      setAddedNote(newNote);
      setEditedConsultation(modifiedConsultation);
    }
  };

  return {
    addedNote,
    editedConsultation,
    edited,
    error,
    loading,
    methods,
    submit,
  };
};
