import React from 'react';

export const MicroSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      id='Capa_1'
      x={0}
      y={0}
      viewBox='0 0 53 53'
      {...props}
    >
      <style>
        {
          '.st1{fill:none;stroke:#334c5e;stroke-width:1.4775;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}'
        }
      </style>
      <circle cx={26.5} cy={26.5} r={26.2} fill='#fff' />
      <path
        d='M36.8 23.6v2.5c0 5.7-4.6 10.3-10.3 10.3-5.7 0-10.3-4.6-10.3-10.3v-2.5m10.3 12.8v7.4m-8.4 1.1h16.8'
        className='st1'
      />
      <path
        d='M26.5 8.1c3.6 0 6.6 3 6.6 6.6v10.8c0 3.6-3 6.6-6.6 6.6-3.6 0-6.6-3-6.6-6.6V14.7c0-3.6 3-6.6 6.6-6.6zm-6.3 6.4h3m-3 3.7h3m-3 3.8h3m-3 3.7h3m6.6-11.2h3m-3 3.7h3m-3 3.8h3m-3 3.7h3'
        className='st1'
      />
    </svg>
  );
};
