import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUpdatePassword } from 'telemed-core';

const REQUIRED_TEXT = 'Este campo es obligatorio';
const MATCH_TEXT = 'La contraseña debe de coincidir';
const MIN_REQUIREMENTS = 'Debe cumplir con los requisitos mínimos';

const schema = yup.object().shape({
  password: yup.string().required(REQUIRED_TEXT),
  newPassword: yup
    .string()
    .required(REQUIRED_TEXT)
    .matches(
      /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
      // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#%&/=+_\*])[A-Za-z\d!@#%&/=+_\*]{8,}$/,
      MIN_REQUIREMENTS
    )
    .test(
      MIN_REQUIREMENTS,
      MIN_REQUIREMENTS,
      (value) => !!value && !value.includes(' ')
    ),
  reNewPassword: yup
    .string()
    .required(REQUIRED_TEXT)
    .oneOf([yup.ref('newPassword'), null], MATCH_TEXT),
});

type FormProps = {
  password: string;
  newPassword: string;
  reNewPassword: string;
};

export const useUpdatePasswordFormManagement = () => {
  const { updated, error, loading, update } = useUpdatePassword();

  const methods = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<FormProps> = async ({
    password,
    newPassword,
  }) => {
    await update(password, newPassword);
  };

  useEffect(() => {
    updated && methods.reset();
  }, [updated]);

  return {
    updated,
    error,
    loading,
    methods,
    submit,
  };
};
