import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { alpha, Grid } from '@material-ui/core';
import { AddButton, Loading, Modal, Treatment } from 'telemed-core';
import moment from 'moment';
import 'moment/locale/es-mx';

import { useTreatments } from '../hooks';
import { TreatmentData } from './TreatmentData';
import { TreatmentForm } from '../forms';

type Props = {
  patientId: number;
};

const keyPrefix = 'components.TreatmentsList';

export const TreatmentsList: React.FC<Props> = ({ patientId }): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [addingNewTreatment, setAddingNewTreatment] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, treatments, refresh } = useTreatments(patientId);

  const handleChange =
    (panel: string) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleAddClick = () => {
    setAddingNewTreatment(true);
  };

  const handleClose = () => {
    refresh();
    setAddingNewTreatment(false);
  };

  console.log('treatments', treatments);

  return (
    <Box>
      <Box mb={3} display='flex' justifyContent='flex-end'>
        <AddButton
          label={t('add')}
          onClick={handleAddClick}
          disabled={addingNewTreatment}
        />
      </Box>
      {!loading && !addingNewTreatment && treatments.length === 0 && (
        <EmptyList />
      )}
      {addingNewTreatment ? (
        <Accordion expanded={true} TransitionProps={{ unmountOnExit: true }}>
          <AddDetails patientId={patientId} onClose={handleClose} />
        </Accordion>
      ) : (
        treatments.map((treatment, idx: number) => (
          <Accordion
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
            TransitionProps={{ unmountOnExit: true }}
            key={idx}
          >
            <Summary {...treatment} />
            <ViewDetails {...treatment} />
          </Accordion>
        ))
      )}
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
    </Box>
  );
};

type AddDetailsProps = {
  patientId: number;
  onClose: () => void;
};

const AddDetails: React.FC<AddDetailsProps> = ({ patientId, onClose }) => {
  return (
    <AccordionDetails
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.info.light, 0.2),
      }}
    >
      <TreatmentForm patientId={patientId} onClose={onClose} />
    </AccordionDetails>
  );
};

const ViewDetails: React.FC<Treatment> = ({
  indications,
  treatmentsInfo,
  creationDate,
}) => {
  return (
    <AccordionDetails
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.info.light, 0.2),
      }}
    >
      <TreatmentData
        indications={indications}
        treatmentInfo={treatmentsInfo}
        creationDate={creationDate}
      />
    </AccordionDetails>
  );
};

const Summary: React.FC<Treatment> = ({
  name,
  creationDate,
  status,
}): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.Summary`,
  });

  const convertedDate = moment
    .utc(creationDate)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.info.light, 0.65),
      }}
    >
      <Grid
        container
        sx={{
          width: '100%',
          paddingRight: (theme) => theme.spacing(2),
          paddingLeft: (theme) => theme.spacing(1),
        }}
      >
        <Grid item md={8}>
          <Typography sx={{ fontWeight: 500 }}>{name}</Typography>
        </Grid>
        <Grid item md={3} justifyContent='center' display='flex'>
          <Typography variant='body2' color='grey.300' sx={{ opacity: 0.7 }}>
            {t('creation')}
            <Moment fromNow locale='es-MX'>
              {convertedDate}
            </Moment>
          </Typography>
        </Grid>
        <Grid item md={1} justifyContent='end' display='flex'>
          <Typography color='primary' fontWeight={700}>
            {t(`status.${status}`)}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: (theme) => theme.spacing(7),
      }}
    >
      <Typography
        variant='body1'
        color='GrayText'
        sx={{
          opacity: 0.5,
        }}
      >
        {t('message')}
      </Typography>
    </Box>
  );
};
