import React from 'react';

export const LogoTypeSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='SITVA'
      x={0}
      y={0}
      viewBox='0 0 214.2 60.3'
      xmlSpace='preserve'
      {...props}
    >
      <style>{'.st0{fill:currentColor}'}</style>
      <path
        d='M98.1 33.7c-9.8 0-17.7-7.9-17.7-17.7 0-4.7 1.9-9.2 5.2-12.5L88 1.1c1.6-1.5 4.1-1.5 5.7.1 1.5 1.5 1.5 4 0 5.5l-2.4 2.4c-3.8 3.8-3.8 9.9 0 13.7s9.9 3.8 13.7 0l8.2-8.1c1.5-1.6 4-1.8 5.7-.3s1.8 4 .3 5.6l-.3.3-8.2 8.1c-3.4 3.5-7.9 5.4-12.6 5.3z'
        className='st0'
      />
      <path
        d='M123.1 33.8c-2.2 0-4-1.8-4-4 0-1.1.4-2.1 1.2-2.8l2.3-2.3c3.6-4 3.2-10.1-.8-13.7-3.7-3.3-9.2-3.3-12.8 0l-8.2 8.1c-1.5 1.6-4 1.8-5.7.3s-1.8-4-.3-5.7l.3-.3 8.2-8.1c6.9-6.9 18.1-6.9 25 0 3.3 3.3 5.2 7.8 5.2 12.5s-1.8 9.2-5.2 12.5l-2.3 2.4c-.8.6-1.8 1.1-2.9 1.1zm-48.4 8.7h1.9V50h10.6v-7.5H89V60h-1.8v-8.3H76.6V60h-1.9V42.5zm17 8.7c0-6.3 3.5-9.1 8.8-9.1s8.9 2.8 8.9 9.1-3.5 9.1-8.9 9.1-8.8-2.8-8.8-9.1zm15.8 0c0-4.8-2.4-7.4-7-7.4s-6.9 2.6-6.9 7.4 2.4 7.4 6.9 7.4 7-2.5 7-7.4zm4.5-8.7h2.4l6.4 15 6.4-15h2.4V60h-1.8V45.1L121.3 60H120l-6.3-14.9V60H112V42.5zm21.2 0h11.6v1.7H135v5.9h9.5v1.6H135v6.7h9.8V60h-11.7V42.5zm13.4 8.7c0-6.1 3.4-9.1 8.7-9.1 2.2-.1 4.4.7 6.1 2.2l-1.2 1.3c-1.4-1.2-3.1-1.8-4.9-1.8-4.3 0-6.9 2.5-6.9 7.4s2.4 7.4 6.9 7.4c1.8.1 3.7-.6 5-1.8l1.3 1.2c-1.7 1.6-4 2.5-6.4 2.3-5.6 0-8.6-3.1-8.6-9.1zm22.7-8.8h1.8l7.1 17.6h-1.9l-1.6-4.1h-8.9L164 60h-1.8l7.1-17.6zm4.7 12l-3.9-9.8-3.9 9.8h7.8zm13.4-1.6h-5.2V60h-1.8V42.5h7.6c4.4 0 5.9 1.7 5.9 5.1 0 3.1-1.2 4.8-4.5 5.2l4.2 7.2h-2l-4.2-7.2zm.6-1.6c2.8 0 4-.8 4-3.6s-1.2-3.5-4.1-3.5h-5.7v7.1h5.8zm8.9-8.7h11.6v1.7h-9.7v5.9h9.5v1.6h-9.5v6.7h9.8V60h-11.7V42.5zM0 57.9l2.5-3.5c1.9 1.3 4.1 1.9 6.3 1.9 3.4 0 3.5-.5 3.5-1.5s-1.2-1.1-3.7-1.4c-5.2-.6-7.5-1.6-7.5-5.6 0-4.2 2.5-5.6 7.8-5.6 4 0 6.3 1 7.7 1.8l-2.7 3.8c-1.5-.9-3.2-1.3-5-1.3-2.6 0-2.9.4-2.9 1.2s1.3 1.2 3.9 1.5c4.4.6 7.3 1.3 7.3 5.4 0 3.6-1.8 5.8-8.2 5.8-4-.1-7.1-1.1-9-2.5zm17.9-15.4h4.9v17.6h-4.9V42.5zm5.7 0h5.1L33 54.1l4.3-11.6h5l-7 17.7h-4.8l-6.9-17.7z'
        className='st0'
      />
      <path
        d='M45.8 46.9H40v-4.4h16.6v4.4h-5.8v13.2h-4.9V46.9zm15.4-4.6H66L73.1 60h-5l-.9-2.3h-7.5l-.9 2.3h-4.7l7.1-17.7zm4.4 11.4l-2.2-5.6-2.2 5.6h4.4zm144.8-10.3c0-1 .9-1.9 1.9-1.8s1.9.9 1.8 1.9c0 1-.9 1.8-1.9 1.8-1 .1-1.8-.7-1.9-1.6.1-.1.1-.2.1-.3zm3.5 0c0-.9-.8-1.5-1.6-1.5s-1.5.8-1.5 1.6.7 1.5 1.6 1.5c.8.1 1.5-.5 1.6-1.3-.1-.1-.1-.2-.1-.3zm-1.5.4h-.5v.8h-.5v-2.3h1.1c.8 0 .9.3.9.8 0 .3-.2.6-.5.7l.5.8h-.5l-.5-.8zm.1-.4c.3 0 .4-.1.4-.3s-.1-.3-.4-.3h-.6v.7l.6-.1z'
        className='st0'
      />
    </svg>
  );
};
