import Stack from '@material-ui/core/Stack';
import Box from '@material-ui/system/Box';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Loading, Modal, Button, Output } from 'telemed-core';

import { useMedicalRecord } from '../hooks';

type Props = {
  onNext: () => void;
};

const keyPrefix = 'components.PersonalRecordViewer';

export const PersonalRecordViewer: React.FC<Props> = ({
  onNext,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { patientInfo, loading, error } = useMedicalRecord();

  return (
    <>
      {!!patientInfo && (
        <Stack spacing={2}>
          <Output
            label={t('labels.name')}
            value={`${patientInfo?.name} ${patientInfo?.firstLastName} ${patientInfo?.secondLastName}`}
          />
          <Output
            label={t('labels.birthday')}
            value={moment(patientInfo.birthday).format('DD-MM-YYYY')}
          />
          <Output label={t('labels.age')} value={`${patientInfo.age}`} />
          <Output label={t('labels.gender')} value={`${patientInfo.gender}`} />
          <Output
            label={t('labels.bloodType')}
            value={`${patientInfo.bloodType}`}
          />
          <Output
            label={t('labels.currentOccupation')}
            value={`${patientInfo.currentOccupation || ''}`}
          />
          <Output
            label={t('labels.previousOccupation')}
            value={`${patientInfo.previousOccupation || ''}`}
          />
          <Output
            label={t('labels.maritalStatus')}
            value={adjustMaritalStatus(
              patientInfo.maritalStatus,
              patientInfo.gender
            )}
          />
          <Output
            label={t('labels.address')}
            value={`${patientInfo.address.street} ${
              patientInfo.address.extNumber
            } ${
              !!patientInfo.address.intNumber
                ? patientInfo.address.intNumber
                : ''
            } ${patientInfo.address.delegation} Col. ${
              patientInfo.address.colony
            }, ${patientInfo.address.state}, CP ${patientInfo.address.cp}`}
          />
          <Output
            label={t('labels.emergencyContact.name')}
            value={`${patientInfo.emergencyContact[0].name}`}
          />
          <Output
            label={t('labels.emergencyContact.phone')}
            value={`${patientInfo.emergencyContact[0].phone}`}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button buttonProps={{ type: 'submit', onClick: onNext }} gray>
              {t('button')}
            </Button>
          </Box>
        </Stack>
      )}
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
    </>
  );
};

const adjustMaritalStatus = (maritalStatus: string, gender: string): string => {
  console.log('maritalStatus', maritalStatus);
  console.log('gender', gender);
  switch (maritalStatus) {
    case 'Soltero':
      console.log('res', gender === 'Masculino' ? maritalStatus : 'Soltera');
      return gender === 'Masculino' ? maritalStatus : 'Soltera';
    case 'Casado':
      console.log('res', gender === 'Masculino' ? maritalStatus : 'Casada');
      return gender === 'Masculino' ? maritalStatus : 'Casada';
    case 'Viudo':
      console.log('res', gender === 'Masculino' ? maritalStatus : 'Viuda');
      return gender === 'Masculino' ? maritalStatus : 'Viuda';
    default:
      return maritalStatus;
  }
};
