import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import moment from 'moment';
import 'moment/locale/es-mx';

import { Modal, Separator, StudyInfo } from 'telemed-core';

type Props = { list: StudyInfo[] };

const keyPrefix = 'components.StudyList';

export const StudyList: React.FC<Props> = ({ list }): JSX.Element => {
  // const { t } = useTranslation('translation', { keyPrefix });

  if (!list || list.length === 0) return <EmptyList />;

  return (
    <Box>
      {list.map((studyInfo, idx) => {
        return (
          <Box key={idx}>
            <ListItem component='li'>
              <ListItemContent {...studyInfo} />
            </ListItem>
            <Separator />
          </Box>
        );
      })}
    </Box>
  );
};

const ListItemContent: React.FC<StudyInfo> = ({
  creationDate,
  indications,
  media,
  name,
  description,
  status,
  type,
}): JSX.Element => {
  const [showCredentials, setShowCredentials] = useState<boolean>(false);
  const [urlToOpen, setUrlToOpen] = useState<string | null>(null);
  const [selectedDeliveredStudy, setSelectedDeliveredStudy] =
    useState<number>();

  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.ListItemContent`,
  });

  const handleOkModal = () => {
    setSelectedDeliveredStudy(-1);
    setShowCredentials(false);
    urlToOpen && window.open(urlToOpen, 'blank', 'noopener');
  };

  const handleCancelModal = () => {
    setSelectedDeliveredStudy(-1);
    setShowCredentials(false);
  };

  const handleClick = (idx: number, url?: string) => {
    setSelectedDeliveredStudy(idx);
    !!url && setUrlToOpen(url);
    setShowCredentials(true);
  };

  return (
    <Box
      width='100%'
      display='grid'
      gridTemplateColumns='350px 1fr'
      paddingBottom={2}
      columnGap={2}
    >
      <Box>
        <Typography color='primary' fontWeight={700} mt={2}>
          {t(`delivered.label`)}
        </Typography>
        {!media || media.length === 0 ? (
          <Box
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            mt='-12px'
          >
            <EmptyDeliveredList />
          </Box>
        ) : (
          media.map(({ name, url, username, password }, idx) => {
            const needsCredentials = !!username || !!password;
            return (
              <ListItem component='li' key={idx}>
                <Link
                  variant='body2'
                  underline='hover'
                  rel='noopener'
                  target='_blank'
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                    textDecorationColor: (theme) => theme.palette.grey[600],
                    cursor: 'pointer',
                  }}
                  {...(needsCredentials
                    ? {
                        onClick: () => {
                          handleClick(idx, url);
                        },
                      }
                    : { href: url })}
                >
                  {name || url}
                </Link>
                {showCredentials && selectedDeliveredStudy === idx && (
                  <Modal
                    title={t('link.title')}
                    buttonText={t('link.ok')}
                    cancelText={t('link.cancel')}
                    onClickOk={handleOkModal}
                    onClickCancel={handleCancelModal}
                  >
                    <Typography variant='body1'>{t('link.message')}</Typography>
                    <Box
                      display='grid'
                      gridTemplateColumns='70px 1fr'
                      columnGap={2}
                      mt={2}
                    >
                      <Typography variant='subtitle1' color='grey.300'>
                        {t('link.user')}
                      </Typography>
                      <Typography variant='body1'>{username || ''}</Typography>
                      <Typography variant='subtitle1' color='grey.300'>
                        {t('link.password')}
                      </Typography>
                      <Typography variant='body1'>{password || ''}</Typography>
                    </Box>
                  </Modal>
                )}
              </ListItem>
            );
          })
        )}
      </Box>
      <Box>
        <Stack direction='row' justifyContent='space-between'>
          <Box>
            <Typography variant='subtitle1' color='grey.300'>
              {name}
            </Typography>
            <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
              {description}
            </Typography>
          </Box>
          <Chip
            label={t(`status.${status}.label`)}
            color={t(`status.${status}.color`)}
            size='small'
          />
        </Stack>

        <Typography color='primary' fontWeight={700} mt={2}>
          {t(`indications`)}
        </Typography>
        <Typography variant='body1'>{indications}</Typography>

        <DisplayData
          label={t(`creationDate`)}
          value={moment.utc(creationDate).local().format('DD/MM/YYYY - h:mm a')}
        />

        <DisplayData label={t(`type.label`)} value={t(`type.${type}`)} />
      </Box>
    </Box>
  );
};

type DisplayDataProps = {
  label: string;
  value: string;
};

const DisplayData: React.FC<DisplayDataProps> = ({
  label,
  value,
}): JSX.Element => (
  <>
    <Stack direction='row' columnGap={1} alignItems='baseline'>
      <Typography color='primary' fontWeight={700} mt={1}>
        {label}
      </Typography>
      <Typography variant='body1'>{value}</Typography>
    </Stack>
  </>
);

const EmptyDeliveredList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyDeliveredList`,
  });

  return (
    <Box display='flex' justifyContent='center'>
      <Typography variant='body1' color='GrayText' sx={{ opacity: 0.5 }}>
        {t('message')}
      </Typography>
    </Box>
  );
};

const EmptyList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.EmptyList`,
  });

  return (
    <Box display='flex' justifyContent='center'>
      <Typography variant='body1' color='GrayText' sx={{ opacity: 0.5 }}>
        {t('message')}
      </Typography>
    </Box>
  );
};
