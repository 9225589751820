import React from 'react';
import Box from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { StudyInfo, LayoutDialog } from 'telemed-core';

import { AddStudyForm } from '../forms';

type Props = {
  open: boolean;
  onClose: () => void;
  onAdd: (newStudy: StudyInfo) => void;
};

const keyPrefix = 'dialogs.AddStudyDialog';

export const AddStudyDialog: React.FC<Props> = ({ open, onAdd, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <LayoutDialog title={t('title')} open={open} handleClose={onClose}>
      <Box container width='600px' minHeight='260px'>
        <AddStudyForm onAdd={onAdd} />
      </Box>
    </LayoutDialog>
  );
};
